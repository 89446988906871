<template>
    <div class="flex simple-checkbox" :class="getModifiers()" @click="onClick">
        <label class="flex flex--100 flex--y-align-center simple-checkbox__text">
            <span class="flex simple-checkbox__box">
                <input type="checkbox" class="simple-checkbox__checkbox-input" :checked="modelValue" @click="onCheckboxClick">
                <span class="slider" v-if="appearance === SimpleCheckboxAppearance.SLIDER"></span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="simple-checkbox__checked-icon">
                    <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                </svg>
            </span>
            <span>{{ text }}</span>
        </label>
    </div>
</template>

<script>
const SimpleCheckboxAppearance = {
    BOX: "box",
    SLIDER: "slider",
};

export default {
    name: "SimpleCheckbox",
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        appearance: {
            type: String,
            default: SimpleCheckboxAppearance.BOX,
        },
    },
    emits: [ "update:modelValue", "click" ],
    data () {
        return {
            SimpleCheckboxAppearance,
        };
    },
    methods: {
        getModifiers () {
            return {
                "simple-checkbox--checked": this.modelValue,
                "simple-checkbox--slider": this.appearance === SimpleCheckboxAppearance.SLIDER,
            };
        },

        onClick (event) {
            if (this.disabled) {
                event.preventDefault();

                return false;
            }

            this.$emit("click", event);
        },

        onCheckboxClick (event) {
            if (this.disabled) {
                event.preventDefault();

                return false;
            }

            this.$emit("update:modelValue", event.target.checked);
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.simple-checkbox {
    margin: 0;
    padding: 0;

    &__text {
        @extend .--unselectable;

        margin: 0;
        padding: 0;

        font-size: 15px;
        font-weight: 400;
        color: rgb(40, 40, 40);
    }
    &__text,
    &__text * {
        cursor: pointer;
    }

    &__box {
        position: relative;

        width: auto;
        height: auto;
        //margin: 0 7px 0 0;
        margin: 0;
        padding: 0;
    }
    &--slider &__box {
        width: 56px;
        height: 30px;
    }

    &__checkbox-input {
        appearance: none;

        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;

        background-color: rgb(250, 250, 250);
        border: 1px solid rgb(181, 181, 181);
        border-radius: 3px;
    }
    &__checkbox-input:checked {
        background-color: rgb(236, 186, 71);
        border-color: rgba(236, 186, 71, 1);
    }
    &--slider &__checkbox-input {
        display: none;
    }

    &__checked-icon {
        position: absolute;
        left: 3px;
        top: 3px;

        display: none;
        width: 14px;
        height: 14px;
        margin: 0;
        padding: 0;

        fill: rgb(252, 252, 252);
    }
    &__checkbox-input:checked + &__checked-icon {
        display: initial;
    }
    &--slider &__checked-icon {
        display: none;
    }
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 34px;
    transition: .4s;
}

.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 22px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 22px;
    border-radius: 50%;
}

.simple-checkbox__checkbox-input:checked + .slider {
    background-color: #66bb6a;
}

.simple-checkbox__checkbox-input:checked + .slider:before {
    transform: translateX(26px);
}
</style>
