<template>
    <div class="flex flex--100 add-scene-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-scene-form">
                <div class="flex flex--100">
                    <div class="flex flex--100 search-bar">
                        <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                    </div>
                </div>
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">
                        {{$t(`management.restaurantSceneManagementTab.product`).toLocaleUpperCase()}}
                    </span>
                </div>
                <div class="flex flex--100 flex--x-align-center scene-list">
                <span class="no-allergens-text" v-if="customProducts.length === 0">{{$t(`management.restaurantSceneManagementTab.noCustomProducts`)}}</span>
                <div class="flex flex--100 flex--y-align-center restaurant-product-box" v-for="product in orderedProducts" :key="product.id">
                    <div
                        class="flex flex--80"
                    >
                        <span class="restaurant-product-box_text">{{ getLocalization(product) }} € {{ normalizePriceToDisplay(product.price) }}</span>
                    </div>
                    <div class="flex flex--20 flex--x-align-end flex--y-align-center">
                        <simple-button v-if="activatedProducts[product.id]" :isLoading="isWaitingServerResponse" :disable="isWaitingServerResponse" class="restaurant-product-box_button" :text="$t(`management.restaurantSceneManagementTab.disable`).toLocaleUpperCase()" @click="disable(product.id)"/>
                        <simple-button v-else class="restaurant-product-box_button" :isLoading="isWaitingServerResponse" :disable="isWaitingServerResponse" :text="$t(`management.restaurantSceneManagementTab.active`).toLocaleUpperCase()" @click="active(product.id)"/>
                    </div>
                </div>
            </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
// import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { i18n, } from "@/main";
import Fuse from "fuse.js";
// import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

export default {
    name: "SceneCustomProductModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        customProducts: {
            type: Array,
            default: () => [],
        },
        scene: {
            type: Object,
        },
    },
    emits: [ "update:scene", ],
    components: {
        SimpleButton,
        SimpleTextbox,
        // SimpleCheckbox,
        ModalBox,
    },
    data () {
        return {
            isWaitingServerResponse: false,
            activeProducts: [],
            searchBarValue: "",
        };
    },
    methods: {
        async load (scene) {
            this.clearProductForm();
            this.activeProducts = scene.customProducts;
        },

        getLocalization (product) {
            return product.localizations.find((localization) => localization.languageIso === i18n.global.locale).name;
        },

        validateSceneProductForm () {/*
            const nameTextbox = this.$refs.nameTextbox;
            
            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearProductForm () {
            this.isWaitingServerResponse = false;
            this.activeProducts = [];
            this.searchBarValue = "";
        },

        async active (id) {
            this.activeProducts.push(id);
            await this.setActiveProduct();
        },

        async disable (id) {
            this.activeProducts = this.activeProducts.filter((activeId) => activeId !== id);
            await this.setActiveProduct();
        },
        
        async setActiveProduct () {
            if (!this.validateSceneProductForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await user.updateScene({
                    ...this.scene,
                    customProducts: this.activeProducts,
                });

                if (response) {
                    this.activeProducts = response.customProducts;
                    this.$emit("update:scene", response);
                }
            }
            catch (error) {
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        fuse () {
            const searchItems = this.customProducts;
            return new Fuse(searchItems, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>
        activatedProducts () {
            const activated = [];

            for (const activeProduct of this.activeProducts) {
                activated[activeProduct] = true;
            }

            return activated;
        },

        filteredProducts () {
            return [ ...this.customProducts, ].filter((product) => product.categories.length > 0);
        },

        orderedProducts () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return [ ...this.filteredProducts, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";
.add-scene-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.add-scene-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.item {
    margin: 10px 0;
}

.restaurant-product-box {
    position: relative;

    width: 100%;

    cursor: pointer;

    background-color: rgb(240, 240, 240);
    border-radius: 0;
    border: 1px solid rgb(226, 226, 226);
    padding: 5px 10px;

    :deep(.restaurant-product) {
        height: 100%;
        background-color: transparent;
        &__name {
            padding: 3px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(0, 0, 0);
        }
    }

    &_text {
        font-size: 18px;
        font-weight: 600;
    }

    &_button {
        border-radius: 5px;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;

        &__selected {
            :deep(.simple-button__text) {
                color: $primary-brand-color;
            }
        }
    }
}
</style>
