<template>
    <modal-box :close-on-background-click="true" :show-back-button="false" :show-close-button="false" ref="modalBox">
        <img :src="localizedNewYearImage" alt="" class="new-year-image"/>
        <div class="flex continue-button" @click="hide">
            <span class="flex continue-button__text">{{ continueText }}</span>
        </div>
    </modal-box>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import {configuration} from "@/configuration";
import {
    RestaurantProcessor,
    saveImage,
    loadImage,
} from "@/utilities";

export default {
    name: "NewChineseYearModalBox",
    components: {
        ModalBox
    },
    props: {
        restaurantPresentation: {
            type: Object,
        }
    },
    data () {
        return {
            SERVER_URI: configuration.uri,
            intervalCount: 0,
        };
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        ITSplashAd () {
            return this.restaurantProcessor.restaurant.ITSplashAd;
        },

        ENSplashAd () {
            return this.restaurantProcessor.restaurant.ENSplashAd;
        },

        ZHSplashAd () {
            return this.restaurantProcessor.restaurant.ZHSplashAd;
        },

        localizedNewYearImage () {
            let uri = loadImage("ITSplashAd");
            if (this.$i18n.locale === "en" && this.ENSplashAd?.uri) {
                uri = loadImage("ENSplashAd");
            }
            if (this.$i18n.locale === "zh" && this.ZHSplashAd?.uri) {
                uri = loadImage("ZHSplashAd");
            }

            return uri;
        },

        continueText () {
            if (this.intervalCount >= 5) {
                return this.$t("generic.continue").toUpperCase();
            }

            let text = this.$t("generic.gotIt");

            text += ` (${5 - this.intervalCount})`;

            return text.toUpperCase();
        },
    },
    methods: {
        show () {
            this.intervalCount = 0;
            this.$refs.modalBox.show();

            const intervalId = setInterval(() => {
                if (++this.intervalCount >= 5) {
                    clearInterval(intervalId);
                }
            }, 1000);
        },

        hide () {
            this.$refs.modalBox.hide();
        },
    },
    mounted () {
        if (this.ITSplashAd) {
            saveImage("ITSplashAd", this.ITSplashAd.uri);
        }
        if (this.ZHSplashAd) {
            saveImage("ZHSplashAd", this.ZHSplashAd.uri);
        }
        if (this.ENSplashAd) {
            saveImage("ENSplashAd", this.ENSplashAd.uri);
        }
    },
};
</script>

<style lang="scss" scoped>
.modal-box {
    :deep(.modal-box__slot) {
        width: 100%;
        height: 90vh;

        margin: 0 0 10vh 0;

        background-color: rgb(0, 0, 0);
        border-radius: 0;
    }
}

.new-year-image {
    width: auto;
    height: 90vh;

    margin: 0 auto;
}

.continue-button {
    cursor: pointer;

    position: absolute;
    left: 50%;
    bottom: 5%;

    padding: 12px 32px;

    background-color: rgb(255, 255, 255);
    border-radius: 1000px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    transform: translate(-50%, 50%);

    z-index: 99999999999999;

    &__text {
        font-size: 14px;
        font-weight: 600;
    }
}
</style>
