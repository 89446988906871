<template>
    <div class="flex product-background-animation">
        <img alt="" :src="`${SERVER_URI}/products/mini/1.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/2.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/3.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/4.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/5.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/6.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/7.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/8.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/9.png`" class="product-background-animation__image">
        <img alt="" :src="`${SERVER_URI}/products/mini/10.png`" class="product-background-animation__image">
    </div>
</template>

<script>
import { configuration } from "@/configuration";

export default {
    name: "ProductBackgroundAnimation",
    data () {
        return {
            SERVER_URI: configuration.uri,
        };
    },
};
</script>

<style lang="scss" scoped>
.product-background-animation {
    pointer-events: none;

    position: absolute;

    width: 80vw;
    height: 50vh;
    margin: 200px 0 0 0;

    z-index: 20000;

    &__image {
        position: absolute;

        width: 128px;
        z-index: 4593405834085;
    }
    &__image:nth-child(1) {
        animation: rotate 13s linear infinite;

        right: 58px;
        top: 18px;
    }
    &__image:nth-child(2) {
        animation: rotate-2 14s linear infinite;

        left: 158px;
        bottom: 68px;
    }
    &__image:nth-child(3) {
        animation: rotate 17s linear infinite;

        right: 158px;
        top: 158px;
    }
    &__image:nth-child(4) {
        animation: rotate-3 17s linear infinite;

        right: 458px;
        top: 158px;
    }
    &__image:nth-child(5) {
        animation: rotate 13s linear infinite;

        right: 258px;
        top: -38px;
    }
    &__image:nth-child(6) {
        animation: rotate-3 13s linear infinite;

        right: 258px;
        top: 358px;
    }
    &__image:nth-child(7) {
        animation: rotate-2 14s linear infinite;

        left: 358px;
        top: 258px;
    }
    &__image:nth-child(8) {
        animation: rotate 12s linear infinite;

        left: 50%;
        top: 358px;
    }
    &__image:nth-child(9) {
        animation: rotate-2 12.3s linear infinite;

        left: 100px;
        top: 98px;
    }
    &__image:nth-child(10) {
        animation: rotate-3 14s linear infinite;

        left: 50px;
        top: 128px;
    }

    @keyframes rotate {
        from {
            transform:
                rotate(0deg)
                translate(30px)
                rotate(0deg);
        }
        to {
            transform:
                rotate(360deg)
                translate(30px)
                rotate(-360deg);
        }
    }

    @keyframes rotate-2 {
        from {
            transform:
                rotate(360deg)
                translate(-40px)
                rotate(-360deg);
        }
        to {
            transform:
                rotate(0deg)
                translate(-40px)
                rotate(0deg);
        }
    }

    @keyframes rotate-3 {
        from {
            transform:
                rotate(0deg)
                translate(-30px)
                rotate(0deg);
        }
        to {
            transform:
                rotate(360deg)
                translate(-30px)
                rotate(-360deg);
        }
    }
}
</style>
