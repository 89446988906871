<template>
    <div class="flex flex--100 restaurant-table-management-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--x-align-center flex--y-align-center add-table-button" @click="showAddTableModalBox">
                    <span class="add-table-button__text">{{$t(`management.restaurantTableManagementTab.newTable`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex add-table-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center table-list">
                <span class="no-tables-text" v-if="tables.length === 0">{{$t(`management.restaurantTableManagementTab.noTables`)}}</span>
                <restaurant-table
                    v-for="table in tables"
                    :key="table.id"
                    :table="table"
                    :show-management-options="true"
                    @table-update="onTableUpdate"
                    @table-delete="onTableDelete"
                />
            </div>
        </div>
        <add-table-modal-box :restaurant-id="restaurantId" ref="addTableModalBox" @table-add="onTableAdd"/>
    </div>
</template>

<script>
import RestaurantTable from "@/components/RestaurantTable";
import AddTableModalBox from "@/components/windows/AddTableModalBox";
import { server } from "@/server";
import { user } from "@/user";
import { notificationCenter } from "@/components/utilities/NotificationCenter";

export default {
    name: "RestaurantTableManagementTab",
    components: {
        AddTableModalBox,
        RestaurantTable,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            tables: [],
            waitingFromServerResponse: false,
        };
    },
    methods: {
        async load (restaurantId) {
            const [ tables, ] = await Promise.all([ server.getRestaurantTables({ restaurantId, }),]);

            this.restaurantId = restaurantId;
            this.tables = tables;
        },

        showAddTableModalBox () {
            this.$refs.addTableModalBox.window.show();
        },

        onTableAdd (table) {
            this.tables.push(table);
        },

        onTableUpdate (table) {
            const foundTable = this.tables.find((t) => t.id === table.id);
            if (foundTable) {
                foundTable.seats = table.seats;
                foundTable.name = table.name;
            }
        },

        async onTableDelete (id) {
            if (this.waitingFromServerResponse) {
                return;
            }
            this.waitingFromServerResponse = true;
            try {
                await user.deleteTable({
                    id,
                });
                this.tables.splice(this.tables.findIndex((t) => t.id === id), 1);
                notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.tableRemovedSuccess`), });
            } catch (e) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
            }
            this.waitingFromServerResponse = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-table-button {
    cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }
}

.no-tables-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.table-list {
    margin: 0;
    padding: 24px;

    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.restaurant-table {
    margin: 12px 18px;
}
</style>
