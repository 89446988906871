<template>
    <div class="add-product-modal-box">
        <modal-box ref="window" :close-on-background-click="false">
            <form class="add-product-form">
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.price') }}</span>
                    <simple-textbox :placeholder="$t('management.generic.price')" v-model:model-value="price" ref="priceTextbox"/>
                </div>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.vat') }}</span>
                    <simple-textbox :placeholder="$t('management.generic.vat')" v-model:model-value="vat" ref="vatTextbox"/>
                </div>
                <!-- <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.previewImages') }}</span>
                    <simple-file-upload @upload="onPreviewImageUpload" :files-limit="1"/>
                </div> -->
                <hr class="separator"/>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.categories') }}</span>
                    <category-selector :categories="categories" v-model:selected="selectedCategories" :max="100"/>
                </div>
                <!-- <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.allergens') }}</span>
                    <allergen-selector :allergens="allergens" v-model:selected="selectedAllergens" :max="100"/>
                </div>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.variations') }}</span>
                    <variation-selector :variations="variations" v-model:selected="selectedVariations" :max="100"/>
                </div> -->
                <hr class="separator"/>
                <div v-for="localization in requiredLocalizations" :key="localization.iso" class="localization-section">
                    <h2 class="lang-title">{{ $t(localization.name) }}</h2>
                    <simple-textbox :placeholder="$t('management.generic.name')" v-model:model-value="localizations[localization.iso].name" ref="nameTextbox"/>
                    <simple-textbox :placeholder="$t('management.generic.description')" :is-multiline="true" v-model:model-value="localizations[localization.iso].description" class="spaced-textbox"/>
                </div>
                <hr class="separator"/>
                <div class="form-section">
                    <span class="input-descriptor-text">{{ $t('management.generic.products') }}</span>
                    <fixed-menu-product-selector :restaurantPresentation="restaurantPresentation" v-model:choices="choices"/>
                </div>
                <hr class="separator"/>
                <simple-button :text="$t('generic.add')" @click="addProduct" :is-loading="isWaitingServerResponse" class="submit-button"/>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CategorySelector from "@/components/inputs/CategorySelector";
import FixedMenuProductSelector from "@/components/inputs/FixedMenuProductSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
// import SimpleFileUpload from "@/components/inputs/SimpleFileUpload";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
// import AllergenSelector from "@/components/inputs/AllergenSelector";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
import { RestaurantProcessor } from "@/utilities";
// import VariationSelector from "@/components/inputs/VariationSelector";

export default {
    name: "AddFixedMenuWindow",
    props: {
        restaurantPresentation: {
            type: Object,
        },
        categories: Array,
        restaurantId: String,
        closeOnProductAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["fixed-menu-add"],
    components: {
        // SimpleFileUpload,
        CategorySelector,
        FixedMenuProductSelector,
        // VariationSelector,
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        // AllergenSelector,
    },
    data() {
        return {
            requiredLocalizations: [
                { name: "lang.en", iso: "en" },
                { name: "lang.zh", iso: "zh" },
                { name: "lang.it", iso: "it" },
            ],
            price: "",
            vat: "",
            selectedCategories: [],
            localizations: {
                en: {},
                zh: {},
                it: {},
            },
            choices: [],
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAddProductForm() {
            const priceTextbox = this.$refs.priceTextbox;
            const vatTextbox = this.$refs.vatTextbox;

            priceTextbox.clearErrors();
            vatTextbox.clearErrors();

            if (priceTextbox.validate(this.ProductAddErrorType.MISSING_PRICE, this.price.length > 0)) {
                priceTextbox.validate(this.ProductAddErrorType.INVALID_PRICE, Number.parseFloat(this.price) >= 0);
            }

            if (vatTextbox.validate(this.ProductAddErrorType.MISSING_VAT, this.vat.length > 0)) {
              vatTextbox.validate(this.ProductAddErrorType.INVALID_VAT, Number.parseFloat(this.vat) >= 0);
            }

            return (priceTextbox.errors.length + vatTextbox.errors.length) === 0;
        },

        clearProductForm() {
            this.price = "";
            this.selectedCategories = [];
            this.localizations = {
                en: {},
                zh: {},
                it: {},
            };
            this.choices = [];
        },

        onPreviewImageUpload(id) {
            this.selectedPreviewImages.push(id);
        },

        async addProduct() {
            if (!this.validateAddProductForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const product = await user.addFixedMenu({
                    price: this.price,
                    valueAddedTax: this.vat,
                    restaurantId: this.restaurantId,
                    previewImageId: null,
                    categories: this.selectedCategories,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                    choices: this.choices,
                });

                if (product) {
                    this.$emit("fixed-menu-add", product);

                    if (this.closeOnProductAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearProductForm();
                    notificationCenter.sendSuccessNotification({ text: this.$t('notification.productAddedSuccess') });
                } else {
                    notificationCenter.sendFailureNotification({ text: this.$t('notification.serverError') });
                }
            } catch (error) {
                notificationCenter.sendFailureNotification({ text: this.$t('notification.networkError') });
                console.error(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        window() {
            return this.$refs.window;
        },
        ProductAddErrorType() {
            return {
              MISSING_PRICE: {
                  id: 1,
                  text: this.$t('management.generic.missingValue'),
              },
              INVALID_PRICE: {
                  id: 2,
                  text: this.$t('management.generic.invalidValue'),
              },
              MISSING_VAT: {
                  id: 3,
                  text: this.$t('management.generic.missingValue'),
              },
              INVALID_VAT: {
                  id: 4,
                  text: this.$t('management.generic.invalidValue'),
              },
            };
        },
        fixedMenuCategoryId () {
            return this.restaurantProcessor.fixedMenuCategoryId;
        },
    },
    mounted() {
        document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.add-product-modal-box {
    :deep(.modal-box__slot) {
        width: auto;
        min-width: 400px;
        max-width: 80vw;
        min-height: 50vh;
        padding: 30px;
        margin: 30px auto;
        background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }

    :deep(.simple-textbox) {
        width: 350px;
    }
}

.add-product-form {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.separator {
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background-color: #dcdcdc;
}

.input-descriptor-text {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
}

.form-section {
    margin-bottom: 15px;
}

.spaced-textbox {
    margin-top: 10px;
}

.submit-button {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background-color: $primary-brand-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: $primary-brand-color-hover;
}

.localization-section {
    margin-bottom: 10px;
}
</style>
