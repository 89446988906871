<template>
    <div class="flex flex--100 restaurant-media-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                <div class="flex flex--100">
                    <h2 class="lang-title">{{ $t(requiredLocalization.name) }}</h2>
                    <h2 class="lang-title" v-if="requiredLocalization.name.slice(-2) === 'it'">({{ $t("generic.default") }})</h2>
                </div>
                <simple-file-upload @upload="onPreviewImageUpload($event, requiredLocalization.name)" :files-limit="1" :maxSizeKb="10240"/>
                <hr class="separator"/>
            </div>
            <div class="flex flex--100">
                <simple-button @click="deleteSplashAds" :is-loading="isWaitingServerResponse" :text="$t(`management.generic.deleteAds`).toLocaleUpperCase()" />
            </div>
        </div> 
    </div>
</template>

<script>
import SimpleFileUpload from "@/components/inputs/SimpleFileUpload";
import SimpleButton from "@/components/inputs/SimpleButton";
import { server } from "@/server";
import { user } from "@/user";

export default {
    name: "RestaurantPosterTab",
    components: {
        SimpleFileUpload,
        SimpleButton,
    },
    data () {
        return {
            isWaitingServerResponse: false,
            restaurantId: -1,
            images: [],
            selectedImages: [],
            requiredLocalizations: [{
                name: "lang.en",
                iso: "en",
            }, {
                name: "lang.zh",
                iso: "zh",
            }, {
                name: "lang.it",
                iso: "it",
            },],
        };
    },
    methods: {
        async load (restaurantId) {
            this.images = await server.getImages();
            this.restaurantId = restaurantId;
        },

        async onPreviewImageUpload (imageId, lang) {
            if (this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            try {
                 await user.updateRestaurantSplashAd({
                    restaurantId: this.restaurantId,
                    locale: lang.slice(-2),
                    imageId,
                });
            }
            catch (e) {
                console.log(e);
            }
           
            this.isWaitingServerResponse = false;
        },

        async deleteSplashAds () {
            if (this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            try {
                await user.updateRestaurantSplashAd({
                    restaurantId: this.restaurantId,
                    delete: true,
                });
            }
            catch (e) {
                console.log(e);
            }
            this.isWaitingServerResponse = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.lang-title {
    font-size: 24px;
    font-weight: 500;
    margin: 0px 10px 10px 0px;
}
</style>
