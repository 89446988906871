<template>
    <div class="application" :class="getModifiers()">
        <!-- Use components cache for Kiosk to improve performance -->
        <keep-alive v-if="isKiosk">
            <router-view/>
        </keep-alive>
        <router-view v-else/>
        <notification-center/>
    </div>
</template>

<script>
import NotificationCenter from "@/components/utilities/NotificationCenter";
import {
    isKiosk,
    isDesktop,
    isMobile,
} from "@/main";

export default {
    name: "Application",
    components: {
        NotificationCenter,
    },
    methods: {
        getModifiers () {
            return {
                "application--kiosk": isKiosk,
                "application--desktop": isDesktop,
                "application--mobile": isMobile,
            };
        },
    },
    computed: {
        isKiosk () {
            return isKiosk;
        },
    },
    mounted () {
        window.document.title = "";
        if (isMobile) {
            let lastTouchEnd = 0;
            document.documentElement.addEventListener("touchend", (event) => {
                let now = Date.now();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            }, {
                passive: false,
            });
        }
    },
};
</script>

<style lang="scss">
@import "~@/css/application.scss";

.application {
    &--kiosk {
        * {
            -webkit-user-select: none;
        }
    }

    &--mobile {
        overflow: hidden;
        position: relative;
        touch-action: manipulation;

        * {
            touch-action: manipulation;
        }
    }
}
</style>
