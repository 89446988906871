<template>
    <div class="flex flex--100 cook-order-point-view">
        <language-selector/>
        <div class="flex main">
            <div class="flex flex--100 flex--x-align-center flex--y-align-start">
                <span class="main__box-title">{{ $t("cashierPos.ordersInPreparation") }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center main__list">
                <div class="flex flex--x-align-center main__box" v-for="order in sortedOrdersInPreparation" :key="order.id">
                    <div class="flex order">
                        <div class="flex flex--100 flex--y-align-center">
                            <div class="flex flex--70">
                                <h2 class="order__daily-id-text">#{{ order.dailyId }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex header">
         <div class="flex flex--100 flex--x-align-center flex--y-align-start">
                <span class="main__box-title2">{{ $t("cashierPos.ordersInDelivery") }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center main__list">
                <div class="flex flex--x-align-center main__box" v-for="order in sortedCompletedOrders" :key="order.id">
                    <div class="flex order closed-order">
                        <div class="flex flex--100 flex--y-align-center">
                            <div class="flex flex--70">
                                <h2 class="order__daily-id-text">#{{ order.dailyId }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="time">{{ normalizedTimeString }}</span>
        <div class="flex flex--100 logo">
            <primary-logo/>
        </div>
    </div>
</template>

<script>
import LanguageSelector from "@/components/inputs/LanguageSelector";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { isDesktop, } from "@/main";
import { OrderDeliveryLocation, OrderStatus, } from "@/orders/RestaurantOrder";
import {
    server,
    WS_API_URI,
} from "@/server";
import {
    RestaurantProcessor,
    WSKeepAlive,
} from "@/utilities";
import { cloneDeep } from "lodash";
// import { notificationCenter } from "@/components/utilities/NotificationCenter";

const preloadedData = {};
let primaryWs;

function createPrimaryWs () {
    try {
        primaryWs = new WSKeepAlive(WS_API_URI);
    }
    catch {
        // Silence is golden.
    }
}

export default {
    async beforeRouteEnter (to, from, next) {
        const restaurantId = to.params.restaurantId;
        const [ restaurantPresentation, ordersInPreparation, ordersInDelivery, ] = await Promise.all([
            server.getRestaurantPresentation(restaurantId),
            server.getRestaurantOrders(restaurantId, OrderStatus.IN_PREPARATION),
            server.getRestaurantOrders(restaurantId, OrderStatus.IN_DELIVERY),
            server.getRestaurantOrders(restaurantId, OrderStatus.CLOSED),
        ]);

        preloadedData.restaurantPresentation = restaurantPresentation;
        preloadedData.ordersInPreparation = [ ...ordersInPreparation, ...ordersInDelivery, ];
        next();
    },

    name: "UserOrderStatusView",
    components: {
        LanguageSelector,
        PrimaryLogo,
    },
    data () {
        return {
            OrderDeliveryLocation,
            ordersInPreparation: preloadedData.ordersInPreparation,
            completedOrders: [],
            isWaitingServerResponseForCompletedOrders: false,
            ordersListenerIntervalId: undefined,
            time: new Date(),
        };
    },
    methods: {
        // <modifiers>
        getOrderProductModifiers (product) {
            return {
                "product--in-preparation": !this.orderProductIsCompleted(product),
            };
        },

        getOrderProductNameModifiers (product) {
            return {
                "product__name-text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderProductSpecialRequestTextModifiers (product) {
            return {
                "product__special-request-text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderProductVariationTextModifiers (product) {
            return {
                "product-variation-box__text--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderFixedMenuModifiers (menu) {
            return {
                "product__name-text--delivered": this.orderFixedMenuIsDelivered(menu),
            };
        },

        getOrderFixedMenuProductModifiers (product) {
            return {
                "fixed-menu-product__name--delivered": this.orderProductIsDelivered(product),
            };
        },

        getOrderCustomProductModifiers (menu) {
            return {
                "product__name-text--delivered": this.orderCustomProductIsDelivered(menu),
            };
        },

        getOrderCustomProductProductModifiers (product) {
            return {
                "fixed-menu-product__name--delivered": this.orderProductIsDelivered(product),
            };
        },
        // </modifiers>

        exitApplication () {
            try {
                const { ipcRenderer, } = window.require("electron");

                primaryWs.close();
                ipcRenderer.send("exit");
            }
            catch {
                // Silence is golden.
            }
        },

        orderProductIsCompleted (product) {
            return product.requestedVolume === product.completedVolume;
        },

        getOrderProductVolumeToDeliver (orderProduct) {
            const requestedVolume = orderProduct.requestedVolume;
            const deliveredVolume = orderProduct.deliveredVolume;

            if (deliveredVolume === 0 || deliveredVolume === requestedVolume) {
                return requestedVolume;
            }

            return requestedVolume - deliveredVolume;
        },

        orderProductIsDelivered (product) {
            return product.requestedVolume === product.deliveredVolume;
        },

        orderFixedMenuIsCompleted (orderFixedMenu) {
            return orderFixedMenu.products.every((product) => this.orderProductIsCompleted(product));
        },

        orderFixedMenuIsDelivered (orderFixedMenu) {
            return orderFixedMenu.products.every((product) => this.orderProductIsDelivered(product));
        },

        orderCustomProductIsCompleted (orderCustomProduct) {
            return orderCustomProduct.products.every((product) => this.orderProductIsCompleted(product));
        },

        orderCustomProductIsDelivered (orderCustomProduct) {
            return orderCustomProduct.products.every((product) => this.orderProductIsDelivered(product));
        },

        getOrderProductVariationsByVolumeIndex (orderProduct, volumeIndex) {
            return orderProduct.variations.filter(
                (variation) => variation.volumeIndex === volumeIndex
            ).map(
                (variation) => this.restaurantProcessor.getVariationById(variation.id)
            );
        },

        orderProductVolumeIndexHasVariations (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).length > 0;
        },

        getOrderProductVariationsTextByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (text, variation) => text += ", " + this.restaurantProcessor.getVariationLocalizedName(variation.id), ""
            );
        },

        async updateOrdersInPreparation () {
            this.ordersInPreparation = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_PREPARATION);
        },

        async updateOrdersInPreparationAndInDelivery () {
            const ordersInDelivery = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_DELIVERY);
            const ordersInPreparation = await server.getRestaurantOrders(this.restaurantId, OrderStatus.IN_PREPARATION);
            this.ordersInPreparation = [ ...ordersInPreparation, ...ordersInDelivery];
        },

        getOrderBaseProducts (order) {
            return order.products.filter((product) =>
                !this.restaurantProcessor.productIsFixedMenu(Number.parseInt(product.id))
            && !this.restaurantProcessor.productIsCustomProduct(Number.parseInt(product.id)));
        },

        getOrderFixedMenus (order) {
            return order.products.filter((product) => this.restaurantProcessor.productIsFixedMenu(Number.parseInt(product.id)));
        },

        getOrderCustomProducts (order) {
            return order.products.filter((product) => this.restaurantProcessor.productIsCustomProduct(Number.parseInt(product.id)));
        },

        getOrderTimeString (order) {
            return new Date(order.creationTimestamp).toLocaleTimeString(this.$i18n.locale).split(":", 2).join(":");
        },
    },
    computed: {
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },

        variations () {
            return this.restaurantProcessor.variations;
        },
        // </restaurant>

        isDesktop () {
            return isDesktop;
        },

        sortedOrdersInPreparation () {
            return [ ...this.ordersInPreparation, ].sort((a, b) => a.creationTimestamp - b.creationTimestamp);
        },

        sortedCompletedOrders () {
            return [ ...this.completedOrders, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },

        hasOrdersToPrepare () {
            return this.ordersInPreparation.length > 0;
        },

        normalizedTimeString () {
            return `${this.time.toLocaleDateString(this.$i18n.locale)} ${this.time.toLocaleTimeString(this.$i18n.locale).split(":", 2).join(":")}`;
        },

        skipDeliveryState () {
            return ![ 1, ].includes(this.restaurantId);
        },

        paymentAfterService () {
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        }
    },
    created () {
        this.ordersListenerIntervalId = setInterval(() => {
            if (!primaryWs || (primaryWs && (primaryWs.readyState === WebSocket.CLOSED || primaryWs.readyState === WebSocket.CLOSING))) {
                this.updateOrdersInPreparation();
            }
        }, 1000 * 20);

        setInterval(() => {
            this.time = new Date();
        }, 60000);

        try {
            if (!primaryWs) {
                createPrimaryWs();
            }

            primaryWs.addEventListener("open", () => {
                primaryWs.send(JSON.stringify({
                    restaurantId: this.restaurantId,
                    service: {
                        name: "POD"
                    }
                }));
            });

            primaryWs.addEventListener("message", (event) => {
                let message;

                try {
                    message = JSON.parse(event.data);
                }
                catch {
                    return;
                }

                if (message.type === "RestaurantAuthentication" && message.result === "OK") {
                    console.log("connected to server");
                    this.updateOrdersInPreparationAndInDelivery();
                }

                if (message.type === "OrderInPreparationNotification" || message.type === "OrderInDeliveryNotification" || message.type === "OrderProductUpdateNotification" || message.type === "OrderProductDeliveryUpdateNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct) {
                        return;
                    }

                    if (firstProduct.restaurantId === this.restaurantId) {
                        const index = this.ordersInPreparation.findIndex((entity) => entity.id === order.id);

                        if (index !== -1) {
                            const copy = cloneDeep(this.ordersInPreparation);

                            copy[index] = order;
                            this.ordersInPreparation = copy;
                        }
                        else {
                            this.ordersInPreparation.push(order);
                        }

                    }
                }
                else if (message.type === "OrderToPayNotification" || message.type === "OrderClosedNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct) {
                        return;
                    }

                    if (firstProduct.restaurantId === this.restaurantId) {
                        this.ordersInPreparation = this.ordersInPreparation.filter((orderInPreparation) => orderInPreparation.id !== order.id);
                        if (this.completedOrders.findIndex((closed) => closed.id === order.id) === -1) {
                            this.completedOrders.push({
                                ...order,
                            });
                            setTimeout(() => {
                               this.completedOrders = this.completedOrders.filter((closed) => closed.id !== order.id);
                            }, 60000);
                        }
                    }
                }
            });
            primaryWs.keepAlive();
        }
        catch (error) {
            console.log(error);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.cook-order-point-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.header {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap;

    width: 50%;
    height: 100%;

    background-color: rgb(44, 44, 44);
}

.main {
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: wrap;

    width: 50%;
    height: 100%;

    padding-left: 20px;

    //background-color: rgb(240, 240, 240);
    background-color: rgb(220, 220, 220); // Darker upon request

    &__list {
        margin: 50px 0;
    }
    &__box {
        width: fit-content;
        margin: 10px;

        &-title {
            margin-top: 20px;

            font-size: 65px;
            font-weight: 800;
            color: rgb(44, 44, 44);
        }

        &-title2 {
            margin-top: 20px;

            font-size: 65px;
            font-weight: 800;
            color: rgb(220, 220, 220);
        }
    }
}

.order {
    position: relative;

    width: fit-content;
    margin: 10px 0 5px 0;
    padding: 10px 15px;

    //background-color: rgb(255, 255, 255);
    background-color: $primary-brand-color;
    background-image: linear-gradient(45deg, rgb(231, 181, 55), $primary-brand-color);
    border-radius: 12px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);
    z-index: 10;

    &__daily-id-text {
        font-size: 60px;
        font-weight: 800;
        //color: rgb(55, 55, 55);
        color: rgb(11, 11, 11);
    }

    &__time-text {
        margin-bottom: 28px;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 800;
        //color: rgb(60, 60, 60);
        color: rgb(11, 11, 11);
    }
}

.closed-order {
    background-color: #33A852!important;
    background-image: linear-gradient(45deg, #33A852, #33A852);
}

.order-list-modal-box {
    :deep(.restaurant-order) {
        width: 40%;
        margin: 25px;

        &__expanded {
            height: auto;
        }

        .order-status,
        .restaurant-name,
        .id,
        .origin,
        .payment-method,
        .payment-date {
            display: none;
        }
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);
}

.primary-logo {
    width: 512px;
    height: 512px;
    opacity: 0.4;
    margin: 48px 0 24px 0;

    fill: rgb(255, 255, 255);
}

.no-orders-in-preparation {
    overflow: hidden;

    height: 100%;

    &__text {
        font-size: 24px;
        font-weight: 600;
        color: rgb(150, 150, 150);
    }
}

.fixed-menu-product-list {
    margin: 0 0 0 20px;
}
.fixed-menu-product {
    cursor: pointer;

    margin-top: 12px;

    &__name {
        position: relative;

        margin-left: 12px;

        //font-size: 18px;
        font-size: 24px; // Bigger upon request
        font-weight: 500;
        //color: rgb(33, 33, 33);
        color: rgb(11, 11, 11);

        &--delivered {
            color: rgb(53, 53, 53);
        }
        &--delivered::before {
            content: "";

            position: absolute;
            left: 50%;
            top: 50%;

            width: calc(100% + 10px);
            height: 2px;

            //background-color: $primary-brand-color;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

            transform: translate(-50%, 50%);
        }
    }
}

.time {
    position: absolute;
    right: 3vw;
    bottom: 2vh;

    font-size: 25px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}

.language-selector {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;

    :deep(.language-selector__box) {
        @extend .flex--direction-column;
    }

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
    }
    :deep(.language.language--selected) {
        background-color: transparent;
    }
    :deep(.language .language__name) {
        display: none;
    }
    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }
    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }
    :deep(.language + .language) {
        margin: 0;
    }
}

.logo {
    z-index: 5;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    justify-content: center;
}
</style>
