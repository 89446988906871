<template>
    <div class="flex flex--100 product-variation-modal-box" :class="getModifiers()">
        <modal-box ref="modalBox" :show-close-button="false" :show-back-button="true">
            <form class="flex flex--100 product-variation-form" v-if="product">
                <h2 class="title">x{{ selectedVolume }} {{ getProductLocalizedName(product) }}</h2>
                <div class="flex flex--100 flex--x-align-center" v-if="product.previewImage">
                    <img :src="product.previewImage.uri" alt="" class="product-image" :class="getProductImageModifiers()"/>
                </div>
                <hr class="separator separator--no-margin-bottom" :class="getSeparatorModifiers()"/>
                <div class="flex flex--100 flex--x-align-center variation-volume-index-box" :class="getVolumeModifiers()">
                    <span
                        class="select-variation-volume-index-button"
                        v-for="(item, i) in selectedVolume"
                        :key="i"
                        :class="getVariationVolumeIndexButtonModifiers(i)"
                        @click="selectVariationVolumeIndex(i)"
                    >
                        <span class="flex selected-variations-length-text flex--x-align-center" v-show="selectedVariations[i].length > 0">
                            {{ selectedVariations[i].length }}
                        </span>
                        x1
                    </span>
                </div>
                <hr class="separator separator--no-margin-top" :class="getSeparatorModifiers()"/>
                <div class="flex flex--100 variation-list" :class="getVariationListModifiers()">
                    <simple-button
                        class="toggle-variation-button"
                        v-for="variation in availableProductVariations"
                        :key="variation.id"
                        :class="getVariationButtonModifiers(variation)"
                        :text="getVariationButtonText(variation)"
                        @click="toggleVariationSelection(variation.id)"
                    />
                </div>
                <hr class="separator" :class="getSeparatorModifiers()"/>
                <div class="flex flex--100" :class="getConfirmBarModifiers()">
                    <simple-button
                        v-if="!isMobile"
                        :text='$t("generic.cancel")'
                        @click="cancel"
                    />
                    <simple-button 
                        :class="getConfirmBtnModifiers()"
                        :text="confirmButtonText"
                        @click="confirm"
                    />
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import { cloneDeep, } from "lodash";
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { isKiosk, isMobile } from '@/main';

export default {
    name: "ProductVariationModalBox",
    components: {
        ModalBox,
        SimpleButton,
    },
    props: {
        variations: {
            type: Array,
        },
    },
    emits: [ "confirm" ],
    data () {
        return {
            selectedVariations: undefined,
            selectedVariationsIndex: undefined,
            product: undefined,
            selectedVolume: undefined,
            isMobile,
        };
    },
    computed: {
        selectedVariationsByIndex () {
            return this.selectedVariations[this.selectedVariationsIndex];
        },

        selectedVariationsByIndexTotalPrice () {
            return this.selectedVariationsByIndex.reduce((total, id) => total + this.getVariationById(id).price, 0);
        },

        selectedVariationsTotalPrice () {
            let totalPrice = 0;

            this.selectedVariations.forEach((singleVolumeVariations) => {
                totalPrice += singleVolumeVariations.reduce((singleVolumeTotalPrice, id) => singleVolumeTotalPrice + this.getVariationById(id).price, 0);
            });

            return totalPrice;
        },

        availableProductVariations () {
            if (!this.product) {
                return [];
            }

            return this.variations.filter((variation) => this.product.variations.some((productVariation) => variation.id === productVariation.id));
        },

        confirmButtonText () {
            const totalPrice = this.selectedVariationsTotalPrice;
            let text = this.$t("generic.confirm");

            if (totalPrice !== 0) {
                if (totalPrice > 0) {
                    text += ` +${this.normalizePriceToDisplay(totalPrice)} €`;
                }
                else {
                    text += ` ${this.normalizePriceToDisplay(totalPrice)} €`;
                }
            }

            return text;
        },
    },
    methods: {
        getModifiers () {
            return {
                "product-variation-modal-box--kiosk": isKiosk,
                "product-variation-modal-box--mobile": isMobile,
            };
        },

        getVolumeModifiers () {
            return {
                "variation-volume-index-box--mobile": isMobile,
            };
        },

        getVariationListModifiers () {
            return {
                "variation-list--mobile": isMobile,
            };
        },

        getConfirmBarModifiers () {
            return {
                "confirm-bar--mobile": isMobile,
            };
        },

        getCancelBtnModifiers () {
            return {
                "cancel-btn--mobile": isMobile,
            };
        },

        getConfirmBtnModifiers () {
            return {
                "confirm-btn--mobile": isMobile,
            };
        },

        getSeparatorModifiers () {
             return {
                "separator--mobile": isMobile,
            };
        },

        getProductImageModifiers () {
             return {
                "product-image--mobile": isMobile,
            };
        },

        getVariationVolumeIndexButtonModifiers (index) {
            return {
                "select-variation-volume-index-button--selected": index === this.selectedVariationsIndex,
            };
        },

        selectVariationVolumeIndex (index) {
            this.selectedVariationsIndex = index;
        },

        getVariationButtonModifiers (variation) {
            return {
                "toggle-variation-button--selected": this.hasSelectedVariation(variation.id),
                "toggle-variation-button--mobile": isMobile,
            };
        },

        hasSelectedVariation (id) {
            return this.selectedVariationsByIndex.includes(id);
        },

        toggleVariationSelection (id) {
            const index = this.selectedVariationsByIndex.indexOf(id);
            const selectedVariationsCopy = cloneDeep(this.selectedVariations);

            if (index === -1) {
                this.selectedVariationsByIndex.push(id);
            }
            else {
                this.selectedVariationsByIndex.splice(index, 1);
            }

            selectedVariationsCopy[this.selectedVariationsIndex] = cloneDeep(this.selectedVariationsByIndex);

            this.selectedVariations = selectedVariationsCopy;
        },

        show ({ product, selectedVolume, selectedVariations = [], }) {
            this.selectedVariations = [];

            selectedVariations.forEach((singleVolumeVariations, i) => this.selectedVariations[i] = cloneDeep(singleVolumeVariations));

            this.selectedVariationsIndex = 0;

            for (let i = 0; i < selectedVolume; ++i) {
                this.selectedVariations[i] = this.selectedVariations[i] ?? [];
            }

            this.product = product;
            this.selectedVolume = selectedVolume;

            this.$refs.modalBox.show();
        },

        cancel () {
            this.$refs.modalBox.hide();

            this.selectedVariations = undefined;
            this.selectedVariationsIndex = undefined;
            this.product = undefined;
            this.selectedVolume = undefined;
        },

        confirm () {
            const selectedVariations = {};

            this.selectedVariations.forEach((singleVolumeVariations, i) => selectedVariations[i] = singleVolumeVariations);

            this.$emit("confirm", {
                product: this.product,
                selectedVariations,
            });

            this.cancel();
        },

        getVariationById (id) {
            return this.variations.find((variation) => variation.id === id);
        },

        getVariationButtonText (variation) {
            const { price } = variation;
            let text = this.getVariationLocalizedName(variation);

            if (price !== 0) {
                if (price > 0) {
                    text += ` +${this.normalizePriceToDisplay(price)} €`;
                }
                else {
                    text += ` ${this.normalizePriceToDisplay(price)} €`;
                }
            }

            return text;
        },

        getVariationLocalization (variation, languageIso) {
            return variation.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getVariationLocalizedName (variation) {
            return this.getVariationLocalization(variation, this.$i18n.locale)?.name ?? "name";
        },

        getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getProductLocalizedName (product) {
            return this.getProductLocalization(product, this.$i18n.locale)?.name ?? "";
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.product-variation-modal-box--kiosk {
    :deep(.modal-box__slot) {
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 90vh;
        margin: 0;
        padding: 0;

        border-radius: 22px 22px 0 0;
    }
}

.product-variation-form {
    padding: 50px;
}

.product-variation-modal-box--kiosk {
    .product-variation-form {
        height: 90vh;
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);

    &--no-margin-bottom {
        margin-bottom: 0;
    }

    &--no-margin-top {
        margin-top: 0;
    }
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-button {
    & + & {
        margin-left: 16px;
    }
}

.variation-list {
    overflow: auto;

    max-height: 30vh;
    padding: 24px;

    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .toggle-variation-button {
        margin: 2px;

        border-radius: 0;

        &--selected {
            background-color: $primary-brand-color;
        }
    }
}

.title {
    font-weight: 600;
    font-size: 24px;
    color: rgb(11, 11, 11);
}

.variation-volume-index-box {
    margin: 33px 0;
}

.select-variation-volume-index-button {
    cursor: pointer;

    position: relative;

    margin: 12px;
    padding: 12px 22px;

    background-color: rgb(240, 240, 240);
    border-radius: 12px;

    font-size: 18px;
    font-weight: 600;
    color: rgb(11, 11, 11);

    &--selected {
        background-color: $primary-brand-color;

        color: rgb(255, 255, 255);
    }
}

.selected-variations-length-text {
    position: absolute;
    right: -10px;
    top: -10px;

    width: 24px;
    height: 24px;

    background-color: rgb(227, 65, 43);
    border-radius: 50%;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: rgb(255, 255, 255);
}

.product-image {
    width: 174px;

    margin-top: 45px;

    border-radius: 12px;
}

.product-image--mobile {
    width: 48px;

    margin-top: 20px;

    border-radius: 12px;
}

.product-variation-modal-box--mobile {
    :deep(.modal-box) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    :deep(.modal-box__slot) {
        width: 100%;
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        margin: 10vh 0 0 0;
        margin: calc(var(--vh, 1vh) * 10) 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.product-variation-modal-box--mobile {
    .product-variation-form {
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        padding: 20px;
        align-content: space-evenly;
    }
}

.variation-volume-index-box--mobile {
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: column;
    margin: 0;
    height: 12%;
}

.variation-list--mobile {
    max-height: 35%;
    padding: 12px;
    justify-content: space-evenly;
    overflow-y: auto;
    overflow-x: hidden;

    border-radius: 6px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.toggle-variation-button--mobile {
    margin: 2px;
    min-width: 100%;
}

.confirm-bar--mobile {
    padding-bottom: 30px;
    justify-content: center;
}

.confirm-btn--mobile {
    background-color: $primary-brand-color;
    border: 2px solid $primary-brand-color;
    border-radius: 1000px;

    :deep(.simple-button__text) {
        color: rgb(255, 255, 255);
    }
}

.separator--mobile {
    margin: 15px 0;
}
</style>
