<template>
    <div class="notification-box" :class="getModifiers()" @mouseenter="cancelExpiration" @mouseleave="startExpiration">
        <p class="notification-box__text">{{ text }}</p>
        <div class="close-button" @click="expire" v-if="showCloseButton">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex close-button__icon">
                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                    <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                    <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export const NotificationBoxAppearance = {
    NEUTRAL: "neutral",
    SUCCESS: "success",
    FAILURE: "failure",
};

export default {
    name: "NotificationBox",
    props: {
        text: {
            type: String,
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        appearance: {
            type: String,
            default: NotificationBoxAppearance.NEUTRAL,
        },
    },
    data () {
        return {
            NotificationBoxAppearance,
            expirationId: null,
            isExpired: false,
        };
    },
    methods: {
        getModifiers () {
            return {
                "notification-box--expired": this.isExpired,
                "notification-box--success": this.appearance === NotificationBoxAppearance.SUCCESS,
                "notification-box--failure": this.appearance === NotificationBoxAppearance.FAILURE,
            };
        },

        cancelExpiration () {
            if (this.expirationId) {
                clearTimeout(this.expirationId);

                this.expirationId = null;
            }
        },

        startExpiration () {
            this.expirationId = setTimeout(() => {
                this.expire();
            }, 5000);
        },

        expire () {
            this.isExpired = true;
        },
    },
    watch: {
        isExpired (value) {
            if (value) {
                setTimeout(() => {
                    try {
                        this.$destroy();
                    } catch {
                        // silence si golden
                    }
                    this.$el.parentNode.removeChild(this.$el);
                }, 1010);
            }
        },
    },
    mounted () {
        this.startExpiration();
    },
};
</script>

<style lang="scss" scoped>
.notification-box {
    transition: transform 1s, opacity 0.5s;

    position: relative;
    width: 368px;
    height: auto;
    padding: 20px 42px 20px 20px;

    background-color: rgb(240, 240, 240);
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 12px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.042), 0 0 26px 0 rgba(0, 0, 0, 0.054);

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(50, 50, 50);
    }

    &--failure {
        outline-color: rgb(227, 130, 130);
        background-color: rgb(255, 242, 244);
    }
    &--failure &__text {
        color: rgb(227, 50, 50);
    }

    &--expired {
        transform: translateX(-400px);

        opacity: 0;
    }
}

.close-button {
    cursor: pointer;

    position: absolute;
    right: 12px;
    top: 50%;

    padding: 10px;

    &__icon {
        width: 18px;

        fill: rgb(60, 60, 60);

        transform: rotate(45deg);
    }

    transform: translateY(-50%);
}
.notification-box--failure .close-button__icon {
    fill: rgb(227, 70, 70);
}
</style>
