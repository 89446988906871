<template>
    <div class="flex flex--100 update-product-category-visibility-modal-box">
        <modal-box ref="window" @hide="clear" :close-on-background-click="false" :append-to-body="true">
            <form class="flex flex--100">
                <div class="flex flex--100 preview-title">
                    <h2>{{ $t('management.generic.preview') }}</h2>
                    <span class="tips"> {{  $t("management.restaurantProductManagementTab.layoutTips") }}</span>
                </div>
                 <div class="flex flex--100 preview-box">
                    <category-filter
                        :categories="listedCategories"
                        :value="selectedCategoryId"
                        v-model:model-value="selectedCategoryId"
                    />
                    <div class="flex flex--100 slider">
                        <splide @splide:mounted="goToSelectedProductCard" :options="splideOptions" :extensions="splideExtensions" :key="selectedCategoryId" ref="productSplide">
                            <splide-slide v-for="product in filteredProducts" :key="product.id">
                                <div class="flex flex--100 item">
                                    <div class="flex flex--100 card" :class="getModifiers(product.id)" @click="changeProduct(product)">
                                        <span class="card__index">{{  $t('management.restaurantProductManagementTab.index')  }}: {{ product.categories.find((c) => c.id === selectedCategoryId).index }}</span>
                                        <svg v-if="product.categories.find((c) => c.id === selectedCategoryId).visible" class="visible-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" viewBox="0 0 512 512" enable-background="new 0 0 512 512">
                                            <g>
                                                <path d="m494.8,241.4l-50.6-49.4c-50.1-48.9-116.9-75.8-188.2-75.8s-138.1,26.9-188.2,75.8l-50.6,49.4c-11.3,12.3-4.3,25.4 0,29.2l50.6,49.4c50.1,48.9 116.9,75.8 188.2,75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zm-238.8,84.4c-38.5,0-69.8-31.3-69.8-69.8 0-38.5 31.3-69.8 69.8-69.8 38.5,0 69.8,31.3 69.8,69.8 0,38.5-31.3,69.8-69.8,69.8zm-195.3-69.8l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2,20.1-46.6,53-46.6,90.1 0,37.1 18.4,70.1 46.6,90.1-35.9-10.2-68.7-29-95.7-55.3l-35.7-34.7zm355,34.8c-27,26.3-59.8,45.1-95.7,55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9,10.2 68.7,29 95.7,55.4l35.6,34.8-35.6,34.7z"/>
                                            </g>
                                        </svg>
                                        <svg v-else class="visible-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 489.658 489.658" xml:space="preserve">
                                            <path d="M485.313,252.34l4.345-7.511l-4.345-7.511c-23.974-41.44-58.446-76.197-99.691-100.511  c-42.473-25.038-91.117-38.28-140.681-38.3c-0.037,0-0.074-0.001-0.112-0.001s-0.074,0.001-0.112,0.001  c-36.01,0.014-71.531,7.015-104.556,20.441L27.936,6.723L6.723,27.936L111.407,132.62c-2.476,1.358-4.935,2.751-7.371,4.187  c-41.245,24.314-75.718,59.07-99.691,100.511L0,244.829l4.345,7.511c23.974,41.44,58.446,76.197,99.691,100.511  c42.473,25.038,91.117,38.28,140.681,38.3c0.037,0,0.074,0.001,0.112,0.001s0.074-0.001,0.112-0.001  c36.01-0.014,71.531-7.015,104.556-20.441l112.226,112.226l21.213-21.213L378.251,357.038c2.476-1.358,4.935-2.751,7.371-4.187  C426.867,328.537,461.34,293.781,485.313,252.34z M454.819,244.829c-22.94,36.587-54.809,66.03-91.791,86.144  c17.673-24.184,28.124-53.964,28.124-86.144s-10.45-61.96-28.124-86.144C400.01,178.799,431.879,208.242,454.819,244.829z   M244.829,361.152c-0.036,0-0.071-0.001-0.107-0.001c-64.092-0.058-116.217-52.217-116.217-116.322  c0-26.675,9.031-51.276,24.189-70.922l47.815,47.815c-3.621,6.916-5.681,14.773-5.681,23.106c0,27.57,22.43,50,50,50  c8.333,0,16.19-2.06,23.106-5.681l47.815,47.815c-19.619,15.137-44.181,24.163-70.815,24.187  C244.9,361.151,244.865,361.152,244.829,361.152z M244.829,128.506c0.036,0,0.071,0.001,0.107,0.001  c64.092,0.058,116.217,52.217,116.217,116.322c0,26.675-9.031,51.276-24.189,70.922l-47.815-47.815  c3.621-6.916,5.681-14.773,5.681-23.106c0-27.57-22.43-50-50-50c-8.333,0-16.19,2.06-23.106,5.681l-47.815-47.815  c19.619-15.137,44.181-24.163,70.815-24.187C244.758,128.507,244.793,128.506,244.829,128.506z M34.839,244.829  c22.94-36.587,54.809-66.03,91.791-86.144c-17.673,24.184-28.124,53.964-28.124,86.144s10.45,61.96,28.124,86.144  C89.648,310.859,57.779,281.416,34.839,244.829z"/>
                                        </svg>
                                        <div class="flex flex--100 flex--x-align-center preview-mobile">
                                            <img class="preview-mobile__image" :src="product.previewImage.uri" loading="lazy" alt="" v-if="product.previewImage">
                                            <div v-else class="preview-mobile__no-image"></div>
                                        </div>
                                        <div class="flex flex--100 flex--x-align-center">
                                            <span class="name">{{ restaurantProcessor.getProductLocalizedName(product.id) }}</span>
                                        </div>
                                        <div class="flex flex--100 flex--x-align-center">
                                            <span class="price">€ {{ normalizePriceToDisplay(product.price) }}</span>
                                            
                                            <span v-if="product.popular && product.categories.findIndex((c) => c.excludeFromTopSeller) === -1" class="flex flex--y-align-center popular">
                                                <svg viewBox="0 0 154 145" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                                                        <polygon points="77 120 29.9771798 144.72136 38.9577393 92.3606798 0.915478696 55.2786405 53.4885899 47.6393202 77 0 100.51141 47.6393202 153.084521 55.2786405 115.042261 92.3606798 124.02282 144.72136"/>
                                                    </g>
                                                </svg>
                                                {{ $t("generic.popular" )}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </splide-slide>
                        </splide>
                    </div>
                </div>
                <hr class="separator"/>
                <template v-if="product">
                <div class="flex flex--100 flex--x-align-center no-categories" v-if="categories.length === 0">
                    <span>This product has no categories.</span>
                </div>
                <div v-else class="flex flex--100 categories-list">
                    <restaurant-category
                        v-for="categoryDescriptor of filteredCategories"
                        :key="categoryDescriptor.category.id"
                        :category="categoryDescriptor.category"
                        :show-editor="false"
                        :selectable="true"
                        :selected="selectedCategory?.category.id === categoryDescriptor.category.id"
                        @update:selected="onCategorySelect(categoryDescriptor)"
                    />
                </div>
                <div v-if="selectedCategory" class="flex flex--100 update-form">
                    <hr class="separator"/>
                    <div class="flex flex--100 update-form-options">
                        <div class="flex flex--100 flex--y-align-center">
                            <simple-textbox v-model:model-value="index" ref="indexTextbox" :placeholder="$t('management.restaurantProductManagementTab.index')"/>
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.indexRule") }}</span>
                        </div>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.visible") }}</span>
                            <simple-checkbox ref="visible" appearance="slider" v-model:model-value="visible"/>
                        </div>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.excludeFromTopSeller") }}</span>
                            <simple-checkbox ref="excludeFromTopSeller" appearance="slider" v-model:model-value="excludeFromTopSeller" />
                        </div>
                    </div>
                    <hr class="separator">
                    <div class="flex flex--100 flex--y-align-end update-form-button">
                        <simple-button :is-loading="isWaitingServerResponseForUpdate" :is-disabled="!dataChanged" :text="$t('generic.update')" @click="update"/>
                    </div>
                </div>
                </template>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import {RestaurantProcessor} from "@/utilities";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import RestaurantCategory from "@/components/RestaurantCategory";
import SimpleButton from "@/components/inputs/SimpleButton";
import {user} from "@/user";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { Grid } from "@splidejs/splide-extension-grid";
import { Splide, SplideSlide, } from "@splidejs/vue-splide";
import CategoryFilter from "@/components/inputs/CategoryFilter.vue";

export default {
    name: "UpdateProductCategoryVisibilityModalBox",
    components: {
        RestaurantCategory,
        SimpleTextbox,
        ModalBox,
        SimpleCheckbox,
        SimpleButton,
        CategoryFilter,
    },
    emits: [ "indexChange", ],
    props: {
        restaurantPresentation: {
            type: Object,
        },
        categories: {
            type: Array,
        }
    },
    data () {
        return {
            product: undefined,
            index: "",
            visible: false,
            excludeFromTopSeller: false,
            selectedCategoryId: -1,
            selectedCategory: null,
            isWaitingServerResponseForUpdate: false,
            Splide,
            SplideSlide,
            splideExtensions: {
                Grid,
            },
            vueProducts: [],
        }
    },
    computed: {
        window () {
            return this.$refs.window;
        },

        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        filteredCategories () {
            const filteredCategories = [];
            if (this.product !== undefined) {
                for (const categoryOption of this.product.categories) {
                    filteredCategories.push({
                        category: this.categories.find((category) => category.id === categoryOption.id),
                        index: String(categoryOption.index),
                        visible: Boolean(categoryOption.visible),
                        excludeFromTopSeller: Boolean(categoryOption.excludeFromTopSeller),
                    });
                }
            }
            return filteredCategories;
        },

        dataChanged () {
            return Number.parseInt(this.index) !== this.selectedCategory.index ||
                !this.visible !== !this.selectedCategory.visible ||
                !this.excludeFromTopSeller !== !this.selectedCategory.excludeFromTopSeller; // Cast Object in Boolean to compare their value
        },

        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: true,
                pagination: true,
                drag: true,
                trimSpace: "move",
                padding: "28px",
                grid: this.splideGridOptions,
                gap: "10px",
            };
        },

        splideGridOptions () {
            // if (this.cashierLayout) {
            //     if (this.restaurantProcessor.restaurant.id == '1') {
            //         return {
            //             dimensions: [ [ 3, 4, ], [ 3, 4, ], ],
            //         };
            //     }

            //     return {
            //         dimensions: [ [ 2, 4, ], [ 2, 4, ], ],
            //     };
            // }

            // TARO GARDEN
            if (this.restaurantProcessor.restaurant.id == '7') {
                return {
                    dimensions: [ [ 1, 5, ], [ 1, 5, ], ],
                };
            }

            if (this.restaurantProcessor.restaurant.id == '1' || this.restaurantProcessor.restaurant.id == '5') {
                return {
                    dimensions: [ [ 2, 3, ], [ 2, 3, ], ],
                };
            }

            return {
                dimensions: [ [ 2, 3, ], [ 2, 3, ], ],
            };
        },

        splidePageItem () {
            // TARO GARDEN
            if (this.restaurantProcessor.restaurant.id == '7') {
                return 5;
            }

            if (this.restaurantProcessor.restaurant.id == '1' || this.restaurantProcessor.restaurant.id == '5') {
                return 6;
            }

            return 6;
        },

        filteredProducts () {
            let products = this.restaurantProcessor.getSortedPopularProductsByCategoryIdHidedInclude(this.selectedCategoryId);
            return products;
        },

        listedCategories () {
            let categories = this.restaurantProcessor.listedCategories;
            return categories;
        },
    },
    methods: {
        getModifiers (id) {
            return {
                "card--selected": this.product?.id === id,
            };
        },

        clear () {
            this.selectedCategory = null;
            this.product = undefined;
            this.selectedCategoryId = -1;
        },

        reset () {
            const selectedCategoryId = this.selectedCategoryId;
            const product = this.product;
            this.clear();
            setTimeout(() => {
                try {
                    this.selectedCategoryId = selectedCategoryId;
                    setTimeout(() => {
                        this.select(this.filteredProducts.find((p) => p.id === product.id), selectedCategoryId);
                        this.goToSelectedProductCard();
                    }, 10);
                } catch {
                    // silence si golden
                }
            }, 10);
        },

        load () {
            this.selectedCategoryId = this.listedCategories[0].id;
            this.select(this.filteredProducts[0]);
        },

        select (product, categoryId = -1) {
            this.product = product;
            if (this.filteredCategories.length > 0) {
                this.selectedCategory = categoryId === -1 ? this.filteredCategories[0] : this.filteredCategories.find((c) => c.category.id === categoryId);
                this.index = String(this.selectedCategory.index);
                this.visible = Boolean(this.selectedCategory.visible);
            }
        },

        onCategorySelect (categoryDescriptor) {
            this.selectedCategory = categoryDescriptor;
            this.index = this.selectedCategory.index;
            this.visible = this.selectedCategory.visible;
            this.excludeFromTopSeller = this.selectedCategory.excludeFromTopSeller;
            const product = this.product;
            setTimeout(() => {
                try {
                    this.selectedCategoryId = categoryDescriptor.category.id;
                    setTimeout(() => {
                        this.select(this.filteredProducts.find((p) => p.id === product.id), categoryDescriptor.category.id);
                        this.goToSelectedProductCard();
                    }, 10);
                } catch {
                    // silence si golden
                }
            }, 10);
        },

        selectCategory (categoryDescriptor) { 
            this.selectedCategory = categoryDescriptor;
            this.index = this.selectedCategory.index;
            this.visible = this.selectedCategory.visible;
            this.excludeFromTopSeller = this.selectedCategory.excludeFromTopSeller; 
        },

        async update () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;

            try {
                const response = await user.updateProductCategoryVisibilityAndIndex({
                    categoryId: this.selectedCategory.category.id,
                    productId: this.product.id,
                    index: Number.parseInt(this.index),
                    visible: this.visible,
                    excludeFromTopSeller: this.excludeFromTopSeller,
                });

                if (response.status === "ok") {
                    this.selectedCategory.index = this.index;
                    this.selectedCategory.visible = this.visible;
                    this.selectedCategory.excludeFromTopSeller = this.excludeFromTopSeller;
                    this.$emit("indexChange", {
                        productId: this.product.id,
                        categoryDescriptor: this.selectedCategory,
                    })
                    this.reset();

                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productUpdatedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponseForUpdate = false;
        },

        goToSelectedProductCard () {
            if (this.product && this.selectedCategory) {
                const products = this.restaurantProcessor.getSortedPopularProductsByCategoryIdHidedInclude(this.selectedCategory.category.id);
                const index = products.findIndex((p) => p.id === this.product.id);
                const itemPerPage = this.splidePageItem;
                this.$refs.productSplide.go(Math.floor(index / itemPerPage));
            }  
        },

        changeProduct (product) {
            this.product = product;
            this.selectCategory(this.filteredCategories.find((c) => c.category.id === this.selectedCategoryId));
        }
    },
    watch: {
        selectedCategoryId() {
            this.select(this.filteredProducts[0]);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.preview-box {
    padding: 0 20px 20px 20px;
}

.preview-title {
    margin: 50px 50px 10px 50px;
}

.categories-list {
    margin: 10px 50px 10px 50px;
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .restaurant-category {
        margin: 24px;
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.input-descriptor-text {
    margin: 10px 20px;

    font-weight: 400;
    font-size: 14px;
}

.update-form {
    padding: 0 50px 50px 50px;
}

.no-categories {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.update-form-button {
}

.update-product-category-visibility-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .update-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}

.preview-mobile {
    padding: 10px 10px 10px 5px;

    &__image {
        height: 88px;
        width: 88px;
        object-fit: contain;
    }

    &__no-image {
        height: 88px;
        width: 88px;
    }
}

h2, .name {
    color: rgb(20, 20, 20);
    font-size: 16px;
    font-weight: 600;
}

.price {
    margin: 8px 0 8px 0px;
    color: rgb(60, 60, 60);
    font-size: 16px;
    font-weight: 500;
}

.popular {
    margin: 8px 0 8px 10px;
    color: rgb(217, 118, 43);
    font-size: 16px;
    font-weight: 500;
    & > svg {
        width: 16px;
        margin: 0 3px 0 0;
        padding: 0;
        fill: rgb(217, 118, 43);
    }
}

.item {
    padding: 10px;
}

.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
    cursor: pointer;

    &--selected {
        border: 2px solid $primary-brand-color;
    }

    &__index {
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

:deep(.category-filter) {
    margin: 10px 30px;
    padding: 20px;
    background-color: rgb(42, 40, 42);
    border-radius: 10px; /* Rounded corners */

    &__button {
        @extend .--unselectable;

        cursor: pointer;

        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(255, 255, 255);
    }
    &__button + &__button {
        margin-left: 20px;
    }
}

.tips {
    margin-left: 10px;
    padding-top: 4px;
}

.visible-icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 20px;

    fill: rgb(0, 0, 0);
}

:deep(.splide__pagination__page) {
    background-color: rgb(0,0,0);
    width: 12px;
    height: 12px;
}
:deep(.splide__pagination__page.is-active) {
    background-color: $primary-brand-color;
}
</style>
