<template>
    <div class="flex flex--100 media-selector">
        <div class="flex flex--100">
            <simple-button :text="selectButtonText" @click="showMediaModalBox"/>
        </div>
        <modal-box ref="modalBox">
            <div class="flex flex--100 media-list">
                <div class="flex media"
                     v-for="media in medias"
                     :key="media.id"
                     :class="getMediaModifiers(media)"
                     :style="getMediaStyle(media)"
                     @click="toggleSelection(media)"
                >
                    <div class="flex flex--x-align-center flex--y-align-center media-overlay">
                        <div class="flex media-selected-box">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="flex media-selected-box__icon">
                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                        </svg>
                        </div>
                    </div>
                </div>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
export default {
    name: "MediaSelector",
    props: {
        medias: {
            type: Array,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    emits: [ "update:selected" ],
    components: {
        SimpleButton,
        ModalBox,
    },
    methods: {
        getMediaModifiers (media) {
            return {
                "media--selected": this.selected.includes(media.id),
            };
        },

        getMediaStyle (media) {
            return {
                "background-image": `url("${media.uri}")`,
            };
        },

        showMediaModalBox () {
            this.$refs.modalBox.show();
        },

        toggleSelection (media) {
            const selectedCopy = JSON.parse(JSON.stringify(this.selected));
            const selectedIndex = this.selected.indexOf(media.id);

            if (selectedIndex !== -1) {
                selectedCopy.splice(selectedIndex, 1);
            }
            else if (this.max === -1 || this.selected.length < this.max) {
                selectedCopy.push(media.id);
            }

            this.$emit("update:selected", selectedCopy);
        },
    },
    computed: {
        selectButtonText () {
            let text;

            if (this.max === 1) {
                text = "Select media";
            }
            else {
                text = "Select medias";
            }

            if (this.selected.length > 0) {
                text = `${text} (${this.selected.length})`;
            }

            return text;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.media-list {}

.media {
    cursor: pointer;

    width: 286px;
    height: 200px;
    margin: 30px;

    background-color: rgb(33, 33, 33);
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 12px;
}

.media-overlay {
    transition: opacity 200ms;

    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);

    opacity: 0;
}
.media--selected .media-overlay {
    opacity: 1;
}

.media-selected-box {
    transition: opacity 200ms;

    padding: 14px;

    border: 4px solid rgb(20, 153, 98);
    border-radius: 50%;

    &__icon {
        width: 40px;
        height: 40px;

        fill: rgb(20, 153, 98);
    }
}
</style>
