<template>
    <div class="flex flex--x-align-center simple-button" :class="getModifiers()" @click="onClick">
        <router-link :to="href" class="simple-button__text" v-if="href">{{ text }}</router-link>
        <span class="simple-button__text" v-else>{{ text }}</span>
        <loading-spinner class="simple-button__loading-spinner" v-if="isLoading"/>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/utilities/LoadingSpinner";

export default {
    name: "SimpleButton",
    components: {
        LoadingSpinner,
    },
    emits: ["click"],
    props: {
        text: {
            type: String,
            default: "",
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            required: false,
        },
    },
    methods: {
        getModifiers () {
            return {
                "simple-button--disabled": this.isDisabled,
                "simple-button--loading": this.isLoading,
            };
        },

        onClick (event) {
            if (!this.isDisabled && !this.isLoading) {
                this.$emit("click", event);
            }
        },
    },
}
</script>

<style lang="scss">
@import "~@/css/globals.scss";

.simple-button {
    @extend .--unselectable;

    $background-color: $primary-color;
    $text-color: rgb(255, 255, 255);

    cursor: pointer;
    position: relative;
    min-width: 174px;
    padding: 14px;
    background-color: $background-color;
    border-radius: 12px;
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    @media (max-width: 448px) {
        min-width: 70px;
    }

    &:active {
        background-color: adjust-color($background-color, $lightness: -3%);
    }

    &__text {
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        color: $text-color;
    }
    &:active &__text {
        color: adjust-color($text-color, $lightness: -2%);
    }

    &--loading {
        cursor: default;
        background-color: adjust-color($background-color, $lightness: -4%);
    }
    &--loading:active {
        background-color: adjust-color($background-color, $lightness: -4%);
    }
    &--loading &__text {
        opacity: 0;
    }
    &--loading &__loading-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.2);
    }

    &--disabled {
        cursor: default;
        background-color: $background-color;
        opacity: 0.44;
    }
    &--disabled:active {
        background-color: $background-color;
    }
    &--disabled:active &__text {
        color: $text-color;
    }
}
</style>
