<template>
    <div class="flex flex--100 add-discount-modal-box">
        <modal-box ref="modalBox">
            <form class="flex flex--100 apply-discount-form">
                <div class="flex flex--100">
                    <simple-textbox placeholder="%" v-model:model-value="percentage" ref="percentageTextbox"/>
                    <simple-button :text="$t('generic.add')" @click="addDiscount"/>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100 flex--x-align-center" v-show="appliedDiscounts.length === 0">
                    <span class="flex no-applied-discounts-text">NO DISCOUNTS APPLIED</span>
                </div>
                <div class="flex flex--100" v-show="appliedDiscounts.length > 0">
                    <div class="flex flex--100 flex--y-align-center applied-discount" v-for="(discount, i) in appliedDiscounts" :key="i">
                        <div class="flex flex--x-align-center flex--y-align-center remove-applied-discount-button" @click="removeDiscount(i)">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex remove-applied-discount-button__icon">
                                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                    <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                                    <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                                </g>
                            </svg>
                        </div>
                        <span class="flex applied-discount__value">{{ discount.percentage }}%</span>
                    </div>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button :text='$t("generic.confirm")' @click="confirm"/>
                    <simple-button :text='$t("generic.cancel")' @click="hide"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";

const OrderDiscountErrorType = {
    MISSING_VALUE: {
        id: 1,
        text: "Percentage is required.",
    },
    INVALID_VALUE: {
        id: 2,
        text: "Percentage must be a number.",
    },
};

export default {
    name: "ApplyDiscountModalBox",
    components: {
        SimpleTextbox,
        SimpleButton,
        ModalBox,
    },
    emits: [ "confirm" ],
    data () {
        return {
            percentage: "",
            appliedDiscounts: [],
        };
    },
    computed: {
        normalizedPercentage () {
            return Math.max(0, Math.min(100, Number.parseFloat(Number.parseFloat(this.percentage).toFixed(2))));
        },

        totalDiscountApplied () {
            return this.appliedDiscounts.reduce((total, discount) => total + discount.percentage, 0);
        },
    },
    methods: {
        validateForm () {
            const percentageTextbox = this.$refs.percentageTextbox;

            percentageTextbox.clearErrors();

            if (percentageTextbox.validate(OrderDiscountErrorType.MISSING_VALUE, this.percentage.length > 0)) {
                percentageTextbox.validate(OrderDiscountErrorType.INVALID_VALUE, Number.isFinite(this.normalizedPercentage));
            }

            return percentageTextbox.isValid;
        },

        show ({ appliedDiscounts = [], }) {
            this.appliedDiscounts = appliedDiscounts;

            this.$refs.modalBox.show();
        },

        hide () {
            this.percentage = "";
            this.appliedDiscounts = [];

            this.$refs.modalBox.hide();
        },

        addDiscount () {
            if (!this.validateForm()) {
                return;
            }

            this.appliedDiscounts.push({ percentage: this.normalizedPercentage, });

            this.percentage = "";
        },

        removeDiscount (i) {
            this.appliedDiscounts.splice(i, 1);
        },

        confirm () {
            this.$emit("confirm", { appliedDiscounts: this.appliedDiscounts, });
            this.hide();
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.apply-discount-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-button {
    & + & {
        margin-left: 16px;
    }
}

.simple-textbox {
    margin-right: 22px;

    width: 60%;
    max-width: 400px;
}

.applied-discount {
    padding: 12px 20px;

    background-color: rgb(246, 246, 246);
    border-radius: 12px;

    &__value {
        font-size: 16px;
        font-weight: 600;

        color: rgb(36, 36, 36);
    }

    & + & {
        margin-top: 12px;
    }
}

.remove-applied-discount-button {
    cursor: pointer;

    width: 22px;
    height: 22px;

    margin-right: 16px;

    background-color: rgb(33, 33, 33);
    border-radius: 50%;

    &__icon {
        width: 12px;

        fill: rgb(255, 255, 255);

        transform: rotate(45deg);
    }
}

.no-applied-discounts-text {
    font-weight: 600;
    font-size: 16px;
    color: rgb(160, 160, 160);
}
</style>
