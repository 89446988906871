<template>
    <div class="flex flex--100 update-product-category-visibility-modal-box">
        <modal-box ref="window" @hide="clear" :close-on-background-click="false" :append-to-body="true">
            <form class="flex flex--100">
                <div class="flex flex--100 update-form">
                    <hr class="separator"/>
                    <div class="flex flex--100 update-form-options">
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t(DiscountStrategies.onePlusOne.name) }}</span>
                            <simple-checkbox ref="onePlusOne" appearance="slider" v-model:model-value="onePlusOne" />
                        </div>
                        <hr class="separator"/>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t(DiscountStrategies.percentage.name) }}</span>
                            <simple-textbox class="input-descriptor-textbox" placeholder="%" v-model:model-value="percentageValue" ref="percentageTextbox"/>
                            <simple-checkbox ref="percentage" appearance="slider" v-model:model-value="percentage" />
                        </div>
                        <hr class="separator"/>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t(DiscountStrategies.fixed.name) }}</span>
                            <simple-textbox class="input-descriptor-textbox" placeholder="0.00" v-model:model-value="fixedValue" ref="fixedTextbox"/>
                            <simple-checkbox ref="fixed" appearance="slider" v-model:model-value="fixed" />
                        </div>
                        <hr class="separator"/>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t("generic.expirationTime") }}</span>
                            <div class="flex flex--direction-column">
                                <v3-date-picker
                                    ref="DatePicker"
                                    class="date-picker-input"
                                    input-format="dd/MM/yyyy"
                                    :locale="calendarLanguage"
                                    v-model:model-value="discountExpirationTimestamp"
                                />
                                <vue-countdown v-show="hasExpiration" :time="discountExpirationTimestamp.getTime() - Date.now()" v-slot="{ days, hours, minutes, seconds }">
                                    {{ $t("generic.timeRemaining") }} {{ days }} {{ $t("generic.days") }} , {{ hours }} {{ $t("generic.hours") }} , {{ minutes }} {{ $t("generic.minutes") }} , {{ seconds }} {{ $t("generic.seconds") }} .
                                </vue-countdown>
                            </div>
                           
                            <simple-checkbox ref="hasExpiration" appearance="slider" v-model:model-value="hasExpiration" />
                        </div>
                    </div>
                    <hr class="separator">
                    <div class="flex flex--100 flex--y-align-end update-form-button">
                        <simple-button :is-loading="isWaitingServerResponseForUpdate" :is-disabled="!dataChanged" :text="$t('generic.update')" @click="update"/>
                    </div>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import {RestaurantProcessor} from "@/utilities";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import { DiscountStrategies } from "@/products/DiscountStrategies";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import {enUS, zhCN, it} from "date-fns/locale";

function getMidnightDate () {
    const date = new Date();

    date.setHours(23,59,59,999);

    return date;
}

export default {
    name: "UpdateProductDiscountStrategiesModalBox",
    components: {
        ModalBox,
        SimpleCheckbox,
        SimpleButton,
        SimpleTextbox,
        V3DatePicker,
    },
    data () {
        return {
            DiscountStrategies,
            product: undefined,
            onePlusOne: false,
            percentage: false,
            fixed: false,
            fixedValue: "",
            percentageValue: "",
            strategies: [],
            isWaitingServerResponseForUpdate: false,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            discountExpirationTimestamp: new Date(getMidnightDate().getTime() + 86400000), //86400000 = 1 day
            hasExpiration: false,
        }
    },
    computed: {
        window () {
            return this.$refs.window;
        },

        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        
        dataChanged () {
            const containsOnePlusOne = this.strategies.includes(DiscountStrategies.onePlusOne.id);
            const fixedDiscount = this.strategies.find((s) => String(s).startsWith("-"));
            const fixed = Boolean(fixedDiscount);
            const fixedValue = fixedDiscount?.substring(1) ?? "";
            const percentageDiscount = this.strategies.find((s) => String(s).startsWith("%"));
            const percentage = Boolean(percentageDiscount);
            const percentageValue = percentageDiscount?.substring(1) ?? "";
            return this.onePlusOne !== containsOnePlusOne || this.fixed !== fixed || this.fixedValue !== fixedValue || this.percentage !== percentage || this.percentageValue !== percentageValue || this.discountExpirationTimestamp !== this.product.discountExpirationTimestamp;
        },

         calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
    },
    methods: {
        clear () {
            this.strategies = [];
            this.onePlusOne = false;
            this.fixedValue = "";
            this.fixed = false;
            this.percentageValue = "";
            this.percentage = false;
            this.hasExpiration = false;
        },

        load (product) {
            this.product = product;
            this.strategies = [];
            if (this.product.discountStrategies && this.product.discountStrategies.length > 0) {
                this.strategies = [ ...this.product.discountStrategies, ];
            }
            this.onePlusOne = this.strategies.includes(DiscountStrategies.onePlusOne.id);
            const fixedDiscount = this.strategies.find((s) => String(s).startsWith("-"));
            this.fixed = Boolean(fixedDiscount);
            if (this.fixed) {
                this.fixedValue = fixedDiscount?.substring(1) ?? "";
            }
            const percentageDiscount = this.strategies.find((s) => String(s).startsWith("%"));
            this.percentage = Boolean(percentageDiscount);
            if (this.percentage) {
                this.percentageValue = percentageDiscount?.substring(1) ?? "";
            }
            const expirationTimestamp = this.product.discountExpirationTimestamp;
            if (expirationTimestamp) {
                this.discountExpirationTImestamp = new Date(expirationTimestamp);
                this.hasExpiration = true;
                setTimeout(() => {
                    this.$refs.DatePicker.set(new Date(expirationTimestamp));
                }, 100);
            }
        },

        async update () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;

            // One plus one
            if (this.onePlusOne) {
                if (this.strategies.findIndex((s) => s === DiscountStrategies.onePlusOne.id) === -1) {
                    this.strategies.push(DiscountStrategies.onePlusOne.id);
                }
            } else {
                this.strategies = this.strategies.filter((s) => s !== DiscountStrategies.onePlusOne.id);
            }
            // Fixed
            this.strategies = this.strategies.filter((s) => !String(s).startsWith("-"));
            if (this.fixed) {
                this.strategies.push(`-${this.fixedValue}`);
            }
            // Percentage
            this.strategies = this.strategies.filter((s) => !String(s).startsWith("%"));
            if (this.percentage) {
                this.strategies.push(`%${this.percentageValue}`);
            }

            const expirationTimestamp = this.hasExpiration ? this.discountExpirationTimestamp.getTime() : null;

            try {
                const response = await user.updateProductDiscountStrategies({
                    productId: this.product.id,
                    discountStrategies: JSON.stringify(this.strategies),
                    discountExpirationTimestamp: expirationTimestamp,
                });

                if (response.status === "ok") {
                    this.product.discountStrategies = [...this.strategies,];
                    this.product.discountExpirationTimestamp = expirationTimestamp;
                    this.window.hide();
                    this.clear();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productUpdatedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponseForUpdate = false;
        }
    },
}
</script>

<style lang="scss" scoped>

.categories-list {
    margin: 50px;
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .restaurant-category {
        margin: 24px;
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.input-descriptor-text {
    margin: 10px 20px;

    font-weight: 400;
    font-size: 14px;
}

.input-descriptor-textbox {
    margin: 10px 20px;

    font-weight: 400;
    font-size: 14px;
}

.update-form {
    padding: 0 50px 50px 50px;
}

.no-categories {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.update-form-button {
}

.update-product-category-visibility-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .update-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}

.date-picker-input {
    margin: 10px 20px;
    width: 200px;
}
</style>
