<template>
    <div class="flex flex--100 restaurant-product-management-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--y-align-center add-product-button" @click="showAddProductWindow">
                    <span class="add-product-button__text">{{$t(`management.restaurantProductManagementTab.newProduct`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex add-product-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
                <div class="flex flex--y-align-center change-product-visibility-button" @click="showUpdateProductCategoryVisibilityWindow">
                    <span class="change-product-visibility-button__text">{{$t(`management.restaurantProductManagementTab.layout`).toLocaleUpperCase()}}</span>
                    <svg class="flex change-product-visibility-button__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 218.208 218.208" xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <path d="M214.31,27.277H3.897C1.743,27.277,0,29.019,0,31.173v27.276v128.586c0,2.154,1.743,3.897,3.897,3.897h27.276h97.414     h27.276h58.448c2.154,0,3.897-1.743,3.897-3.897V58.449V31.173C218.207,29.019,216.464,27.277,214.31,27.277z M27.276,183.139     H7.793V62.345h19.483V183.139z M124.69,183.139H35.069V62.345h89.621V183.139z M151.966,183.139h-19.483V62.345h19.483V183.139z      M210.414,183.139h-50.655V62.345h50.655V183.139z M210.414,54.553h-54.552h-27.276H31.172H7.793V35.069h202.621V54.553z"/>
                                    <path d="M46.759,116.897H113c2.154,0,3.897-1.743,3.897-3.897V74.035c0-2.153-1.743-3.896-3.897-3.896H46.759     c-2.154,0-3.897,1.743-3.897,3.897v38.965C42.862,115.154,44.605,116.897,46.759,116.897z M50.655,77.931h58.448v31.172H50.655     V77.931z"/>
                                    <path d="M46.759,179.243H113c2.154,0,3.897-1.743,3.897-3.897v-38.966c0-2.154-1.743-3.897-3.897-3.897H46.759     c-2.154,0-3.897,1.743-3.897,3.897v38.966C42.862,177.501,44.605,179.243,46.759,179.243z M50.655,140.277h58.448v31.172H50.655     V140.277z"/>
                                    <rect x="46.759" y="120.793" width="66.241" height="7.793"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="flex flex--100 search-bar">
                <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
            </div>
            <div class="flex flex--100 flex--x-align-center action-box">
                <simple-button :text="$t(`management.restaurantProductManagementTab.active`).toLocaleUpperCase()" @click="showActive(true)"/>
                <simple-button :text="$t(`management.restaurantProductManagementTab.archived`).toLocaleUpperCase()" @click="showActive(false)"/>
            </div>
            <div class="flex flex--100 flex--x-align-center product-list">
                <span class="no-products" v-if="products.length === 0">{{$t(`management.restaurantProductManagementTab.noProducts`)}}</span>
                    <restaurant-product
                        v-for="product in displayProducts"
                        :key="product.id"
                        :product="product"
                        :is-selectable="false"
                        :show-analytics="false"
                        :show-editor="true"
                        :categories="categories"
                        :allergens="allergens"
                        :product-tags="productTags"
                        :variations="variations"
                        layout="horizontal"
                        :is-archived="!showActiveProduct"
                        :restaurant-presentation="restaurantPresentation"
                        @remove="onProductRemove"
                        @update="onProductUpdate"
                        @archive="onProductArchive"
                        @unarchive="onProductUnarchive"
                    />
            </div>
            <management-pagination
                class="pagination"
                v-model:model-value.number="page"
                :total-page="totalPage"
                :total-item="totalItem"
            ></management-pagination>
        </div>
        <add-product-modal-box ref="addProductWindow" :restaurant-id="restaurantId" :allergens="allergens" :variations="variations" :categories="categories" @product-add="onProductAdd"/>
        <update-product-category-visibility-modal-box
            ref="UpdateProductCategoryVisibilityModalBox"
            :categories="categories"
            :restaurant-presentation="restaurantPresentation"
            @index-change="onProductIndexChange"
        ></update-product-category-visibility-modal-box>
    </div>
</template>

<script>
import RestaurantProduct from "@/components/RestaurantProduct";
import AddProductModalBox from "@/components/windows/AddProductModalBox";
import ManagementPagination from "@/components/utilities/ManagementPagination";
import { server } from "@/server";
import SimpleButton from "@/components/inputs/SimpleButton";
import Fuse from "fuse.js";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from "@/utilities";
import UpdateProductCategoryVisibilityModalBox from "@/components/windows/UpdateProductCategoryVisibilityModalBox";

export default {
    name: "RestaurantProductManagementTab",
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        SimpleButton,
        AddProductModalBox,
        RestaurantProduct,
        SimpleTextbox,
        ManagementPagination,
        UpdateProductCategoryVisibilityModalBox,
    },
    data () {
        return {
            restaurantId: undefined,
            products: [],
            categories: [],
            archivedProducts: [],
            variations: [],
            selectedProductId: -1,
            allergens: [],
            productTags: [],
            restaurantPresentation: undefined,
            showActiveProduct: true,
            searchBarValue: "",
            page: 0,
            size: 12,
        };
    },
    methods: {
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.archivedProducts = await server.getRestaurantArchivedProducts(restaurantId);
            this.restaurantId = restaurantId;
            this.products = this.restaurantPresentation.products;
            this.categories = this.restaurantPresentation.categories;
            this.variations = this.restaurantPresentation.variations;
            this.allergens = this.restaurantPresentation.allergens;
            this.productTags = this.restaurantPresentation.productTags;
        },

        showAddProductWindow () {
            this.$refs.addProductWindow.window.show();
        },

        showUpdateProductCategoryVisibilityWindow () {
            this.$refs.UpdateProductCategoryVisibilityModalBox.load();
            this.$refs.UpdateProductCategoryVisibilityModalBox.window.show();
        },

        onProductAdd (product) {
            this.products.push(product);
        },

        onProductUpdate (product) {
            const index = this.findProductIndexById(this.products, product.id);

            if (index !== -1) {
                this.products.splice(index, 1);
            }
            this.products.push(product);
        },

        findProductIndexById (products, id) {
            for (let i = 0; i < products.length; ++i) {
                const product = products[i];

                if (product.id === id) {
                    return i;
                }
            }

            return -1;
        },

        async onProductRemove (id) {
            const index = this.findProductIndexById(this.products, id);

            if (index !== -1) {
                this.products.splice(index, 1);
            }
        },

        async onProductArchive (id) {
            const index = this.findProductIndexById(this.products, id);

            if (index !== -1) {
                this.archivedProducts.push(this.products[index]);
                this.products.splice(index, 1);
            }
        },

        async onProductUnarchive (id) {
            const index = this.findProductIndexById(this.archivedProducts, id);

            if (index !== -1) {
                this.products.push(this.archivedProducts[index]);
                this.archivedProducts.splice(index, 1);
            }
        },

        showActive (value) {
            this.page = 0;
            this.showActiveProduct = value;
        },

        onProductIndexChange ({ productId, categoryDescriptor }) {
             const productCategory = this.restaurantProcessor.getProductById(productId)
                        .categories.find((c) => c.id === categoryDescriptor.category.id);
            productCategory.index = Number.parseInt(categoryDescriptor.index);
            productCategory.visible = categoryDescriptor.visible;
            productCategory.excludeFromTopSeller = categoryDescriptor.excludeFromTopSeller;
        },
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // <fuse>
        fuse () {
            const searchItems = this.showActiveProduct ? this.filteredProducts : this.archivedProducts;
            return new Fuse(searchItems, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>

        filteredProducts () {
            return [ ...this.products, ].filter((product) => product.categories.length > 0 && !this.restaurantProcessor.productIsFixedMenu(product.id) &&
        !this.restaurantProcessor.productIsCustomProduct(product.id));
        },

        orderedProducts () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return [ ...this.filteredProducts, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },

        orderedArchivedProducts () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return [ ...this.archivedProducts, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },

        displayProducts () {
            let display = [];
            if (this.showActiveProduct) {
                display = this.orderedProducts;
            }
            else {
                display = this.orderedArchivedProducts;
            }

            return display.slice(this.page * this.size, (this.page + 1) * this.size);
        },

        totalItem () {
            let total = 0;
            if (this.showActiveProduct) {
                total = this.orderedProducts.length;
            }
            else {
                total = this.orderedArchivedProducts.length;
            }

            return total;
        },

        totalPage () {
            return Math.floor(this.totalItem / this.size);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-product-button {
    cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px 0 0 6px;
    border-right: 1px dashed rgb(255,255,255);

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }

    @media (max-width: 448px) {
        margin-bottom: 20px!important;
    }
}

.change-product-visibility-button {
     cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 0 6px 6px 0;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 16px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }

    @media (max-width: 448px) {
        margin-bottom: 20px!important;
    }
}

.product-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.no-products {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.restaurant-product-management-tab {
    height: 100vh;
    .restaurant-product {
        width: 42%;
        margin: 30px;

        @media (max-width: 448px) {
            margin: 10px;
            width: 100%!important;
        }
    }
}

.action-box {
    margin-bottom: 50px;
    .simple-button {
        border-radius: 0;
        border-left: 1px solid rgb(54, 54, 54);
        &__text {
            font-size: 13px;
        }
    }
    .simple-button:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    .simple-button:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    @media (max-width: 448px) {
        margin-bottom: 20px!important;
    }
}

.search-bar {
    position: relative;

    margin: 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }

    @media (max-width: 448px) {
        justify-content: center;
        margin-bottom: 20px;
    }
}

.pagination {
    margin-top: 30px;
}
</style>
