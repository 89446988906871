<template>
    <div class="flex flex--100 customer-distance-modal-box">
        <modal-box ref="modalBox" :show-close-button="false" :close-on-background-click="false">
            <div class="flex flex--100" v-show="verificationState === DistanceVerificationState.NOT_VALID">
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex flex--80 flex--x-align-center title">
                        <h2 class="title__text title__text--not-valid">{{ $t("delivery.addressTooFar") }}</h2>
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex not-valid-icon-box">
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex not-valid-icon-box__icon">
                            <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                                <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <hr class="flex flex--100 separator">
                <div class="flex flex--100 action-box">
                    <simple-button :text="$t('generic.continue')" @click="close"/>
                    <simple-button :text="$t('delivery.checkDifferentAddress')" @click="resetVerificationState"/>
                </div>
            </div>
            <div class="flex flex--100" v-show="verificationState === DistanceVerificationState.NOT_VERIFIED">
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex flex--80 flex--x-align-center title">
                        <h2 class="title__text">{{ $t("delivery.checkAddress") }}</h2>
                    </div>
                </div>
                <div class="flex flex--100 customer-address-box">
                    <div class="flex flex--100 flex--x-align-center">
                        <simple-textbox :placeholder="$t('delivery.address')" class="address-textbox" v-model:model-value="address"/>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <simple-textbox :placeholder="$t('delivery.streetNumber')" class="address-number-textbox" v-model:model-value="houseNumber"/>
                        <simple-textbox :placeholder="$t('delivery.postalCode')" class="cap-textbox" v-model:model-value="cap"/>
                    </div>
                </div>
                <div class="flex flex--100 action-box">
                    <simple-button :text="$t('delivery.check')" :is-loading="isWaitingServerResponseForDistance" @click="requestDistance"/>
                    <simple-button :text="$t('delivery.checkLater')" @click="close"/>
                </div>
            </div>
            <div class="flex flex--100" v-show="verificationState === DistanceVerificationState.VALID">
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex flex--80 flex--x-align-center title">
                        <h2 class="title__text title__text--valid">{{ $t("delivery.addressReachable") }}</h2>
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex valid-icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="flex valid-icon-box__icon">
                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                        </svg>
                    </div>
                </div>
                <hr class="flex flex--100 separator">
                <div class="flex flex--100 action-box">
                    <simple-button :text="$t('generic.continue')" @click="close"/>
                </div>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { GoogleMapsApi } from "@/utilities";

const MAX_DELIVERY_DISTANCE = 3500;

const maps = new GoogleMapsApi({
    key: "AIzaSyATFvT9epocUfonYes_fq8Gn_mtk8ohJ9Q",
    language: "IT",
});

const DistanceVerificationState = Object.freeze({
    NOT_VERIFIED: 0,
    VALID: 1,
    NOT_VALID: 2,
});

export default {
    name: "CustomerDistanceModalBox",
    props: {
        restaurantPresentation: {
            type: Object,
        },
    },
    emits: [ "valid" ],
    data () {
        return {
            DistanceVerificationState,
            verificationState: DistanceVerificationState.NOT_VERIFIED,
            address: "",
            houseNumber: "",
            cap: "",

            isWaitingServerResponseForDistance: false,
        };
    },
    computed: {

    },
    components: {SimpleButton, SimpleTextbox, ModalBox},
    methods: {
        close () {
            this.resetVerificationState();
            this.$refs.modalBox.hide();
        },

        show () {
            this.resetVerificationState();
            this.$refs.modalBox.show();
        },

        resetVerificationState () {
            this.verificationState = DistanceVerificationState.NOT_VERIFIED;
            this.address = "";
            this.houseNumber = "";
            this.cap = "";
        },

        async requestDistance () {
            const address = this.address;
            const houseNumber = this.houseNumber;
            const cap = Number.parseInt(this.cap);

            if (!address || address.length < 3 || !houseNumber || !Number.isFinite(cap)) {
                return;
            }

            this.isWaitingServerResponseForDistance = true;

            const composedAddress = `${cap} ${address} ${houseNumber}`;
            let response;

            try {
                await maps.init();

                response = await maps.getDistance({
                    origin: this.restaurantPresentation.restaurant.address,
                    destination: composedAddress,
                });
            }
            catch {
                this.verificationState = DistanceVerificationState.NOT_VALID;
                this.isWaitingServerResponseForDistance = false;

                return;
            }

            const distance = Number.parseInt(response.travelDistanceValue);

            if (distance <= MAX_DELIVERY_DISTANCE) {
                this.verificationState = DistanceVerificationState.VALID;

                this.$emit("valid", {
                    address,
                    houseNumber,
                    cap: cap.toString(),
                });
            }
            else {
                this.verificationState = DistanceVerificationState.NOT_VALID;
            }

            this.isWaitingServerResponseForDistance = false;
        }
    },
}
</script>

<style lang="scss" scoped>
.separator {
    margin: 0;
    padding: 0;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.customer-address-box {
    padding: 30px 50px 52px 50px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    $max-input-width: 586px;
    $two-input-space: 16px;

    .address-textbox {
        width: 90%;
        max-width: 586px;
    }
    .address-number-textbox {
        width: calc(45% - #{$two-input-space / 2});
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

        margin: 16px $two-input-space 0 0;
    }
    .cap-textbox {
        width: calc(45% - #{$two-input-space / 2});
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2});

        margin: 16px 0 0 0;
    }
}

.title {
    margin: 52px 10px 22px 10px;

    &__text {
        font-size: 38px;
        font-weight: 800;
        text-align: center;
        color: rgb(13, 12, 13);
    }
    &__text--not-valid {
        font-size: 28px;
        color: rgb(227, 70, 70);
    }
    &__text--valid {
        font-size: 28px;
        color: rgb(20, 153, 98);
    }
}

.customer-distance-modal-box {
    :deep(.modal-box__slot) {
        max-width: 698px;
        min-height: initial;
    }
}

.action-box {
    padding: 30px 50px;

    .simple-button + .simple-button {
        margin-left: 22px;
    }
}

.valid-icon-box {
    margin-bottom: 52px;
    padding: 10px;

    border: 4px solid rgb(20, 153, 98);
    border-radius: 50%;

    &__icon {
        width: 32px;
        height: 32px;

        stroke-width: 10px;
        stroke: rgb(20, 153, 98);
        fill: rgb(20, 153, 98);
    }
}

.not-valid-icon-box {
    margin-bottom: 52px;
    padding: 10px;

    border: 4px solid rgb(227, 70, 70);
    border-radius: 50%;

    &__icon {
        width: 32px;
        height: 32px;

        fill: rgb(227, 70, 70);

        transform: rotate(45deg);
    }
}
</style>
