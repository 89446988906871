<template>
    <div class="flex flex--100 login-view">
        <div class="flex flex--100 flex--x-align-center">
            <div class="flex flex--100 login-view__box">
                <form class="flex flex--100 form">
                    <error-list ref="errorList"/>
                    <div class="flex flex--100 flex--direction-column">
                        <simple-textbox :placeholder="$t('generic.username')" v-model:model-value="username" class="username" ref="usernameTextbox"/>
                        <simple-textbox :placeholder="$t('generic.password')" :is-password="true" v-model:model-value="password" class="password" ref="passwordTextbox"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('generic.access')" class="login-button" :is-loading="isWaitingServerResponse" @click="login"/>
                    </div>
                </form>
            </div>
            <product-background-animation style="display: none"/>
        </div>
        <PrimaryFooter/>
    </div>
</template>

<script>
import PrimaryFooter from "@/components/PrimaryFooter";
import ErrorList from "@/components/utilities/ErrorList";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import ProductBackgroundAnimation from "@/components/utilities/ProductBackgroundAnimation";
import { user } from "@/user";
import { server } from "@/server";

const UserLoginErrorType = {
    MISSING_USERNAME: {
        id: 1,
        text: "Username is required.",
    },
    MISSING_PASSWORD: {
        id: 2,
        text: "Password is required.",
    },
    INEXISTENT_USER: {
        id: 3,
        text: "This username doesn't exists.",
    },
    INCORRECT_PASSWORD: {
        id: 4,
        text: "This password is incorrect.",
    },
    BLOCKED_USER: {
        id: 5,
        text: "Your account has been blocked.",
    },
    NETWORK_ERROR: {
        id: 6,
        text: "A Internet error occurred, check your connection.",
    },
};

function clearLoginRedirectStorage () {
    sessionStorage.removeItem("FortuneRMS::LoginRedirect");
}

export default {
    beforeRouteLeave (to, from, next) {
        clearLoginRedirectStorage();
        next();
    },

    name: "LoginView",
    components: {
        PrimaryFooter,
        ProductBackgroundAnimation,
        SimpleButton,
        SimpleTextbox,
        ErrorList,
    },
    data () {
        return {
            username: "",
            password: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateLoginForm () {
            const usernameTextbox = this.$refs.usernameTextbox;
            const passwordTextbox = this.$refs.passwordTextbox;

            usernameTextbox.validate(UserLoginErrorType.MISSING_USERNAME, this.username.length > 0);
            passwordTextbox.validate(UserLoginErrorType.MISSING_PASSWORD, this.password.length > 0);

            return usernameTextbox.isValid && passwordTextbox.isValid;
        },

        async login () {
            this.$refs.errorList.clear();
            this.$refs.usernameTextbox.clearErrors();
            this.$refs.passwordTextbox.clearErrors();

            if (!this.validateLoginForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const loginResponse = await user.login(this.username, this.password);

                this.isWaitingServerResponse = false;

                if (!server.isErrorResponse(loginResponse)) {
                    const redirectLocation = this.loginRedirectLocation;

                    if (redirectLocation) {
                        await this.$router.push(redirectLocation);
                    }
                    else {
                        await this.$router.push("/management");
                    }
                }
                else {
                    switch (loginResponse.result.type) {
                        case "InexistentUserError": {
                            this.$refs.usernameTextbox.addError(UserLoginErrorType.INEXISTENT_USER);

                            break;
                        }
                        case "IncorrectPasswordError": {
                            this.$refs.passwordTextbox.addError(UserLoginErrorType.INCORRECT_PASSWORD);

                            break;
                        }
                    }
                }
            }
            catch (error) {
                this.isWaitingServerResponse = false;

                this.$refs.errorList.addError(UserLoginErrorType.NETWORK_ERROR);
            }
        },
    },
    computed: {
        loginRedirectLocation () {
            return sessionStorage.getItem("FortuneRMS::LoginRedirect");
        },
    },
    created () {
        window.addEventListener("beforeunload", () => clearLoginRedirectStorage());
    },
    unmounted () {
        clearLoginRedirectStorage();
    },
};
</script>

<style lang="scss" scoped>
.login-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;

    &__box {
        max-width: 560px;
    }
}

.form {
    margin: 100px 0 0 0;
    padding: 50px;
    background-color: rgb(254, 254, 254);

    .simple-textbox.username,
    .simple-textbox.password {
        width: 100%;
    }
    .simple-textbox.password {
        margin-top: 20px;
    }

    .simple-button.login-button {
        width: 100%;
        margin-top: 30px;
    }
}
</style>
