export const OrderStatus = Object.freeze({
    TO_PAY: 0,
    IN_PREPARATION: 1,
    IN_DELIVERY: 3,
    DELIVERED: 2,
    CLOSED: 4,
});

export const OrderOrigin = Object.freeze({
    WEBSITE: 0,
    KIOSK: 1,
    CASHIER: 2,
    TABLE_QR: 3,
    THIRD_PARTY: 4,
});

export const OrderPaymentMethod = Object.freeze({
    CASH: 0,
    CARD: 1,
    THIRD_PARTY: 2,
    WECHAT_PAY: 3,
    DELIVEROO: 4,
    GUAGUA: 5,
    GLOVO: 6,
    UBEREATS: 7,
    PHONECALL: 8,
    JUSTEAT: 9,
    OTHER: 10,
    PREBILLING: 11,
});

export const OrderDeliveryLocation = Object.freeze({
    RESTAURANT: 0,
    TAKE_AWAY: 1,
    ADDRESS: 2,
});

export const ThirdPartyOrigin = Object.freeze({
    DELIVEROO: 0,
    GUAGUA: 1,
    GLOVO: 2,
    UBEREATS: 3,
    PHONECALL: 4,
    WECHAT: 5,
    JUSTEAT: 6,
    OTHER: 7
});
