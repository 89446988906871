<template>
  <div class="flex flex--100 restaurant-categories-grid">
    <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
        <primary-logo/>
    </div>
    <div class="flex flex--100 categories-container">
      <div
        class="restaurant-category flex flex--50 flex--direction-column flex--y-align-center"
        v-for="category in listedCategories"
        :key="category.id"
        @click="handleCategoryClick(category.id)"
      >
        <div class="product-image-container">
          <div class="preview" v-if="isFixedMenu(category.id)">
            <img
              v-for="(imageUri, index) in getFixedMenuImages(category.id)"
              :key="index"
              :src="imageUri"
              alt=""
              class="preview__image"
            />
          </div>
          <img
            v-else
            :src="getPopularProductImageUrlByCategoryId(category.id)"
            alt=""
            class="product-image"
          />
        </div>
        <div class="category-name">
          {{ restaurantProcessor.getCategoryLocalizedName(category.id) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RestaurantProcessor } from "@/utilities";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { isKiosk } from "@/main";

export default {
  name: "RestaurantCategoriesGrid",
  props: {
      restaurantPresentation: {
        type: Object,
      },
      selectedScene: {
        type: String,
      },
    },
  components: {
      PrimaryLogo,
  },
  emits: [
      "select",
  ],
  data () {
    return {
      selectedCategoryId: -1,
    };
  },
  computed: {
    restaurantProcessor() {
      return new RestaurantProcessor({
        restaurantPresentation: this.restaurantPresentation,
      });
    },
    selectedSceneObj() {
      let result;
      if (this.selectedScene !== "all") {
        result = this.restaurantProcessor.getSceneById(Number(this.selectedScene));
      }
      return result;
    },
    listedCategories() {
      let categories = this.restaurantProcessor.listedCategories;
      if (this.selectedSceneObj) {
        categories = categories.filter((c) =>
          this.selectedSceneObj.categories.find((sc) => sc.id === c.id)
        );
        const ssc = this.selectedSceneObj.categories;
        categories.sort(
          (c1, c2) =>
            ssc.find((s) => s.id === c1.id).index -
            ssc.find((s) => s.id === c2.id).index
        );
      }
      return categories;
    },
    isKiosk() {
      return isKiosk;
    },
  },
  methods: {
    isFixedMenu(id) {
      return id === this.restaurantProcessor.fixedMenuCategoryId;
    },
    getPopularProductImageUrlByCategoryId(id) {
      const products = this.restaurantProcessor.getSortedPopularProductsByCategoryId(id);
      if (id === 5) {
        return "https://giaogiao.it:3000/images/08F3C090-544D-11EC-9E96-615B28B81C91091-544D-11EC-9E96-615B28B81C91.jpg";
      }
      return products[0]?.previewImage?.uri;
    },
    getFixedMenuImages (id) {
        const fixedMenuId = this.restaurantProcessor.getSortedPopularProductsByCategoryId(id)[0].id;
        const product = this.restaurantProcessor.getFixedMenuById(fixedMenuId);
        return product.choices.map((choice) => choice.products[0].product.previewImage.uri);
    },
    handleCategoryClick(categoryId) {
      this.selectedCategoryId = categoryId;
      this.$emit("select", categoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.restaurant-categories-grid {
  position: relative;
  background-color: rgb(245, 245, 245);
  max-height: 92vh; /* Constrained height */
  overflow-y: auto; /* Enable vertical scrolling */

  .header {
    overflow: hidden;
    height: 25%;
    z-index: 3;
    background-color: $primary-brand-color;
  }

  .primary-logo {
    width: 262px;
    height: auto;

    fill: rgb(255, 255, 255);
}

  .categories-container {
    z-index: 2;
    height: 75%;
    overflow: auto;
  }

  .restaurant-category {
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    touch-action: manipulation;

    &:active {
      opacity: 0.8;
    }

    .product-image-container {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 170px;
        background-color: #fefefe;
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
      

      .product-image {
        margin: 5px;
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 150px;
      }

      .preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__image {
            width: 24%;
            max-height: 250px;
            border-radius: 6px;

            z-index: 2;
        }
        &__image:nth-child(1) {
            position: relative;
            right: -10px;
        }
        &__image:nth-child(2) {
            width: 31%;

            z-index: 3;
        }
        &__image:nth-child(3) {
            position: relative;
            left: -10px;
        }
      }
    }

    .category-name {
        padding: 12px;
        text-align: center;
        color: rgb(20, 20, 20);
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        background-color: #fefefe;
    }
  }
}
</style>
