<template>
    <div class="flex simple-file-upload">
        <div class="flex flex--100 flex--x-align-center selected-files" v-if="selectedFiles.length > 0">
            <div
                v-for="fileDescriptor in selectedFiles"
                :key="fileDescriptor.id"
                class="selected-files__file"
                :class="getSelectedFileModifiers(fileDescriptor)"
                :style="getImageStyle(fileDescriptor)"
            >
                <loading-spinner class="selected-files__loading-spinner"/>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center">
            <label class="file-browser">
                <simple-button :text="$t('generic.openFileBrowser')" class="file-browser__button"/>
                <input type="file" class="file-browser__file-input" :multiple="multipleFiles" ref="fileInput" @change="onFileInputChange">
            </label>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/utilities/LoadingSpinner";
import SimpleButton from "@/components/inputs/SimpleButton";
import { user } from "@/user";
import { server } from "@/server";
import { v1 } from "uuid";

export default {
    name: "SimpleFileUpload",
    props: {
        multipleFiles: {
            type: Boolean,
            default: true,
        },
        duplicateFiles: {
            type: Boolean,
            default: true,
        },
        filesLimit: {
            type: Number,
            default: -1,
        },
        asyncUpload: {
            type: Boolean,
            default: true,
        },
        maxSizeKb: {
            type: Number,
            default: 1024,
        },
    },
    emits: [ "upload" ],
    components: {
        LoadingSpinner,
        SimpleButton,
    },
    data () {
        return {
            selectedFiles: [],
            uploadedFiles: [],
        };
    },
    methods: {
        getSelectedFileModifiers (fileDescriptor) {
            return {
                "selected-files__file--uploaded": this.hasUploadedFile(fileDescriptor),
            };
        },

        addFile (file) {
            if (file.size / 1024 > this.maxSizeKb) {
                alert(this.$t('error.fileSizeLimitExceeded', { size: this.maxSizeKb, }));
                return;
            }
            if (this.filesLimit !== -1 && this.selectedFiles.length + 1 > this.filesLimit) {
                return;
            }

            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();

                fileReader.addEventListener("error", reject);
                fileReader.addEventListener("load", (event) => {
                    const fileDataUrl = event.target.result;

                    if (!this.duplicateFiles && this.hasFile(fileDataUrl)) {
                        return;
                    }

                    const fileDescriptor = {
                        id: v1().toUpperCase(),
                        fileInstance: file,
                        fileDataUrl,
                    };

                    this.selectedFiles.push(fileDescriptor);

                    if (this.asyncUpload) {
                        this.uploadFile(fileDescriptor);
                    }

                    resolve();
                });

                fileReader.readAsDataURL(file);
            });
        },

        hasFile (fileDataUrl) {
            for (const fileInfo of this.selectedFiles) {
                if (fileInfo.fileDataUrl === fileDataUrl) {
                    return true;
                }
            }

            return false;
        },

        hasUploadedFile (fileDescriptor) {
            return this.uploadedFiles.includes(fileDescriptor.id);
        },

        async onFileInputChange (event) {
            const selectedFiles = [ ...event.target.files, ];

            for (const selectedFile of selectedFiles) {
                await this.addFile(selectedFile);
            }
        },

        async uploadFile (file) {
            const response = await user.uploadImage(file.fileInstance);

            if (!server.isErrorResponse(response)) {
                this.uploadedFiles.push(file.id);
                this.$emit("upload", response.result.image.id);
            }
        },

        getImageStyle (fileInfo) {
            return {
                "background-image": `url("${fileInfo.fileDataUrl}")`,
            };
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.simple-file-upload {
    margin: 0;
    padding: 40px 25px;

    background-color: rgb(253, 253, 253);
    border: 2px dashed rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.file-browser {
    @extend .--unselectable;

    &__button {
        width: 90vw;
        max-width: 320px;
    }

    &__file-input {
        display: none;
    }
}

.selected-files {
    &__file {
        position: relative;

        width: 230px;
        height: 230px;
        margin: 0 22px 22px 22px;
        padding: 0;

        background-position: center center;
        background-size: cover;
    }
    &__file::before {
        content: "";

        position: absolute;

        width: inherit;
        height: inherit;

        background-color: rgba(0, 0, 0, 0.7);
    }
    &__file--uploaded::before {
        display: none;
    }

    &__loading-spinner {
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%) scale(0.3);
    }
    &__file--uploaded &__loading-spinner {
        display: none;
    }
}
</style>
