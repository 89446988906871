<template>
    <div class="flex flex--100 restaurant-order-list-tab">
        <div class="flex flex--100 box">
            <h2 class="menu">SUka</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "RestaurantMenusTab",
    data () {
        return {
            restaurantId: -1,
            products: [],
        };
    },
    methods: {
        async openRestaurant () {

        },

        async closeRestaurant () {

        },

        async load (restaurantId) {
            this.restaurantId = restaurantId;
        },

        showAddProductWindow () {
            this.$refs.addProductWindow.window.show();
        },

        onProductAdd (product) {
            this.products.push(product);
        },

        findProductIndexById (id) {
            for (let i = 0; i < this.products.length; ++i) {
                const product = this.products[i];

                if (product.id === id) {
                    return i;
                }
            }

            return -1;
        },

        onProductRemove (id) {
            const index = this.findProductIndexById(id);

            if (index !== -1) {
                this.products.splice(index, 1);
            }
        },
    },
    computed: {
        orderedProducts () {
            return [ ...this.products, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-category-button {
    cursor: pointer;

    margin: 50px 0;
    padding: 10px 20px;

    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(40, 40, 40);
    }
    &:hover &__text {
        color: rgb(78, 199, 26);
    }

    &__icon {
        width: 12px;
        margin-left: 5px;

        fill: rgb(60, 60, 60);
    }
    &:hover &__icon {
        fill: rgb(78, 199, 26);
    }
}

.no-products {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}
</style>

<style lang="scss">
.restaurant-product-management-tab {
    .restaurant-product {
        width: 42%;
        margin: 30px;
    }
}
</style>
