<template>
    <div class="flex flex--x-align-center modal-box" @click="onClick" ref="root" v-if="isActive">
        <div class="flex flex--100 flex--x-align-center flex--y-align-center close-button" @click="hide" v-if="showCloseButton">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex close-button__icon">
                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                    <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                    <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                </g>
            </svg>
        </div>
        <div v-if="showBackButton" class="flex back-button" @click="hide">
            <span class="flex back-button__text">{{ $t("generic.back").toUpperCase() }}</span>
        </div>
        <div class="flex modal-box__slot">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalBox",
    props: {
        showBackButton: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
        closeOnBackgroundClick: {
            type: Boolean,
            default: true,
        },
        appendToBody: {
            type: Boolean,
            default: false,
        },
        manualClose: {
            type: Boolean,
            default: false,
        }
    },
    emits: [ "before-hide", "show", "hide", ],
    data () {
        return {
            isActive: false,
        };
    },
    methods: {
        show () {
            this.isActive = true;
        },

        hide () {
            this.$emit("before-hide");

            if (!this.manualClose) {
                this.isActive = false;
            }
        },

        toggle () {
            this.isActive = !this.isActive;
        },

        onClick (event) {
            if (this.closeOnBackgroundClick && event.target === this.$refs.root) {
                this.hide();
            }
        },
    },
    watch: {
        isActive (value) {
            const body = window.document.body;

            if (body) {
                if (value) {
                    body.style.overflow = "hidden";

                    this.$emit("show");
                }
                else {
                    body.style.overflow = "";

                    this.$emit("hide");
                }
            }
        },
    },
    mounted () {
        if (this.appendToBody) {
            window.document.body.appendChild(this.$el);
        }
    },
};
</script>

<style lang="scss" scoped>
.modal-box {
    overflow: auto;

    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgba(0, 0, 0, 0.3);

    backdrop-filter: blur(30px);

    z-index: 1618033988;

    &__slot {
        overflow: hidden;

        width: 80vw;
        min-height: 80vh;
        min-height: calc(var(--vh, 1vh) * 80);
        margin: 10vh 0;
        margin: calc(var(--vh, 1vh) * 10) 0;

        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
    }

    .close-button {
        cursor: pointer;

        position: absolute;
        right: 10vw;
        top: 5vh;
        top: calc(var(--vh, 1vh) * 5);

        width: 38px;
        height: 38px;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);

        &__icon {
            //transition: fill 300ms;

            width: 18px;

            fill: rgb(60, 60, 60);

            transform: rotate(45deg);
        }
        /*
        &:hover .close-button__icon {
            fill: rgb(227, 70, 70);
        }
        */
    }
}

.back-button {
    cursor: pointer;

    position: absolute;
    left: 50%;
    top: 5%;

    padding: 12px 32px;

    background-color: rgb(255, 255, 255);
    border-radius: 1000px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    transform: translate(-50%, -50%);

    z-index: 99999999999999;

    &__text {
        font-size: 14px;
        font-weight: 600;
    }
}
</style>
