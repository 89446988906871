<template>
    <div class="flex flex--100 cashier-sale-point-view">
        <div class="top-right-div">
            <language-selector/>
            <div class="flex flex--100 flex--x-align-center">
                <simple-selectbox
                    v-show="restaurantId != 6"
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
            </div>
        </div>
        <div class="flex flex--100 main">
            <restaurant-product-list
                ref="restaurantProductList"
                :restaurant-presentation="restaurantPresentation"
                :show-search-bar="true"
                :cashier-layout="true"
                layout="horizontal"
                v-model:selected-products="basket.selectedProducts"
                :selected-scene="selectedScene"
            />
            <customer-basket :restaurant-presentation="restaurantPresentation" :scene="selected" :cover-price="coverPrice" :show-cashier-controls="true" v-model:basket="basket" ref="customerBasket"/>
            <div class="flex flex--100 flex--y-align-center action-box">
                <div class="member-only" v-if="restaurantId === 1">
                    <primary-logo @click="showMemberOnlyModal" class="member-only__logo"/>
                </div>
                <div class="flex flex--50 flex--x-align-center left-action-box">
                    <div class="flex flex--x-align-center flex--y-align-center cash-register-button">
                        <simple-button :text="$t('cashierPos.tables')" @click="showCashRegisterModalBox"/>
                        <svg class="flex cash-register-button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M490 367h-17V150c0-9-7-16-17-16h-83v-34h83c10 0 17-7 17-17V17c0-10-7-17-17-17H256c-9 0-17 7-17 17v66c0 10 8 17 17 17h83v34H56c-10 0-17 7-17 16v217H22c-9 0-16 8-16 17v111c0 10 7 17 16 17h468c9 0 16-7 16-17V384c0-9-7-17-16-17zM273 67V33h167v34H273zM72 167h368v200H72V167zm401 312H39v-78h434v78z"/>
                            <path d="M189 200h-67a17 17 0 000 34h67a17 17 0 000-34z"/>
                            <circle cx="256" cy="250.4" r="16.7"/>
                            <circle cx="322.8" cy="250.4" r="16.7"/>
                            <circle cx="389.6" cy="250.4" r="16.7"/>
                            <circle cx="256" cy="317.2" r="16.7"/>
                            <circle cx="322.8" cy="317.2" r="16.7"/>
                            <circle cx="389.6" cy="317.2" r="16.7"/>
                            <circle cx="256" cy="439.7" r="16.7"/>
                        </svg>
                    </div>
                    <simple-button
                        :text="$t('cashierPos.ordersToPay')"
                        :is-loading="isWaitingServerResponseForOrdersToPay"
                        @click="showOrdersToPay"
                    />
                    <simple-button
                        v-if="!skipPreparationState"
                        :text="$t('cashierPos.ordersInPreparation')"
                        :is-loading="isWaitingServerResponseForOrdersInPreparation"
                        @click="showOrdersInPreparation"
                    />
                    <simple-button
                        v-if="!skipDeliveryState && !skipPreparationState"
                        :text="$t('cashierPos.ordersInDelivery')"
                        :is-loading="isWaitingServerResponseForOrdersInDelivery"
                        @click="showOrdersInDelivery"
                    />
                    <simple-button
                        :text="$t('cashierPos.completedOrders')"
                        :is-loading="isWaitingServerResponseForCompletedOrders"
                        @click="showCompletedOrders"
                    />
                    <simple-button
                        v-if="restaurantHasTables"
                        :text="$t('cashierPos.tables')"
                        :is-loading="isWaitingServerResponseForTables"
                        @click="showTables"
                    />
                </div>
                <div class="flex flex--50 flex--x-align-center">
                    <simple-button :text="clearButtonText" :is-disabled="basketIsEmpty && !selectedTableOrderToAddProduct && !selectedTableForCreateOrder" @click="clearOrder"/>
                    <simple-button
                        v-if="selectedTableOrderToAddProduct"
                        :text="addProductButtonText"
                        :is-disabled="basketIsEmpty"
                        @click="addProduct"
                    />
                    <simple-button v-else :text="continueButtonText" :is-disabled="basketIsEmpty" @click="continueOrder"/>
                </div>
            </div>
        </div>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderToPayListModalBox" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersToPay") }}</h2>
            </div>
            <div class="flex flex--100 order-list order-list--to-pay">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-for="order in ordersToPay" :key="order.id">
                    <restaurant-order
                        :restaurant-presentation="restaurantPresentation"
                        :order="order"
                        :show-cashier-controls="true"
                    />
                    <simple-button class="order-select-button" :text="$t('cashierPos.select')" @click="selectOrderToPay(order)"/>
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box cash-register-modal-box" ref="cashRegisterModalBox" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="Open cash box" @click="openCashRegister"/>
                <simple-button text="Close fiscal day" @click="closeFiscalDay"/>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderInPreparationListModalBox" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersInPreparation") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-for="order in ordersInPreparation" :key="order.id">
                    <restaurant-order
                        :restaurant-presentation="restaurantPresentation"
                        :order="order"
                        :show-cashier-controls="true"
                    />
                    <simple-button
                        v-if="paymentAfterService"
                        class="order-select-button"
                        :is-loading="isWaitingServerResponseForFromInPreparationToToPay"
                        :text="$t('cashierPos.toPay')"
                        @click="selectOrderFromInPreparationToToPay(order)"
                    />
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderInDeliveryListModalBox" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersInDelivery") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-for="order in ordersInDelivery" :key="order.id">
                    <restaurant-order
                        :restaurant-presentation="restaurantPresentation"
                        :order="order"
                        :show-cashier-controls="true"
                    />
                    <simple-button
                        v-if="paymentAfterService"
                        class="order-select-button"
                        :is-loading="isWaitingServerResponseForFromInDeliveryToToPay"
                        :text="$t('cashierPos.toPay')"
                        @click="selectOrderFromInDeliveryToToPay(order)"
                    />
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="completedOrderListModalBox" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.completedOrders") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <restaurant-order
                    v-for="order in completedOrders"
                    :key="order.id"
                    :restaurant-presentation="restaurantPresentation"
                    :order="order"
                    :show-cashier-controls="true"
                />
            </div>
        </modal-box>
        <modal-box
            class="cashier-modal-box member-only-modal-box"
            ref="memberOnlyModalBox"
            :show-close-button="false"
            :show-back-button="true"
            :manual-close="true"
            @before-hide="goBackToFeatures"
            >
            <!-- Features List -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.memberOnly") }}</h2>
            </div>
            <div class="flex flex--100 flex--wrap member-features" v-if="featureSelected === -1">
                <div class="feature-box" @click="selectFeature(0)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path d="M54 10L10 54L32 54L54 32V10Z" fill="#FF9800"/>
                    <circle cx="44" cy="20" r="6" fill="#FFFFFF"/>
                    <text x="20" y="44" font-size="18" fill="#FFFFFF" transform="rotate(-45 20 44)">%</text>
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.promoCode") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(1)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="32" cy="32" r="30" stroke="#4CAF50" stroke-width="4" fill="none"/>
                    <polyline points="20,34 28,42 44,26" fill="none" stroke="#4CAF50" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.checkInfo") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(2)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 4V12C43.0457 12 52 20.9543 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32H4C4 47.464 16.536 60 32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4Z" fill="#2196F3"/>
                    <polygon points="12,32 20,24 20,40" fill="#2196F3"/>
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.updateInfo") }}</span>
                </div>
            </div>

            <!-- Feature Content -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-else>
            <!-- Promo Code Feature -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center promo-code-feature" v-if="featureSelected === 0">
            <div class="flex flex--100 flex--x-align-center promo-code-input-container">
                <simple-textbox
                    :placeholder="$t('memberOnly.promoCode').toUpperCase()"
                    v-model:model-value="promoCode"
                />
                <simple-button
                    class="promo-code-button"
                    :text="$t('memberOnly.check')"
                    @click="checkPromoCode"
                />
                <simple-button
                    class="promo-code-button"
                    :text="$t('memberOnly.use')"
                    @click="usePromoCode"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center promo-code-response">{{ promoCodeReply }}</div>
                
            </div>

            <!-- Check Info Feature -->
            <div v-else-if="featureSelected === 1">
                <!-- Check info content -->
            </div>

            <!-- Update Info Feature -->
            <div v-else-if="featureSelected === 2">
                <!-- Update info content -->
            </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box" ref="orderConfirmationModalBox" @hide="onConfirmationModalBoxHide" :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 full-height">
                <div class="flex order-confirmation-box">
                    <div class="flex flex--100 basket-box">
                        <customer-basket :restaurant-presentation="restaurantPresentation" :show-cashier-controls="true" v-model:basket="basket"/>
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center take-away-box">
                        <div class="flex flex--y-align-center take-away">
                            <span class="take-away__text">{{ $t("generic.takeAway").toUpperCase() }}</span>
                            <simple-checkbox appearance="slider" v-model:model-value="basket.isTakeAway"/>
                        </div>
                        <div class="flex flex--y-align-center cover-number" v-if="coverPrice > 0">
                            <simple-textbox :placeholder="$t('generic.cover').toUpperCase()" v-model:model-value="basket.cover" @input="onCoverInput"/>
                        </div>
                        <!-- <div class="flex flex--y-align-center one-plus-one-button" v-if="includeOnePlusOne">
                            <simple-button :text="$t('discountStrategy.onePlusOne').toUpperCase()" @click="applyOnePlusOne"/>
                        </div> -->
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center action-box payment-method-selection-box">
                        <simple-button
                            class="payment-method-button"
                            :text="$t('generic.cash')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.CASH)"
                            @click="selectCashPaymentMethod"
                        />
                        <simple-button
                            class="payment-method-button"
                            :text="$t('generic.card')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.CARD)"
                            @click="selectCardPaymentMethod"
                        />
                        <simple-button
                            class="payment-method-button"
                            :text="$t('generic.thirdParty')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.THIRD_PARTY)"
                            @click="selectThirdPartyPaymentMethod"
                        />
                        <simple-button
                            class="payment-method-button"
                            :text="$t('generic.prebilling')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.PREBILLING)"
                            @click="selectPrebillingPaymentMethod"
                        />
                    </div>
                </div>
                <div class="flex order-payment-box">
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center title" v-show="orderPaymentMethod === null">
                        <h2 class="title__text">{{ $t("generic.selectPaymentMethod") }}</h2>
                    </div>
                    <div class="flex flex--100 cash-payment-box" v-show="orderPaymentMethod === OrderPaymentMethod.CASH">
                        <cashier-payment-calculator v-model:model-value="cashAmountPaid"/>
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-button :text="confirmButtonText" :is-disabled="basketIsEmpty" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder"/>
                        </div>
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center card-payment-box" v-show="orderPaymentMethod === OrderPaymentMethod.CARD">
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-button :text="confirmButtonText" :is-disabled="basketIsEmpty" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder"/>
                        </div>
                    </div>
                    <div class="flex flex--100 third-party-payment-box" v-show="orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY">
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-selectbox
                                class="third-party-select-box"
                                :select-placeholder="$t('management.generic.origin')"
                                :options="thirdPartyPaymentOptions"
                                :can-search-options="false"
                                :can-select-empty-value="false"
                                v-model:model-value="selectedThirdPartyOrigin"
                            />
                        </div>
                        <div class="flex flex--100 flex--x-align-center third-party-payment-textbox">
                            <simple-textbox
                                class="flex flex--80"
                                ref="thirdPartyTotalPriceTextbox"
                                v-model:model-value="thirdPartyOriginTotal"
                                :placeholder="$t('generic.thirdPartyTotal')"
                            ></simple-textbox>
                        </div>
                        <div class="flex flex--100 flex--x-align-center third-party-payment-box">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-button class="third-party-payment-button" :text="$t('generic.cash')" :is-disabled="thirdPartyButtonIsDisabled" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="validateThirdPartyOriginTotal(OrderPaymentMethod.CASH)"/>
                            <simple-button class="third-party-payment-button" :text="$t('generic.card')" :is-disabled="thirdPartyButtonIsDisabled" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="validateThirdPartyOriginTotal(OrderPaymentMethod.CARD)"/>
                        </div>
                    </div>
                     <div class="flex flex--100 flex--x-align-center flex--y-align-center card-payment-box" v-show="orderPaymentMethod === OrderPaymentMethod.PREBILLING">
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmWithoutPayment") }}
                            </p>
                            <simple-button :text="confirmButtonText" :is-disabled="basketIsEmpty" :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center order-completed-box" v-if="confirmedOrder" @click="hideConfirmationModalBox">
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex order-completed-icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve" class="flex order-completed-icon-box__icon">
                            <path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z"/>
                        </svg>
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="order-completed-box__daily-id-text">{{ $t("generic.order") }} #{{ confirmedOrder.dailyId }}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center" v-if="customerHasRest">
                    <span class="order-completed-box__customer-rest-text">{{ $t("generic.rest") }} € {{ normalizePriceToDisplay(customerRest) }}</span>
                </div>
            </div>
        </modal-box>
        <scene-map-table-management
            ref="sceneMapTableManagement"
            :restaurant-presentation="restaurantPresentation"
            :scene="selected"
            :scenes="scenes"
            :tables="tables"
            @to-pay="tableOrderToPay"
            @table-update="updateTables"
            @table-add-product="tableAddProduct"
            @table-select="updateBasketTable"
        />
    </div>
</template>

<script>
import CashierPaymentCalculator from "@/components/CashierPaymentCalculator";
import ModalBox from "@/components/containers/ModalBox";
import CustomerBasket from "@/components/CustomerBasket";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import RestaurantOrder from "@/components/RestaurantOrder";
import RestaurantProductList from "@/components/RestaurantProductList";
import {
    OrderStatus,
    OrderDeliveryLocation,
    OrderOrigin,
    OrderPaymentMethod,
    ThirdPartyOrigin,
} from "@/orders/RestaurantOrder";
import { server, WS_API_URI, } from "@/server";
import { cloneDeep, } from "lodash";
import {RestaurantProcessor, BasketProcessor, WSKeepAlive,} from "@/utilities";
import { isDesktop, i18n, } from "@/main";
import SceneMapTableManagement from "@/components/SceneMapTableManagement";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
// import { DiscountStrategies } from "@/products/DiscountStrategies";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";

const preloadedData = {};
const orderInPreparationAudio = new Audio(require("@/audio/order-in-preparation.mp3"));
let primaryWs;
let selectedScene;

function createPrimaryWs () {
    primaryWs?.close();

    try {
        primaryWs = new WSKeepAlive(WS_API_URI);
    }
    catch {
        // Silence is golden.
    }
}

export default {
    async beforeRouteEnter (to, from, next) {
        selectedScene = !to.query.sceneId || to.query.sceneId === "undefined" || to.query.sceneId === "all" ? undefined : to.query.sceneId;
        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId);

        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }

        next();
    },

    name: "CashierSalePointView",
    components: {
        SimpleTextbox,
        SceneMapTableManagement,
        LanguageSelector,
        SimpleCheckbox,
        SimpleSelectbox,
        CashierPaymentCalculator,
        RestaurantOrder,
        ModalBox,
        SimpleButton,
        CustomerBasket,
        RestaurantProductList,
        PrimaryLogo,
    },
    data () {
        return {
            OrderPaymentMethod,
            basket: BasketProcessor.createEmpty(),

            ordersToPay: [],
            ordersInPreparation: [],
            ordersInDelivery: [],
            completedOrders: [],
            tables: [],
            selectedThirdPartyOrigin: null,
            thirdPartyOriginTotal: "",

            isWaitingServerResponseForOrderCreation: false,
            isWaitingServerResponseToToPay: false,
            isWaitingServerResponseForTables: false,
            isWaitingServerResponseForFromInPreparationToToPay: false,
            isWaitingServerResponseForFromInDeliveryToToPay: false,
            isWaitingServerResponseForOrdersToPay: false,
            isWaitingServerResponseForOrdersInPreparation: false,
            isWaitingServerResponseForOrdersInDelivery: false,
            isWaitingServerResponseForCompletedOrders: false,
            isWaitingServerResponseForOrderConfirmation: false,

            selectedOrderToPay: null,

            selectedTableForCreateOrder: null,

            selectedTableOrderToAddProduct: null,

            ordersHandledByCashier: {},

            orderOrigin: null,
            orderPaymentMethod: null,

            cashAmountPaid: null,

            confirmedOrder: null,
            customerRest: null,

            thirdPartyPaymentOptions: [{
                text: "Deliveroo",
                value: ThirdPartyOrigin.DELIVEROO,
            }, {
                text: "GuaGua",
                value: ThirdPartyOrigin.GUAGUA,
            }, {
                text: "Glovo",
                value: ThirdPartyOrigin.GLOVO,
            }, {
                text: "Uber Eats",
                value: ThirdPartyOrigin.UBEREATS,
            }, {
                text: "Phone Call",
                value: ThirdPartyOrigin.PHONECALL,
            }, {
                text: "WeChat",
                value: ThirdPartyOrigin.WECHAT,
            }, {
                text: "Just Eat",
                value: ThirdPartyOrigin.JUSTEAT,
            }, {
                text: "Other",
                value: ThirdPartyOrigin.OTHER,
            }],

            thirdPartyPaymentMethodMap: new Map([
                [ThirdPartyOrigin.JUSTEAT, OrderPaymentMethod.JUSTEAT],
                [ThirdPartyOrigin.GLOVO, OrderPaymentMethod.GLOVO],
                [ThirdPartyOrigin.DELIVEROO, OrderPaymentMethod.DELIVEROO],
                [ThirdPartyOrigin.PHONECALL, OrderPaymentMethod.PHONECALL],
                [ThirdPartyOrigin.WECHAT, OrderPaymentMethod.WECHAT_PAY],
                [ThirdPartyOrigin.GUAGUA, OrderPaymentMethod.GUAGUA],
                [ThirdPartyOrigin.OTHER, OrderPaymentMethod.OTHER],
                [ThirdPartyOrigin.UBEREATS, OrderPaymentMethod.UBEREATS]
            ]),

            selectedScene,
            featureSelected: -1,
            promoCodeReply: "",
            promoCode: "",
        };
    },
    methods: {
        clearOrder () {
            this.selectedThirdPartyOrigin = null;
            this.thirdPartyOriginTotal = "";
            this.selectedTableForCreateOrder = null;
            this.selectedOrderToPay = null;
            this.selectedTableOrderToAddProduct = null;
            this.basket = BasketProcessor.createEmpty();
        },

        hideConfirmationModalBox () {
            this.$refs.orderConfirmationModalBox.hide();
        },

        onConfirmationModalBoxHide () {
            this.orderPaymentMethod = null;
            this.cashAmountPaid = null;
            this.customerRest = null;
            this.confirmedOrder = null;
        },

        async selectOrderFromInPreparationToToPay (order) {
            if (this.isWaitingServerResponseForFromInPreparationToToPay) {
                return;
            }

            this.isWaitingServerResponseForFromInPreparationToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: order.id,
            });

            this.isWaitingServerResponseForFromInPreparationToToPay = false;


            this.selectOrderToPay(updatedOrder);
        },

        async orderFromInPreparationToToPay (order) {
            const orderProducts = order.products;

            for (const orderProduct of orderProducts) {
                if (orderProduct.requestedMenus) {
                    for (const orderFixedMenu of orderProduct.requestedMenus) {
                        for (const orderFixedMenuProduct of orderFixedMenu.products) {
                            await server.updateOrderProduct({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                completedVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });

                            await server.updateOrderProductDeliveredVolume({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                deliveredVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });
                        }
                    }
                }
                else {
                    await server.updateOrderProduct({
                        orderId: order.id,
                        productId: orderProduct.id,
                        completedVolume: orderProduct.requestedVolume,
                    });

                    await server.updateOrderProductDeliveredVolume({
                        orderId: order.id,
                        productId: orderProduct.id,
                        deliveredVolume: orderProduct.requestedVolume,
                    });
                }
            }

            if (!this.skipDeliveryState) {
                await server.updateOrder({
                    id: order.id,
                    status: OrderStatus.IN_DELIVERY,
                });
            }

             return server.updateOrder({
                id: order.id,
                status: OrderStatus.TO_PAY,
            });
        },

        async selectOrderFromInDeliveryToToPay (order) {

            if (this.isWaitingServerResponseForFromInDeliveryToToPay) {
                return;
            }

            this.isWaitingServerResponseForFromInDeliveryToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: order.id,
            });

            this.isWaitingServerResponseForFromInDeliveryToToPay = false;

            this.selectOrderToPay(updatedOrder);
        },

        async orderFromInDeliveryToToPay (order) {
            const orderProducts = order.products;

            for (const orderProduct of orderProducts) {
                if (orderProduct.requestedMenus) {
                    for (const orderFixedMenu of orderProduct.requestedMenus) {
                        for (const orderFixedMenuProduct of orderFixedMenu.products) {
                            await server.updateOrderProductDeliveredVolume({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                deliveredVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });
                        }
                    }
                }
                else {
                    await server.updateOrderProductDeliveredVolume({
                        orderId: order.id,
                        productId: orderProduct.id,
                        deliveredVolume: orderProduct.requestedVolume,
                    });
                }
            }

            return server.updateOrder({
                id: order.id,
                status: OrderStatus.TO_PAY,
            });
        },

        selectOrderToPay (order) {
            this.clearOrder();

            order.products.forEach((product) => {
                const productId = product.id;
                const productVariations = product.variations;
                const productMessage = product.message;
                const productSelectionDescriptor = {
                    selectedVolume: product.requestedVolume,
                };

                if (this.restaurantProcessor.productIsFixedMenu(productId)) {
                    productSelectionDescriptor.selectedProducts = product.requestedMenus.map((menu) => menu.products.map((menuProduct) => {
                        return {
                            [menuProduct.id]: { selectedVolume: 1, },
                        };
                    }));
                }

                if (this.restaurantProcessor.productIsCustomProduct(productId)) {
                    productSelectionDescriptor.selectedProducts = product.requestedMenus.map((menu) => menu.products.map((menuProduct) => {
                        return {
                            [menuProduct.id]: { selectedVolume: 1, price: this.restaurantProcessor.getProductById(menuProduct.id).price, },
                        };
                    }));
                }

                this.basket.selectedProducts[productId] = productSelectionDescriptor;

                if (productVariations && productVariations.length > 0) {
                    const normalizedProductVariations = {};

                    for (let i = 0; i < product.requestedVolume; ++i) {
                        normalizedProductVariations[i] = productVariations.filter(
                            (productVariation) => productVariation.volumeIndex === i
                        ).map(
                            (productVariation) => productVariation.id
                        );
                    }

                    this.basket.selectedProductsVariations[productId] = normalizedProductVariations;
                }

                if (productMessage) {
                    this.basket.selectedProductsMessages[productId] = productMessage;
                }
            });

            this.basket.isTakeAway = order.deliveryLocation === OrderDeliveryLocation.TAKE_AWAY;
            this.selectedOrderToPay = order;

            if (order.requestedPersons !== null && order.requestedPersons !== undefined && Number(order.requestedPersons) > 0) {
                this.basket.cover = String(order.requestedPersons);
                let additions = this.basketProcessor.appliedAdditions;
                if (this.basket.cover.length === 0) {
                    additions = additions.filter((a) => a.description !== "Coperto");
                }
                if (this.basket.cover.length > 0) {
                    const coverAddition = additions.find((a) => a.description === "Coperto");
                    if (coverAddition) {
                        coverAddition.value = order.requestedPersons * this.coverPrice;
                    }
                    else {
                        additions.push({
                            description: "Coperto",
                            value: order.requestedPersons * this.coverPrice,
                        });
                    }
                }
            }

            // if (order.deductions.length > 0) { // If add other promotion type change this!!!
            //     this.applyOnePlusOne();
            // }

            this.$refs.orderToPayListModalBox.hide();
            this.$refs.orderInPreparationListModalBox.hide();
            this.$refs.orderInDeliveryListModalBox.hide();
        },

        getPaymentMethodButtonModifiers (method) {
            return {
                "payment-method-button--selected": this.orderPaymentMethod === method,
            };
        },

        async updateOrdersToPay () {
            if (this.isWaitingServerResponseForOrdersToPay) {
                return;
            }

            this.isWaitingServerResponseForOrdersToPay = true;
            this.ordersToPay = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.TO_PAY, this.selectedScene);
            this.isWaitingServerResponseForOrdersToPay = false;
        },

        async updateOrdersInPreparation () {
            if (this.isWaitingServerResponseForOrdersInPreparation) {
                return;
            }

            this.isWaitingServerResponseForOrdersInPreparation = true;
            this.ordersInPreparation = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_PREPARATION, this.selectedScene);
            this.isWaitingServerResponseForOrdersInPreparation = false;
        },

        async updateOrdersInDelivery () {
            if (this.isWaitingServerResponseForOrdersInDelivery) {
                return;
            }

            this.isWaitingServerResponseForOrdersInDelivery = true;
            this.ordersInDelivery = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_DELIVERY, this.selectedScene);
            this.isWaitingServerResponseForOrdersInDelivery = false;
        },

        async updateCompletedOrders () {
            if (this.isWaitingServerResponseForCompletedOrders) {
                return;
            }

            this.isWaitingServerResponseForCompletedOrders = true;
            this.completedOrders = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.CLOSED, this.selectedScene);
            this.isWaitingServerResponseForCompletedOrders = false;
        },

        async updateTables () {
            if (this.isWaitingServerResponseForTables) {
                return;
            }

            this.isWaitingServerResponseForTables = true;
            this.tables = await server.getRestaurantTables({ restaurantId: this.restaurantId, });
            this.isWaitingServerResponseForTables = false;
        },

        async showOrdersToPay () {
            await this.updateOrdersToPay();
            this.ordersToPay.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderToPayListModalBox.show();
        },

        async showOrdersInPreparation () {
            await this.updateOrdersInPreparation();
            this.ordersInPreparation.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderInPreparationListModalBox.show();
        },

        async showOrdersInDelivery () {
            await this.updateOrdersInDelivery();
            this.ordersInDelivery.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderInDeliveryListModalBox.show();
        },

        async showCompletedOrders () {
            await this.updateCompletedOrders();
            this.completedOrders.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.completedOrderListModalBox.show();
        },

        async showTables () {
            await this.updateTables();
            this.$refs.sceneMapTableManagement.load();
            this.$refs.sceneMapTableManagement.show();
        },

        showMemberOnlyModal () {
            this.$refs.memberOnlyModalBox.show();
        },

        showCashRegisterModalBox () {
            this.$refs.cashRegisterModalBox.show();
        },

        playNewOrderNotificationSound () {
            orderInPreparationAudio.play();
        },

        showOrderConfirmationModalBox () {
            this.$refs.orderConfirmationModalBox.show();
        },

        selectCashPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.CASH;
        },

        selectCardPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.CARD;
        },

        selectThirdPartyPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.THIRD_PARTY;
        },

        selectPrebillingPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.PREBILLING;
        },

        async createOrderToPay ({ products, fixedMenus, customProducts, }) {
            const origin = this.orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY ? OrderOrigin.THIRD_PARTY : OrderOrigin.CASHIER;
            const deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;
            const tables = [];
            if (this.selectedTableForCreateOrder) {
                tables.push(this.selectedTableForCreateOrder.id);
            }
            return server.sendOrder({
                sceneId: this.selectedScene,
                origin,
                deliveryLocation,
                products,
                fixedMenus,
                customProducts,
                tables,
                thirdPartyOrigin: this.selectedThirdPartyOrigin,
                totalPrice: this.thirdPartyOriginTotal,
                discounts: this.basket.discounts,
                additions: this.basket.additions,
                deductions: this.basket.deductions,
                productsVariations: this.basket.selectedProductsVariations,
                productsMessages: this.basket.selectedProductsMessages,
            });
        },

        async createOrderPaymentAfterService () {
            if (this.isWaitingServerResponseForOrderCreation) {
                return;
            }

            this.isWaitingServerResponseForOrderCreation = true;
            const products = [];
            const fixedMenus = [];
            const customProducts = [];

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            this.basketSelectedCustomProducts.forEach((customProduct) => {
                this.basket.selectedProducts[customProduct.id].selectedProducts.forEach((products) => {
                    let productIds = [];
                    const currentCustomProduct = [ customProduct.id, ];

                    productIds = products.map((product) => Object.keys(product));
                    productIds = productIds.map((ids) => {
                        let intIds = [];
                        ids.forEach((id) => {
                            intIds = [ ...intIds, Number.parseInt(id)];
                        })
                        return intIds;
                    });

                    productIds.forEach((ids) => {
                        ids.forEach((id) => {
                            currentCustomProduct.push(id);
                        })
                    });
                    customProducts.push(currentCustomProduct);
                });
            });

            await this.createOrderToPay({ products, fixedMenus, customProducts, });

            this.isWaitingServerResponseForOrderCreation = false;
            this.clearOrder();
        },

        async confirmOrder (thirdPartyPaymentMethod = null) {
            if (this.orderPaymentMethod === OrderPaymentMethod.CASH) {
                if (this.cashAmountPaid < this.basketTotal) {
                    return;
                }
            }

            if (this.isWaitingServerResponseForOrderConfirmation) {
                return;
            }

            this.isWaitingServerResponseForOrderConfirmation = true;

            const products = [];
            const fixedMenus = [];
            const customProducts = [];
            const deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            this.basketSelectedCustomProducts.forEach((customProduct) => {
                this.basket.selectedProducts[customProduct.id].selectedProducts.forEach((products) => {
                    let productIds = [];
                    const currentCustomProduct = [ customProduct.id, ];

                    productIds = products.map((product) => Object.keys(product));
                    productIds = productIds.map((ids) => {
                        let intIds = [];
                        ids.forEach((id) => {
                            intIds = [ ...intIds, Number.parseInt(id)];
                        })
                        return intIds;
                    });

                    productIds.forEach((ids) => {
                        ids.forEach((id) => {
                            currentCustomProduct.push(id);
                        })
                    });
                    customProducts.push(currentCustomProduct);
                });
            });

            let order = this.selectedOrderToPay;

            if (!order) {
                order = await this.createOrderToPay({ products, fixedMenus, customProducts, });
            }

            this.ordersHandledByCashier[order.id] = true;

            let nextStatus = OrderStatus.IN_PREPARATION;

            if (this.selectedOrderToPay && this.paymentAfterService || this.skipPreparationState) {
                nextStatus = OrderStatus.CLOSED;
            }
            if (!this.selectedOrderToPay && this.skipPreparationState && this.paymentAfterService) {
                nextStatus = OrderStatus.TO_PAY;
            }

            let paymentMethod = this.orderPaymentMethod;

            if (this.orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY) {
                paymentMethod = this.thirdPartyPaymentMethodMap.get(Number.parseInt(this.selectedThirdPartyOrigin));
            }

            const updateDescriptor = {
                id: order.id,
                paidThroughCashier: 1,
                status: nextStatus,
                paymentMethod,
            };

            if (this.selectedOrderToPay) {
                updateDescriptor.products = products;
                updateDescriptor.fixedMenus = fixedMenus;
                updateDescriptor.customProducts = customProducts;
                updateDescriptor.discounts = this.basket.discounts;
                updateDescriptor.additions = this.basket.additions;
                updateDescriptor.deductions = this.basket.deductions;
                updateDescriptor.deliveryLocation = deliveryLocation;
                updateDescriptor.productsVariations = this.basket.selectedProductsVariations;
                updateDescriptor.productsMessages = this.basket.selectedProductsMessages;
            }

            order = await server.updateOrder(updateDescriptor);
            order.customProducts?.forEach((customProduct) => {
                const product = order.products.find((productToFind) => productToFind.id === customProduct.customProductId);
                if (product) {
                    let totalPrice = 0;
                    product.requestedMenus.forEach((menu) => {
                        menu.products.forEach((menuProduct) => {
                            totalPrice += this.restaurantProcessor.getProductById(menuProduct.id).price;
                        });
                    });
                    product.unitPrice = totalPrice;
                }
            });

            // Add cash amount
            order.cashAmountPaid = this.cashAmountPaid;
            // Add third party payment method
            order.thirdPartyPaymentMethod = thirdPartyPaymentMethod;

            if (this.orderPaymentMethod === OrderPaymentMethod.CASH) {
                this.customerRest = this.cashAmountPaid - this.basketTotal;
            }

            this.confirmedOrder = order;
            this.isWaitingServerResponseForOrderConfirmation = false;

            this.clearOrder();
            this.printReceipt(order);
        },

        printReceipt (order, onlyReminder = false) {
            // <receipt>
            if (isDesktop && order) {
                try {
                    const { ipcRenderer, } = window.require("electron");

                    /**
                     * Link price and localizations to order products to keep compatibility with FortuneRMS Desktop (avoids breaking printers).
                     */
                    order.products.forEach((orderProduct) => {
                        const product = this.restaurantProcessor.getProductById(orderProduct.id);

                        orderProduct.price = product.price !== 0 ? product.price : orderProduct.unitPrice;
                        orderProduct.localizations = product.localizations;
                        orderProduct.variationsDetails = this.restaurantProcessor.getProductVariations(orderProduct.id);
                        orderProduct.valueAddedTax = product.valueAddedTax;
                        orderProduct.category = product.categories[0].id;
                        if (!product.discountExpirationTimestamp || product.discountExpirationTimestamp > Date.now()) {
                            orderProduct.discountStrategies = product.discountStrategies;
                        }
                        this.bindFixedMenuProductLocalizations(order); // Link fixed menu products localizations for receipt
                        this.bindCustomProductProductLocalizations(order); // Link custom dish products localizations for receipt
                    });

                    if (onlyReminder) {
                        ipcRenderer.send("printOrder", JSON.stringify(order));
                    }
                    else {
                        ipcRenderer.send("confirmOrder", JSON.stringify(order));
                    }
                }
                catch {
                    // Silence is golden.
                }
            }
            // </receipt>
        },

        bindFixedMenuProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsFixedMenu(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((fixedMenuProduct) => {
                            fixedMenuProduct.localizations = this.restaurantProcessor.getProductById(fixedMenuProduct.id).localizations;
                        });
                    }
                }
            });
        },

        bindCustomProductProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsCustomProduct(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((customProductProduct) => {
                            customProductProduct.localizations = this.restaurantProcessor.getProductById(customProductProduct.id).localizations;
                        });
                    }
                }
            });
        },

        openCashRegister () {
            try {
                const { ipcRenderer, } = window.require("electron");

                ipcRenderer.send("openCashRegister");
            }
            catch {
                // Silence is golden.
            }

            this.$refs.cashRegisterModalBox.hide();
        },

        closeFiscalDay () {
            try {
                const { ipcRenderer, } = window.require("electron");

                ipcRenderer.send("closeFiscalDay");
            }
            catch {
                // Silence is golden.
            }

            this.$refs.cashRegisterModalBox.hide();
        },

        async tableOrderToPay (orderId) {
            if (this.isWaitingServerResponseToToPay) {
                return;
            }

            this.isWaitingServerResponseToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: orderId,
            });

            this.selectOrderToPay(updatedOrder);

            this.isWaitingServerResponseToToPay = false;
            this.$refs.sceneMapTableManagement.hide();
        },

        async tableAddProduct (tableId, orderId) {
            this.selectedTableOrderToAddProduct = await server.getOrder({id: orderId});
            this.$refs.sceneMapTableManagement.hide();
        },

        async addProduct () {
            const products = [];
            const fixedMenus = [];

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            const order = await server.addOrderProduct({
                id: this.selectedTableOrderToAddProduct.id,
                fixedMenus,
                products,
            });

            if (order) {
                this.playNewOrderNotificationSound();
                this.clearOrder();
            }
        },

        async updateBasketTable (table) {
            this.selectedTableForCreateOrder = table;
            this.$refs.sceneMapTableManagement.hide();
        },

        async continueOrder () {
            // if (this.paymentAfterService && this.selectedTableForCreateOrder) {
            if (this.paymentAfterService && !this.selectedOrderToPay) {
                await this.createOrderPaymentAfterService();
            }
            else {
                this.showOrderConfirmationModalBox();
            }
        },

        async validateThirdPartyOriginTotal (paymentMethod) {
            const regex = /^(?:\d*\.\d{1,2}|\d+)$/;
            const thirdPartyTotalPriceTextbox = this.$refs.thirdPartyTotalPriceTextbox;

            thirdPartyTotalPriceTextbox.clearErrors();

            thirdPartyTotalPriceTextbox.validate({
                id: 1,
                text: "Invalid number format",
            }, regex.test(this.thirdPartyOriginTotal));

            if (thirdPartyTotalPriceTextbox.isValid) {
                await this.confirmOrder(paymentMethod);
            }
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },

        onCoverInput (value) {
            this.$refs.customerBasket.updateAddition(value);
        },

        selectFeature(featureIndex) {
            this.featureSelected = featureIndex;
        },

        goBackToFeatures() {
            if (this.featureSelected !== -1) {
                this.featureSelected = -1; // Return to features list
            } else {
                // Close the modal or perform another action
                this.$refs.memberOnlyModalBox.toggle();
            }
        },

        async checkPromoCode () {
            try {
                const response = await server.checkPromoCode({ code: this.promoCode, });
                this.promoCodeReply = this.formatPromoCodeResponse(response);
            }
            catch (e) {
                console.log(e);
            }
        },

        async usePromoCode () {
            try {
                const response = await server.usePromoCode({ code: this.promoCode, });
                if (response) {
                    this.promoCodeReply = "OK";
                }
            }
            catch (e) {
                console.log(e);
            }
        },

        formatPromoCodeResponse (response) {
            // Parse the expiry date from the response
            const expiryDate = new Date(response.expiryDate);
            const currentDate = new Date();
  
            if (!response.isActive) {
                return this.$t("memberOnly.invalid");
            }

            // Check if the promo code has expired
            if (currentDate > expiryDate) {
                return this.$t('memberOnly.expired');
            }

            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const formattedExpiryDate = expiryDate.toLocaleDateString('it-IT', options);

            let message = `**${this.$t("memberOnly.promoCode")}:** ${response.code}\n`;
            message += `**${this.$t("memberOnly.description")}:** ${response.description}\n`;
            if (response.discountPercentage !== null) {
                message += `**${this.$t("memberOnly.discount")}:** ${response.discountPercentage}%\n`;
            } else {
                message += `**${this.$t("memberOnly.discount")}:** ${this.$t("memberOnly.notApplicable")}\n`;
            }
            message += `**${this.$t("memberOnly.validUntil")}:** ${formattedExpiryDate}\n`;

            return message;
        }
    },
    computed: {
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },

        configuration () {
            return this.restaurantProcessor.restaurantConfiguration;
        },

        restaurantHasTables () {
            return this.restaurantProcessor.tables.length > 0;
        },

        skipDeliveryState () {
            if (this.selected) {
                return this.selected.skipDeliveryState;
            }
            return this.restaurantProcessor.restaurantConfiguration.skipDeliveryState;
        },

        skipPreparationState () {
            if (this.selected) {
                return this.selected.skipPreparationState;
            }
            return this.restaurantProcessor.restaurantConfiguration.skipPreparationState;
        },

        scenes () {
            return this.restaurantProcessor.scenes ?? [];
        },

        coverPrice () {
            let coverPrice = 0;
            if (this.selectedOrderToPay && this.selectedOrderToPay.sceneId) {
                const orderScene = this.scenes.find((scene) => String(scene.id) === String(this.selectedOrderToPay.sceneId));
                if (orderScene) {
                    coverPrice = Number.parseFloat(orderScene.cover);
                }
            }
            else if (this.selected) {
                if (this.selected.cover) {
                    coverPrice = Number.parseFloat(this.selected.cover);
                }
            }
            else if (this.configuration && this.configuration.cover) {
                coverPrice = Number.parseFloat(this.configuration.cover);
            }
            return coverPrice;
        },

        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus() {
            return this.basketProcessor.selectedFixedMenus;
        },

        basketSelectedCustomProducts () {
            return this.basketProcessor.selectedCustomProducts;
        },

        basketTotalSelectedVolume () {
            return this.basketProcessor.totalSelectedVolume;
        },

        basketSubtotal () {
            return this.basketProcessor.subtotal;
        },

        basketTotal () {
            return this.basketProcessor.total;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>

        isDesktop () {
            return isDesktop;
        },

        customerHasRest () {
            return Number.isFinite(this.customerRest) && this.customerRest > 0;
        },

        clearButtonText () {
            let text = this.$t("generic.clear");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableOrderToAddProduct) {
                text += ` #${this.selectedTableOrderToAddProduct.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        continueButtonText () {
            let text = this.$t("generic.continue");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        confirmButtonText () {
            let text = this.$t("generic.confirm");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        addProductButtonText () {
            let text = this.$t("cashierPos.addProduct");

            if (this.selectedTableOrderToAddProduct) {
                text += ` #${this.selectedTableOrderToAddProduct.dailyId}`;
            }

            return text;
        },

        paymentAfterService () {
            if (this.selected) {
                return this.selected.paymentAfterService;
            }
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        },

        thirdPartyButtonIsDisabled () {
            return this.basketIsEmpty || !this.selectedThirdPartyOrigin || !this.thirdPartyOriginTotal;
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }
            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        },

        // includeOnePlusOne () {
        //     for (const productId in this.basket.selectedProducts) {
        //         const product = this.restaurantProcessor.getProductById(Number.parseInt(productId));
        //         if (product && product.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
        //             return true;
        //         }
        //     }

        //     return false;
        // }
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                if (value.length === 0) {
                    this.basket.discounts = [];
                    this.basket.additions = [];
                    this.basket.deductions = [];

                    if (!this.confirmedOrder && this.$refs.orderConfirmationModalBox.isActive) {
                        this.$refs.orderConfirmationModalBox.hide();
                    }
                }

                // <products-messages>
                // Remove a message if its product is no longer selected.
                for (const productId in this.basket.selectedProductsMessages) {
                    if (!value.some((product) => product.id === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsMessages[productId];
                    }
                }
                // </products-messages>

                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>
            },
        },
    },
    async created () {
        let token;
        try {
            if (isDesktop) {
                const { ipcRenderer, } = window.require("electron");
                const userData = JSON.parse(await ipcRenderer.invoke("host:getUserData"));
                if (userData) {
                    token = userData.token;
                }   
            }
        } catch (e) {
            console.log(e);
        }
            
        try {
            createPrimaryWs();

            primaryWs.addEventListener("open", () => {
                primaryWs.send(JSON.stringify({
                    restaurantId: this.restaurantId,
                    sceneId: selectedScene,
                    token: token,
                    service: {
                        name: "POS",
                    }
                }));
            });

            primaryWs.addEventListener("message", (event) => {
                let message;

                try {
                    message = JSON.parse(event.data);
                }
                catch {
                    return;
                }

                if (message.type === "RestaurantAuthentication" && message.result === "OK") {
                    console.log("connected to server");
                }

                if (message.type === "OrderInPreparationNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct || firstProduct.restaurantId !== this.restaurantId) {
                        return;
                    }

                    if (!this.ordersHandledByCashier[order.id]) {
                        this.playNewOrderNotificationSound();
                        this.printReceipt(order, true);
                    }
                }
            });
            primaryWs.keepAlive();
        }
        catch (error) {
            console.log(error);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.cashier-sale-point-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.main {
    height: 100%;

    background-color: rgb(245, 245, 245);
    align-content: space-between;

    :deep(.restaurant-product-list) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 66%;
    }
    :deep(.restaurant-product-list .slider) {
        height: 70%;
    }

    :deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 24%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.03);

        z-index: 3;
    }
    :deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }
    :deep(> .customer-basket .basket) {
        height: 100%;
    }
    :deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 80%;
        padding: 0 25px 25px 25px;
    }
    :deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 20%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    :deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }
    :deep(> .customer-basket .discount) {
        display: none;
    }
    :deep(> .customer-basket .total) {
        display: none;
    }
}

.action-box {
    position: relative;

    height: 10%;

    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    z-index: 5;

    :deep(.simple-button + .simple-button) {
        margin-left: 16px;
    }
}

.left-action-box {
    :deep(.simple-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(54, 54, 54);
    }
    :deep(.simple-button + .simple-button) {
        margin: 0;
    }
    :deep(.cash-register-button .simple-button) {
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.payment-method-selection-box {
    :deep(.simple-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(54, 54, 54);
    }
    :deep(.simple-button + .simple-button) {
        margin: 0;
    }
    :deep(.simple-button:first-child) {
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.action-button {
    position: relative;
}

.remote-notifications-length-text {
    @extend .--unselectable;

    position: absolute;
    right: -10px;
    top: -10px;

    width: 24px;
    height: 24px;

    background-color: rgb(227, 65, 43);
    border-radius: 50%;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: rgb(255, 255, 255);
}

.cashier-modal-box {
    :deep(.close-button) {
        right: 22px;
        top: calc(15vh - 22px);

        transform: translateY(-100%);

        z-index: 999;
    }

    :deep(.modal-box__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.order-list-modal-box {
    :deep(.restaurant-order) {
        width: 40%;
        margin: 25px;
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);

    &--to-pay {
        :deep(.restaurant-order .name) {
            display: none;
        }
    }
}

.order-confirmation-box {
    width: 65%;
    height: 100%;

    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .basket-box {
        height: 65%;
    }
    .basket-box:deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: transparent;
    }
    .basket-box:deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }
    .basket-box:deep(> .customer-basket .basket) {
        height: 100%;
    }
    .basket-box:deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 70%;
        padding: 0 25px 25px 25px;
    }
    .basket-box:deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 30%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .basket-box:deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }
    .basket-box:deep(> .customer-basket .discount) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }
    .basket-box:deep(> .customer-basket .total) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }

    .take-away-box {
        height: 10%;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .action-box {
        height: 25%;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.take-away {
    margin: 0;
    padding: 0;

    &__text {
        margin: 0 12px 0 0;
        padding: 0;

        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(66, 66, 66);
    }
}

.order-payment-box {
    overflow: auto;

    width: 35%;
    height: 100%;

    background-color: rgb(250, 250, 250);

    .title {
        height: inherit;

        &__text {
            @extend .--unselectable;

            font-weight: 600;
            font-size: 20px;
            color: rgb(160, 160, 160);
        }
    }
}

.cash-payment-box {
    height: inherit;

    :deep(.cashier-payment-calculator) {
        margin: 50px 0;
    }
}

.card-payment-box {
    height: inherit;
}

.third-party-payment-box {
    height: inherit;
}

.third-party-payment-textbox {

    :deep(.simple-textbox) {
        width: 80%;
    }
    margin-top: 25px;
}

.full-height {
    height: 100%;
}

.third-party-select-box {
    margin-top: 50px;
    width: 80%;
}

.order-confirm-notice-text {
    width: 100%;
    margin: 20px;

    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: rgb(232, 142, 27);
}

.payment-method-button {
    &--selected:deep(.simple-button__text) {
        color: $primary-brand-color;
    }
}

.order-completed-box {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);

    z-index: 50;

    &__daily-id-text {
        position: relative;

        margin-top: 20px;

        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);

        &::after {
            content: "";

            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;

            background-color: $primary-brand-color;
        }
    }

    &__customer-rest-text {
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }

    .order-completed-icon-box {
        margin: 0;
        padding: 20px;

        border: 4px solid rgb(20, 153, 98);
        border-radius: 50%;

        &__icon {
            width: 48px;
            height: 48px;

            fill: rgb(20, 153, 98);
        }
    }
}

.restaurant-product-list {
    &::before {
        min-height: 180px;
        height: 30%;
    }

    :deep(.restaurant-product) {
        max-height: 208px;
    }
    :deep(.restaurant-product .preview-horizontal) {
        &__image {
            width: 72px;
            height: 72px;
        }
    }
    :deep(.restaurant-fixed-menu .preview) {
        &__image {
            width: 100%;
            max-width: 88px;
        }
        &__image:nth-child(2) {
            width: 100%;
            max-width: 142px;
        }
    }
}

.cash-register-button {
    position: relative;

    :deep(.simple-button) {
        min-width: 78px;

        &__text {
            opacity: 0;
        }
    }

    &__icon {
        pointer-events: none;

        position: absolute;

        width: 23px;

        fill: rgb(255, 255, 255);
    }
}

.cash-register-modal-box {
    .simple-button {
        margin: 40px;
    }
}

.top-right-div {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 12px;
    .language-selector {
        :deep(.language) {
            background-color: transparent;
            border-radius: 0;
        }
        :deep(.language.language--selected) {
            background-color: transparent;
        }
        :deep(.language .language__name) {
            display: none;
        }
        :deep(.language .language__icon) {
            margin: 0;

            opacity: 0.5;
        }
        :deep(.language.language--selected .language__icon) {
            opacity: 1;
        }
        :deep(.language + .language) {
            margin: 0;
        }
    }
}

.scene-select-box {
    min-width: 174px;
    :deep(.choices__item) {
        color: rgb(254,254,254);
        font-weight: 600;
        font-size: 15px;
    }
    :deep(.choices__inner) {
        border: none;
        background-color: transparent;
    }
    :deep(.choices__list) {
        border: none;
        border-radius: 0;
    }
    :deep(.choices__item--choice) {
        border: none;
        background-color: rgb(77,77,77);
    }
    :deep(.choices__item--choice) {
        font-size: 1rem;
        padding-right: 0;
    }
}

.scene-box {
    margin: 0;
    padding: 0;
}

.cover-number {
    margin-left: 20px;
    width: 130px;
}

.one-plus-one-button {
    :deep(.simple-button) {
        min-width: 78px;
        margin-left: 20px;
    }
}

.third-party-payment-box {
    :deep(.simple-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border: none;
        border-left: 1px solid rgb(54, 54, 54);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    :deep(.simple-button + .simple-button) {
        margin: 0;
    }

    :deep(.simple-button:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }   
}

.member-only {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 60px;
  height: 60px;
  background-color: $primary-brand-color;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  &__logo {
    width: 42px;
    height: 42px;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  }
}

.member-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);
}

.feature-box {
  background-color: $primary-brand-color;
  border-radius: 12px;
  width: 30%; /* Adjust as needed */
  margin: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.feature-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .feature-box {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .feature-box {
    width: 100%;
  }
}

.promo-code-feature {
  padding: 20px;
  box-sizing: border-box;
}

/* Container for the textbox and buttons */
.promo-code-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

/* Style for the textbox */
.promo-code-input-container .simple-textbox {
  flex: 1;
  margin-right: 10px;
  font-size: 18px;
}

/* Style for the buttons */
.promo-code-button {
  padding: 15px 20px;
  font-size: 16px;
  margin: 0px 10px;
  flex-shrink: 0;
}

/* Remove margin from the last button */
.promo-code-button:last-child {
  margin-right: 0;
}

/* Style for the response message */
.promo-code-response {
  white-space: pre-wrap; /* Preserva gli a capo e gli spazi */
  font-size: 26px;
  color: #333;
  text-align: left;
  width: 80%;
  max-width: 800px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .promo-code-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .promo-code-input-container simple-textbox,
  .promo-code-button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .promo-code-button:last-child {
    margin-bottom: 0;
  }
}
</style>
