<template>
    <div class="flex flex--100 primary-footer">
        <div class="flex flex--100 flex--x-align-center">
            <div class="flex flex--60">
                <p class="copyright">
                    COPYRIGHT FUNENG TECHNOLOGIES, 2021-{{ currentYear }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrimaryFooter",
    computed: {
        currentYear () {
            return (new Date()).getUTCFullYear();
        },
    },
};
</script>

<style lang="scss" scoped>
.primary-footer {
    margin-top: 100px;
}

.copyright {
    cursor: default;
    user-select: none;

    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.04rem;
    color: rgb(140, 140, 140);
}
</style>
