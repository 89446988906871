<template>
    <div class="flex restaurant-product-tag" :class="getModifiers()" @click="onClick">
        <span class="flex restaurant-product-tag__name">{{ $t(`${this.productTag.name}`) }}</span>
    </div>
</template>

<script>
// import UpdateProductTagModalBox from "@/components/windows/UpdateProductTagModalBox";
export default {
    name: "RestaurantProductTag",
    components: {
        // UpdateProductTagModalBox,
    },
    props: {
        productTag: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        showEditor: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:selected",
        "product-tag-update",
        "product-tag-delete",
    ],
    methods: {
        getModifiers () {
            return {
                "restaurant-product-tag--selectable": this.selectable,
                "restaurant-product-tag--selected": this.selected,
                "restaurant-product-tag--active": this.isActive,
            };
        },

        onClick () {
            if (this.selectable) {
                if (this.showEditor) {
                    this.showUpdateProductTagModalBox();
                }
                else {
                    this.$emit("update:selected", !this.selected);
                }
            }
        },

        onProductTagUpdate (productTag) {
            this.$emit("product-tag-update", productTag);
        },

        onProductTagDelete (productTagId) {
            this.$emit("product-tag-delete", productTagId);
        },

        showUpdateProductTagModalBox () {
            this.$refs.updateProductTagModalBox.load();
            this.$refs.updateProductTagModalBox.window.show();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";
.restaurant-product-tag {
    @extend .--unselectable;
    padding: 12px 16px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    &__name {
        font-size: 16px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &--selectable {
        cursor: pointer;
    }
    &--selected {
        outline-color: $primary-brand-color;
    }
    &--active {
        background-image: linear-gradient(45deg, rgb(31, 140, 17), rgb(41, 150, 27));
    }
}
</style>
