<template>
    <div class="flex flex--100 restaurant-list-view">
        <language-selector/>
        <home-navigation :selected="1"/>
        <div class="flex flex--100 choose-a-restaraunt">
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex title choose-restaurant-text">{{$t("loyalty.title")}}</h2>
            </div>
        </div>
        <div class="flex flex--100 flex--y-align-center flex--x-align-around restaurant-list">
           <div class="flex flex--100 flex--y-align-center form-container">
                <div class="flex flex--100 flex--x-align-center">
                    <primary-logo/>
                </div>
                <form id="loyalty-form" class="flex flex--100 flex--direction-column" v-if="!submitted">
                    <div class="flex flex--100 loyalty-name">
                        <simple-textbox
                            :placeholder="$t(`loyalty.firstName`)"
                            name="firstName"
                            class="flex loyalty-name__first"
                            ref="firstNameTextbox"
                            v-model:model-value="formData.firstName"
                        />
                        <simple-textbox
                            :placeholder="$t(`loyalty.lastName`)"
                            name="lastName"
                            class="flex loyalty-name__last"
                            ref="lastNameTextbox"
                            v-model:model-value="formData.lastName"
                        />
                    </div>
                    
                    <div class="flex flex--100 flex--x-align-center">
                        <simple-textbox
                            name="email"
                            :placeholder="$t(`loyalty.email`)"
                            class="flex flex--100"
                            ref="emailTextbox"
                            v-model:model-value="formData.email"
                        />
                    </div>
                    
                    <div class="flex flex--100 flex--x-align-center">
                        <simple-textbox
                            name="phone"
                            :placeholder="$t(`loyalty.phone`)"
                            class="flex flex--100"
                            ref="phoneTextbox"
                            v-model:model-value="formData.phone"
                        />
                    </div>

                    <div class="flex flex--100 loyalty-birthday">
                        <span class="text loyalty-birthday__text">{{ $t(`loyalty.birthday`) }}</span>
                        <v3-date-picker
                            class="flex"
                            input-format="dd/MM/yyyy"
                            :locale="calendarLanguage"
                            v-model:model-value="formData.birthday"
                        />
                        <span v-if="birthdayIsValid" class="error-message">{{ $t(`loyalty.MISSING_BIRTHDAY`) }}</span>
                    </div>

                    <span class="flex flex--100 optional">{{ $t(`loyalty.optional`) }}:</span>

                    <div class="flex flex--100 loyalty-address">
                        <simple-textbox
                            :placeholder="$t(`loyalty.street`)"
                            name="street"
                            class="flex flex--100 loyalty-address__street"
                            ref="streetTextbox"
                            v-model:model-value="formData.street"
                        />
                    </div>

                    <div class="flex flex--100 loyalty-address">
                        <simple-textbox
                            :placeholder="$t(`loyalty.city`)"
                            name="city"
                            class="flex loyalty-address__city"
                            ref="cityTextbox"
                            v-model:model-value="formData.city"
                        />
                        <simple-textbox
                            :placeholder="$t(`loyalty.province`)"
                            name="province"
                            class="flex loyalty-address__province"
                            ref="provinceTextbox"
                            v-model:model-value="formData.province"
                        />
                        <simple-textbox
                            :placeholder="$t(`loyalty.postalCode`)"
                            name="postalCode"
                            class="flex loyalty-address__postalCode"
                            ref="postalCodeTextbox"
                            v-model:model-value="formData.postalCode"
                        />
                    </div>

                        <!-- Consenso per l'Informativa sulla Privacy -->
                        <div class="flex flex--100 loyalty-consent">
                        <input
                            id="consent"
                            name="privacy_policy"
                            class="loyalty-consent__checkbox"
                            type="checkbox"
                            v-model="formData.consent"
                            required
                        />
                        <label for="consent" class="text loyalty-consent__text">
                            {{ $t(`loyalty.read`) }}
                            <a
                            class="text loyalty-consent__link"
                            href="https://www.iubenda.com/privacy-policy/95253192"
                            target="_blank"
                            >{{ $t(`loyalty.privacy`) }}</a
                            >
                            {{ $t(`loyalty.authorize`)}}
                        </label>
                        </div>

                        <!-- Consenso per Email -->
                        <div class="flex flex--100 loyalty-consent">
                        <input
                            id="consentEmail"
                            name="consent_email"
                            class="loyalty-consent__checkbox"
                            type="checkbox"
                            v-model="formData.consentEmail"
                        />
                        <label for="consentEmail" class="text loyalty-consent__text">
                            {{ $t(`loyalty.consentEmail`) }}
                        </label>
                        </div>

                        <!-- Consenso per SMS -->
                        <div class="flex flex--100 loyalty-consent">
                        <input
                            id="consentSMS"
                            name="consent_sms"
                            class="loyalty-consent__checkbox"
                            type="checkbox"
                            v-model="formData.consentSMS"
                        />
                        <label for="consentSMS" class="text loyalty-consent__text">
                            {{ $t(`loyalty.consentSMS`) }}
                        </label>
                        </div>

                        <!-- Consenso per Chiamate Dirette -->
                        <div class="flex flex--100 loyalty-consent">
                        <input
                            id="consentPhoneCall"
                            name="consent_calls"
                            class="loyalty-consent__checkbox"
                            type="checkbox"
                            v-model="formData.consentPhoneCall"
                        />
                        <label for="consentPhoneCall" class="text loyalty-consent__text">
                            {{ $t(`loyalty.consentPhoneCall`) }}
                        </label>
                        </div>

                        <!-- Pulsante di invio -->
                        <button
                        @click="submitForm"
                        class="loyalty-button"
                        id="loyalty-submit"
                        type="button"
                        :disabled="!formData.consent"
                        >
                        {{ $t(`loyalty.subscribe`) }}
                        </button>
                </form>

                <div class="flex flex--100 loyalty-name" v-if="submitted">
                    <p class="title">{{ $t(`loyalty.thanks`) }}</p>
                </div>
        </div>
        </div>
        <footer-navigation/>
    </div>
</template>

<script>
import FooterNavigation from "@/components/navigation/FooterNavigation";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import HomeNavigation from "@/components/navigation/HomeNavigation";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import {enUS, zhCN, it} from "date-fns/locale";
import { user } from "@/user";

export default {
    name: "LoyaltyView",
    components: {
        PrimaryLogo,
        HomeNavigation,
        LanguageSelector,
        FooterNavigation,
        SimpleTextbox,
        V3DatePicker,
    },
    data () {
        return {
            formData: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                birthday: null,
                consent: false,
                newsletter: false,
                street: "",
                city: "",
                province: "",
                postalCode: "",
                consentEmail: false,
                consentSMS: false,
                consentPhoneCall: false,
            },
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            submitted: false,
            birthdayIsValid: false,

            errorType: {
                MISSING_STREET: {
                    id: 1,
                    text: this.$t("loyalty.MISSING_STREET"),
                },
                MISSING_CITY: {
                    id: 2,
                    text: this.$t("loyalty.MISSING_CITY"),
                },
                MISSING_POSTAL_CODE: {
                    id: 3,
                    text: this.$t("loyalty.MISSING_POSTAL_CODE"),
                },
                MISSING_PROVINCE: {
                    id: 4,
                    text: this.$t("loyalty.MISSING_PROVINCE"),
                },
                INCORRECT_POSTAL_CODE: {
                    id: 5,
                    text: this.$t("loyalty.INCORRECT_POSTAL_CODE"),
                },
                MISSING_FIRST_NAME: {
                    id: 6,
                    text: this.$t("loyalty.MISSING_FIRST_NAME"),
                },
                MISSING_LAST_NAME: {
                    id: 7,
                    text: this.$t("loyalty.MISSING_LAST_NAME"),
                },
                MISSING_EMAIL: {
                    id: 8,
                    text: this.$t("loyalty.MISSING_EMAIL"),
                },
                MISSING_PHONE: {
                    id: 9,
                    text: this.$t("loyalty.MISSING_PHONE"),
                },
            },
        };
    },
    computed: {
        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
    },
    methods: {
        async submitForm () {
            if (this.formData.consent && this.validateDeliveryForm()) {
                this.submitted = true;
                // eslint-disable-next-line no-undef
                if (_iub) {
                    // eslint-disable-next-line no-undef
                    _iub.cons_instructions.push(
                        [
                            "submit",
                            {
                                submitElement: document.getElementById("loyalty-submit"),
                                form: {
                                    selector: document.getElementById("loyalty-form"),
                                    map: {
                                        subject: {
                                            first_name: "firstName",
                                            last_name: "lastName",
                                            email: "email",
                                            phone: "phone",
                                        },
                                        preferences: {
                                            privacy_policy: "privacy_policy",
                                            consent_email: "consent_email",
                                            consent_sms: "consent_sms",
                                            consent_calls: "consent_calls",
                                        },
                                    },
                                },
                                consent: {
                                    legal_notices: [
                                        {
                                            identifier: "privacy_policy",
                                        },
                                    ],
                                },
                            },
                            {
                                success: function (response) {
                                    console.log(response);
                                },
                                error: function (response) {
                                    console.log(response);
                                },
                            },
                        ]
                    );
                }
      
                await user.createCustomer({
                    firstName: this.formData.firstName,
                    lastName: this.formData.lastName,
                    email: this.formData.email,
                    phone: this.formData.phone,
                    birthday: this.formData.birthday,
                    consent: this.formData.consent,
                    consentEmail: this.formData.consentEmail,
                    consentSMS: this.formData.consentSMS,
                    consentPhoneCall: this.formData.consentPhoneCall,
                    newsletter: this.formData.newsletter,
                    address: `${this.formData.street},${this.formData.city},${this.formData.province},${this.formData.postalCode}`,
                });
            }
        },

        clearFormErrors () {
            const firstNameTextbox = this.$refs.firstNameTextbox;
            const lastNameTextbox = this.$refs.lastNameTextbox;
            const phoneTextbox = this.$refs.phoneTextbox;
            const emailTextbox = this.$refs.emailTextbox;
            const cityTextbox = this.$refs.cityTextbox;
            const provinceTextbox = this.$refs.provinceTextbox;
            const postalCodeTextbox = this.$refs.postalCodeTextbox;

            firstNameTextbox.clearErrors();
            lastNameTextbox.clearErrors();
            postalCodeTextbox.clearErrors();
            phoneTextbox.clearErrors();
            emailTextbox.clearErrors();
            cityTextbox.clearErrors();
            provinceTextbox.clearErrors();

            this.birthdayIsValid = true;
        },

        validateDeliveryForm () {
            const firstNameTextbox = this.$refs.firstNameTextbox;
            const lastNameTextbox = this.$refs.lastNameTextbox;
            const phoneTextbox = this.$refs.phoneTextbox;
            const emailTextbox = this.$refs.emailTextbox;
            const streetTextbox = this.$refs.streetTextbox;
            const cityTextbox = this.$refs.cityTextbox;
            const provinceTextbox = this.$refs.provinceTextbox;
            const postalCodeTextbox = this.$refs.postalCodeTextbox;
            let addressValid = true;
            this.clearFormErrors();

            if (this.formData.street.length > 0 || this.formData.city.length > 0 || this.formData.province.length > 0 || this.formData.postalCode.length > 0) {
                streetTextbox.validate(this.errorType.MISSING_STREET, this.formData.street.length > 0);
                cityTextbox.validate(this.errorType.MISSING_CITY, this.formData.city.length > 0);

                if (postalCodeTextbox.validate(this.errorType.MISSING_POSTAL_CODE, this.formData.postalCode.length > 0)) {
                    postalCodeTextbox.validate(this.errorType.INCORRECT_POSTAL_CODE, Number.isFinite(Number.parseInt(this.formData.postalCode)));
                }
                provinceTextbox.validate(this.errorType.MISSING_PROVINCE, this.formData.province.length > 0);
                addressValid = streetTextbox.isValid && cityTextbox.isValid && postalCodeTextbox.isValid && provinceTextbox.isValid;
            }

            firstNameTextbox.validate(this.errorType.MISSING_FIRST_NAME, this.formData.firstName.length > 0);
            lastNameTextbox.validate(this.errorType.MISSING_LAST_NAME, this.formData.lastName.length > 0);
            emailTextbox.validate(this.errorType.MISSING_EMAIL, this.formData.email.length > 0);
            phoneTextbox.validate(this.errorType.MISSING_PHONE, this.formData.phone.length > 0);
            this.birthdayIsValid = Boolean(!this.formData.birthday);

            return firstNameTextbox.isValid && lastNameTextbox.isValid && emailTextbox.isValid && phoneTextbox.isValid && addressValid && this.formData.birthday;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.text { 
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #232021;
}
.paragraph {
    margin-top: 28px;

    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.07rem;
}

.title {
    max-width: 60vw;
    text-align: center;
    margin: 0 auto;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.07rem;
}

.restaurant-list-view {
    :deep(.language-selector) {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 100000;
        background-color: rgba(255, 255, 255, 0.08);

        .language {
            margin: 0;
            background-color: transparent;

            &__name {
                font-size: 14px;
                font-weight: 400;
            }
            &__icon {
                width: 18px;
            }
            &:hover {
                .language__name {

                }
            }
            &--selected {
                .language__name {
                    color: $primary-brand-color;
                    font-weight: 600;
                }
            }
        }
    }
}

.restaurant-list {
    padding: 30px;
    min-height: 80vh;
    background-color: rgb(0, 0, 0);

    background-image: url("https://giaogiao.it:3000/images/internal/TRY.jpg");
    background-size: cover;

    .restaurant-box {
        will-change: transform;
        transition: transform 300ms;

        margin: 30px;

        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
        background-color: $primary-brand-color;
        border-radius: 20px;
        padding: 30px;

        .primary-logo {
            width: 100px;
            margin: 0 auto 20px auto;
        }

        &:hover {
            transform: scale(1.12);
        }
        .paragraph {
            font-size: 20px;
        }
        .title {
            font-size: 30px;
        }
    }
}

.choose-restaurant-text {
    margin: 40px auto;
    color: rgb(0, 0, 0);
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-style: oblique;

    @media (max-width: 448px) {
        font-size: 17px;
    }
}

.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fdfdfd;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="date"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.loyalty-button {
    padding: 10px 20px;
    background-color: $primary-brand-color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    font-size: 15px;
    font-weight: 600;
    min-width: 174px;
    padding: 14px;
    background-color: $primary-brand-color;
    border-radius: 12px;
}

.loyalty-button:disabled {
    background-color: $primary-color;
    opacity: 0.44;
    cursor: not-allowed;
}

.loyalty-name {
    margin-top: 30px;
    &__first {
        width: 48%;
        margin-right: auto;
    }
    &__last {
        width: 48%;
    }
}

.loyalty-birthday {
    &__text {
        margin-bottom: 5px;
    }
}

.loyalty-consent {
    &__text {
        margin-bottom: 5px;
    }

    &__checkbox {
        margin-top: 2px;
        margin-right: 5px;
        width: 18px;
        height: 18px;   
    }

     &__link {
        text-decoration: underline;
        color: $primary-brand-color;
    }
}

.loyalty-address {
    &__street {
        
    }

    &__city {
       width: 32%;
        margin-right: auto;
    }

    &__province {
        width: 32%;
        margin-right: auto;
    }

    &__postalCode {
        width: 32%;
    }
}

.optional {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
}

.error-message{
    font-size: 13px;
    font-weight: 400;
    color: #e33232;
    margin-left: 20px;
}
</style>
