<template>
    <div class="flex flex--100 add-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-category-form">
                <div class="flex flex--100 box">
                    <div class="flex flex--100 tooltip" @mouseout="closeTooltip">
                        <span class="tooltiptext" id="myTooltip">{{ copyText }}</span>
                        <simple-textbox @click="copyToken" class="token" :is-readonly="true" placeholder="Token" :model-value="token" ref="tokenTextbox"/>
                    </div>
                </div>
                <div class="flex flex--100 box">
                    <simple-textbox :placeholder="$t('management.generic.name')" v-model:model-value="name" ref="nameTextbox"/>
                </div>
                <div class="flex flex--100 box">
                    <simple-selectbox
                        ref="ModeSelectbox"
                        class="mode-select-box"
                        :select-placeholder="$t('management.generic.mode')"
                        :options="modeOptions"
                        :can-search-options="false"
                        :can-select-empty-value="false"
                        :pre-select="true"
                        v-model:model-value="selectedMode"
                    />
                </div>
                <div class="flex flex--100 box">
                    <prism-editor class="configuration-textbox " v-model:model-value="configuration" :highlight="highlightJSON"></prism-editor>
                </div>
                 <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button class="update-button" :text="$t('generic.update')" @click="updateEndpoint" :is-loading="isWaitingServerResponse"/>
                    <simple-button :text="$t('generic.delete')" @click="deleteEndpoint" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/

export default {
    name: "UpdateEndpointModalBox",
    props: {
        closeOnEndpointUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "endpoint-update", "endpoint-delete" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        SimpleSelectbox,
        PrismEditor,
    },
    data () {
        return {
            mode: null,
            modeOptions: [{
                text: this.$t("endpoint.cashier"),
                value: "cashier",
            }, {
                text: this.$t("endpoint.kiosk"),
                value: "kiosk",
            }, {
                text: this.$t("endpoint.kitchen"),
                value: "kitchen",
            }, {
                text: this.$t("endpoint.waiter"),
                value: "waiter",
            }],
            selectedMode: "cashier",
            name: "",
            configuration: "",
            saved: {},
            id: null,
            restaurantId: null,
            token: null,
            isWaitingServerResponse: false,
            copyText: "Copy to clipboard",
        };
    },
    methods: {
        load (endpoint) {
            this.saved.name = endpoint.name;
            this.saved.configuration = endpoint.configuration;
            this.saved.selectedMode = endpoint.mode;
            this.selectedMode = endpoint.mode;
            this.name = endpoint.name;
            this.configuration = endpoint.configuration;
            this.id = endpoint.id;
            this.restaurantId = endpoint.restaurantId;
            this.token = endpoint.token;
            console.log(this.$refs.ModeSelectbox);
        },
        
        validateAddEndpointForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.name = "";
            this.configuration = "";
            this.selectedMode = "";
        },

        async updateEndpoint () {
            if (!this.validateAddEndpointForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const endpoint = await user.updateEndpoint({
                    id: this.id,
                    restaurantId: this.restaurantId,
                    name: this.name,
                    mode: this.selectedMode,
                    configuration: this.configuration,
                });

                if (endpoint) {
                    this.$emit("endpoint-update", endpoint);

                    if (this.closeOnEndpointUpdate) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.endpointUpdatedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async deleteEndpoint () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const id = await user.deleteEndpoint({
                    id: this.id,
                });

                if (id) {
                    this.$emit("endpoint-delete", id);

                    if (this.closeOnEndpointUpdate) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.endpointDeletedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        highlightJSON (code) {
            return highlight(code, languages.json);
        },

        copyToken () {
            navigator.clipboard.writeText(this.token);
            this.copyText = "Copied";
        },

        closeTooltip () {
            this.copyText = "Copy to clipboard";
        }
    },
    computed: {
        window () {
            return this.$refs.window;
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.add-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px 15px 0px;
}

.configuration-textbox {
    width: 100%;

    padding: 20px;

    background-color: rgb(77, 77, 77);
    border-radius: 6px;
    caret-color: rgb(255,255,255);

    :deep(*) {
        font-family: "Fira code", "Fira Mono", "Consolas", "Menlo", "Courier", "monospace";
        font-size: 18px;
        line-height: 1.5;
    }
}

.mode-select-box {
    width: 300px;
}

.token {
    width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.box  {
    margin-bottom: 20px;
}

.update-button {
    margin-right: 20px;
}
</style>
