<template>
    <div class="flex flex--100 fixed-menu-product-selector">
        <div class="flex flex--100">
            <simple-button :text="$t('management.restaurantFixedMenuManagementTab.selectFixedMenu')" @click="showFixedMenuProductModalBox" class="select-button"/>
        </div>
        <modal-box ref="modalBox" :append-to-body="true" @hide="save">
            <div class="flex flex--100 fixed-menu-product-modal-box">
                <span class="note">{{ $t("management.restaurantFixedMenuManagementTab.note") }}</span>
                <div class="flex flex--100">
                    <div class="flex flex--100 choice-add">
                        <simple-button class="create-choice-button" :text="$t('management.restaurantFixedMenuManagementTab.createChoice')" @click="createChoice"/>
                        <simple-button class="remove-choice-button" :text="$t('management.restaurantFixedMenuManagementTab.removeChoice')" @click="removeChoice"/>
                    </div>
                    <div class="flex flex--100 flex--x-align-center navigation">
                        <div
                            class="flex flex--x-align-center flex--y-align-center navigation__button"
                            v-for="(choice, i) in fixedMenuChoices"
                            :key="i"
                            :class="getNavigationButtonModifiers(i)"
                            @click="selectChoice(i)"
                        >
                            <span v-show="removeMode" class="delete-icon">-</span>
                            {{ i + 1 }}
                        </div>
                    </div>
                    <div class="flex flex--100 preview-box">
                        <div class="flex flex--100 slider" v-if="rerender">
                            <splide :options="splideOptions" :extensions="splideExtensions" ref="productSplide">
                                <splide-slide v-for="product in selectedProducts" :key="product.id">
                                    <div class="flex flex--100 item">
                                        <div class="flex flex--100 card">
                                            <div class="flex flex--100 flex--x-align-center preview-mobile">
                                                <img class="preview-mobile__image" :src="product.previewImage.uri" loading="lazy" alt="" v-if="product.previewImage">
                                                <div v-else class="preview-mobile__no-image"></div>
                                            </div>
                                            <div class="flex flex--100 flex--x-align-center" v-if="product.localizations">
                                                <span class="name">{{ restaurantProcessor.getProductLocalizedName(product.id) }}</span>
                                            </div>
                                            <div class="flex flex--100 flex--x-align-center" v-if="product.price">
                                                <span class="price">€ {{ normalizePriceToDisplay(product.price) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </splide-slide>
                            </splide>
                        </div>
                    </div>
                    <hr class="separator"/>
                    <div class="flex flex--100 product-list" v-show="fixedMenuChoices.length > 0">
                        <div class="flex flex--20 search-bar">
                            <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                        </div>
                        <div class="flex flex--100 flex--x-align-center product-list">
                            <span class="no-products" v-if="products.length === 0">{{$t(`management.restaurantFixedMenuManagementTab.noFixedMenus`)}}</span>
                            <div @click="selectProduct(product)" class="restaurant-product" :class="getProductModifiers(product)" v-for="product in orderedProducts" :key="product.id">
                                <span class="restaurant-product__name">{{  restaurantProcessor.getProductLocalizedName(product.id) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { RestaurantProcessor } from "@/utilities";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import Fuse from "fuse.js";
import { Grid } from "@splidejs/splide-extension-grid";
import { Splide, SplideSlide, } from "@splidejs/vue-splide";

export default {
    name: "FixedMenuProductSelector",
    components: {
        ModalBox,
        SimpleButton,
        SimpleTextbox,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        choices: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["update:choices"],
    data() {
        return {
            searchBarValue: "",
            fixedMenuChoices: [],
            removeMode: false,
            choiceIndex: -1,
            Splide,
            SplideSlide,
            splideExtensions: {
                Grid,
            },
            rerender: true,
        };
    },
    methods: {
        getProductModifiers (product) {
            return {
                "restaurant-product--selected": this.isProductSelectedInChoice(product),
            };
        },

        getNavigationButtonModifiers (i) {
            return {
                "navigation__button--active": this.choiceIndex === i,
            };
        },

        showFixedMenuProductModalBox () {
            this.fixedMenuChoices = [...this.choices,];
            if (this.fixedMenuChoices.length > 0) {
                this.choiceIndex = 0;
            }
            this.$refs.modalBox.show();
        },

        createChoice () {
            this.fixedMenuChoices.push({
                products: [],
            });
            this.choiceIndex = this.fixedMenuChoices.length - 1;
            this.rerender = false;
            this.$nextTick(() => {
                this.rerender = true;
            });
        },

        removeChoice () {
            this.removeMode = !this.removeMode;
        },

        selectChoice (i) {
            if (this.removeMode) {
                this.fixedMenuChoices.splice(i, 1);
                if (this.choiceIndex >= this.fixedMenuChoices.length) {
                    this.choiceIndex = this.fixedMenuChoices.length - 1;
                }
                if (this.fixedMenuChoices.length === 0) {
                    this.removeMode = false;
                    this.choiceIndex = -1;
                }
            }
            else {
                this.choiceIndex = i;
            }
            this.rerender = false;
            this.$nextTick(() => {
                this.rerender = true;
            });
        },

        selectProduct (product) {
            if (this.selectedChoice) {
                const index = this.selectedChoice.products.findIndex((productId) => product.id === productId);
                if (index > -1) {
                    this.selectedChoice.products.splice(index, 1);
                }
                else {
                    this.selectedChoice.products.push(product.id);
                }
            }
            this.rerender = false;
            this.$nextTick(() => {
                this.rerender = true;
            });
        },

        clear() {
            this.removeMode = false;
            this.fixedMenuChoices = [];
            this.searchBarValue = "";
        },

        isProductSelectedInChoice (product) {
            if (this.selectedChoice) {
                return this.selectedChoice.products.findIndex((productId) => productId === product.id) !== -1;
            }
            return false;
        },

        save () {
            this.$emit("update:choices", [ ...this.fixedMenuChoices, ]);
            this.clear();
        }
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // <fuse>
        fuse () {
            let products = this.restaurantProcessor.baseProducts.filter((product) => product.categories.length > 0);
            return new Fuse(products, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>

        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: true,
                pagination: true,
                drag: true,
                trimSpace: "move",
                padding: "28px",
                grid: this.splideGridOptions,
                gap: "10px",
            };
        },

        splideGridOptions () {
            return {
                dimensions: [ [ 2, 3, ], [ 2, 3, ], ],
            };
        },

        products () {
            return this.restaurantProcessor.baseProducts;
        },
        normalizedSelectedCategories() {
            return Object.keys(this.selectedCategories).filter((id) => this.selectedCategories[id] === true);
        },
        
        filteredProducts () {
            return [...this.products,].filter((product) => product.categories.length > 0);
        },

        orderedProducts () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return [ ...this.filteredProducts, ].sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        },

        selectedChoice () {
            if (this.choiceIndex > -1) {
                return this.fixedMenuChoices[this.choiceIndex];
            }
            return null;
        },

        selectedProducts () {
            if (this.selectedChoice) {
                return this.selectedChoice.products.map((productId) => this.restaurantProcessor.getProductById(productId)).filter((p) => p);
            }
            return [];
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.fixed-menu-product-selector {
    .select-button {
        width: 100%;
        padding: 10px;
        background-color: rgb(77, 77, 77);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .select-button:hover {
        background-color: rgb(50, 50, 50);
    }

    .fixed-menu-product-modal-box {
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-height: 70vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 16px; /* Added gap between items for better spacing */
    }
}

.restaurant-category {
    padding: 12px 16px;
    margin: 12px 10px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */

    &:hover {
        background-color: #5a5a5a;
        transform: scale(1.05); /* Slightly increase size on hover */
    }

    &--selectable {
        cursor: pointer;
    }

    &--selected {
        outline-color: $primary-brand-color;
    }
}

.modal-box {
    :deep(.modal-box__slot) {
        width: 80vw;
        min-width: 300px;
        max-width: 80vw;
        min-height: 50vh;
        padding: 30px;
        margin: 30px auto;
        background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }
}

.choice-container {
    padding: 0 0 20px 0;
    .simple-button {
        width: 100px;
        min-width: inherit !important;;
        border-radius: 0;
        border: 1px solid rgb(255,255,255);
    }
}

.choice-add {
    padding: 0 0 20px 0;
    .simple-button {
        min-width: inherit !important;;
    }
}

.navigation {
    position: relative;

    &__button {
        @extend .--unselectable;

        cursor: pointer;
        position: relative;

        width: 48px;
        height: 48px;
        margin: 0;
        padding: 0;

        background-color: rgb(42, 40, 42);
        border-radius: 50%;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.0032), 0 0 10px 0 rgba(0, 0, 0, 0.0064);

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.04rem;
        color: rgb(255, 255, 255);

        &--active {
            background-color: $primary-brand-color;
        }

        & + & {
            margin-left: 32px;
        }
    }
}

.toggle-button {
    min-width: 40px;

    &:hover {
        .toggle-button__icon {
            transform: scale(1.3);
        }
    }
}

.search-bar {
    position: relative;

    margin: 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}
.restaurant-product {
    cursor: pointer;

    padding: 12px 16px;
    margin: 12px 10px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */

    &:hover {
        background-color: #5a5a5a;
        transform: scale(1.05); /* Slightly increase size on hover */
    }

    &--selected {
        outline-color: $primary-brand-color;
    }

    &__name {
        font-size: 16px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
}

.create-choice-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px dashed rgb(255,255,255);
}

.remove-choice-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.delete-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 16px;
    height: 16px;
    background-color: #e3412b;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    text-align: center;
}

.preview-mobile {
    padding: 10px 10px 10px 5px;

    &__image {
        height: 88px;
        width: 88px;
        object-fit: contain;
    }

    &__no-image {
        height: 88px;
        width: 88px;
    }
}

h2, .name {
    color: rgb(20, 20, 20);
    font-size: 16px;
    font-weight: 600;
}

.price {
    margin: 8px 0 8px 0px;
    color: rgb(60, 60, 60);
    font-size: 16px;
    font-weight: 500;
}

.item {
    padding: 10px;
}

.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.splide__pagination__page {
    background-color: rgb(0,0,0);
    width: 12px;
    height: 12px;
}

.preview-box {
    padding: 0 20px 20px 20px;
}

.splide {
    width: 100%;
    margin: 0;
    padding: 0;
}

.note {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}
</style>
