<template>
    <div class="flex flex--100 restaurant-table-management">
        <modal-box ref="modalBox" :show-close-button="false" :close-on-background-click="true" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center restaurant-table-management__title">
                <span class="restaurant-table-management__title__text">{{$t('cashierPos.tables')}}</span>
            </div>
            <div class="flex flex--100 restaurant-table-management__box">
                <div class="flex flex--100 slider">
                    <splide :options="splideOptions" :extensions="splideExtensions">
                        <splide-slide v-for="table in tables" :key="table.id">
                            <restaurant-table
                                :table="table"
                                :is-mobile="isMobile"
                                :show-options="showOptions"
                                :payment-after-service="paymentAfterService"
                                @to-pay="toPay"
                                @bind-order="showOrderInputModalBox"
                                @add-product="addProduct"
                                @free="freeTable"
                                @select="select"
                                @table-on-click="tableOnClick"
                            />
                        </splide-slide>
                    </splide>
                </div>
            </div>
            <modal-box
                class="order-input"
                ref="orderInputModalBox"
                :show-close-button="false"
                :close-on-background-click="true"
                :show-back-button="true"
                :append-to-body="true"
                @before-hide="clear"
            >
                <div class="flex flex--100 flex--y-align-center order-input">
                    <div class="flex flex--70">
                        <simple-textbox class="flex flex--100" :placeholder="$t('cashierPos.orderId')" v-model:model-value="orderId"></simple-textbox>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button :text="$t('cashierPos.bindOrder')" @click="bindOrder"></simple-button>
                    </div>
                </div>
            </modal-box>
            <modal-box class="order-list-modal-box" ref="activeOrderListModalBox">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                    <h2 class="order-list-title__text">{{ $t("cashierPos.activeOrders") }}</h2>
                </div>
                <div v-if="selectedTable" class="flex flex--100 flex--x-align-center order-list">
                    <simple-button
                        v-for="order in selectedTable.orders"
                        :key="order"
                        :text="order.toString()"
                        @click="selectOrder(selectedTable.id, order)"
                    />
                </div>
            </modal-box>
        </modal-box>
    </div>
</template>

<script>
import {RestaurantProcessor} from "@/utilities";
import RestaurantTable from "@/components/RestaurantTable";
import ModalBox from "@/components/containers/ModalBox";
import {Splide, SplideSlide} from "@splidejs/vue-splide";
import { Grid } from "@splidejs/splide-extension-grid";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import {server} from "@/server";

export default {
    name: "RestaurantTableManagement",
    components: {
        SimpleTextbox,
        RestaurantTable,
        ModalBox,
        Splide,
        SplideSlide,
        SimpleButton,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        tables: {
            type: Array,
        },
        showOptions: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "table-update",
        "to-pay",
        "table-add-product",
        "table-update",
        "table-select",
        "table-on-click",
    ],
    data () {
        return {
            orderId: "",
            selectedTable: null,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // </restaurant>
        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: false,
                pagination: false,
                drag: true,
                trimSpace: "move",
                padding: "28px",
                heightRatio : this.isMobile ? 0 : 0.3,
                grid: {
                    dimensions: this.splideDimensions,
                },
            };
        },

        splideExtensions () {
            return {
                Grid,
            };
        },

        splideDimensions () {
            if (this.isMobile) {
                return [ [ 6, 1, ], [ 6, 1, ], ];
            }
            else {
                return [ [ 4, 4, ], [ 4, 4, ], ];
            }
        },

        paymentAfterService () {
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        },
    },
    methods: {
        async bindOrder () {
            try {
                const order = await server.updateOrder({
                    id: this.orderId,
                    tables: [this.selectedTable.id],
                });
                if (order) {
                    this.$refs.orderInputModalBox.hide();
                    this.clear();
                    this.$emit("table-update");
                }
            }
            catch (error) {
                console.log(error);
            }
        },

        toPay (orders) {
            this.$emit("to-pay", orders);
        },

        addProduct (table) {
            this.selectedTable = table;
            this.$refs.activeOrderListModalBox.show();
        },

        showOrderInputModalBox (table) {
            this.selectedTable = table;
            this.$refs.orderInputModalBox.show();
        },

        selectOrder (tableId, orderId) {
            this.$emit("table-add-product", tableId, orderId);
            this.$refs.activeOrderListModalBox.hide();
        },

        async freeTable (table) {
            try {
                await server.freeTable({
                    id: table.id,
                });
                this.$refs.orderInputModalBox.hide();
                this.$emit("table-update");
            }
            catch (error) {
                console.log(error);
            }
        },

        select (table) {
            this.$emit("table-select", table);
        },

        clear () {
            this.selectedTable = null;
            this.orderId = "";
        },

        show () {
            this.$refs.modalBox.show();
        },

        hide () {
            this.$refs.modalBox.hide();
        },

        tableOnClick (table) {
            this.$emit("table-on-click", table);
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
}
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-table-management {
    &__title {
        overflow: hidden;

        height: 15%;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &__text {
            margin: 50px 0;

            font-size: 32px;
            font-weight: 600;
        }
    }

    &__box {
        padding-bottom: 28px;

        background-color: rgb(240, 240, 240);
    }
}

.modal-box {
    :deep(&__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.slider {
    margin: 20px 0;
    padding: 0;

    z-index: 2;
}

.splide {
    width: 100%;
    margin: 0;
    padding: 0;
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);

    &--to-pay {
        :deep(.restaurant-order .name) {
            display: none;
        }
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}
</style>
