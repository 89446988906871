<template>
    <div class="flex flex--100 order-addition-modal-box">
        <modal-box ref="modalBox">
            <form class="flex flex--100 order-addition-form">
                <div class="flex flex--100">
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('order.additions')" v-model:model-value="addition" ref="additionTextbox"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-textbox class="description-textbox" :placeholder="$t('management.description')" :is-multiline="true" v-model:model-value="description" ref="descriptionTextbox"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button class="add-button" :text="$t('generic.add')" @click="addAddition"/>
                    </div>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100 flex--x-align-center" v-show="appliedAdditions.length === 0">
                    <span class="flex no-applied-additions-text">No additions applied</span>
                </div>
                <div class="flex flex--100" v-show="appliedAdditions.length > 0">
                    <div class="flex flex--100 flex--y-align-center applied-addition" v-for="(addition, i) in appliedAdditions" :key="i">
                        <div class="flex flex--50 flex--y-align-center">
                            <div class="flex flex--x-align-center flex--y-align-center remove-applied-addition-button" @click="removeAddition(i)">
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex remove-applied-addition-button__icon">
                                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                        <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                                        <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                                    </g>
                                </svg>
                            </div>
                            <span class="flex applied-addition__description">
                                <template v-if="addition.description">{{ addition.description }}</template>
                                <template v-else>Addition</template>
                            </span>
                        </div>
                        <div class="flex flex--50 flex--x-align-end">
                            <span class="flex applied-addition__value">{{ normalizePriceToDisplay(addition.value) }} €</span>
                        </div>
                    </div>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button :text='$t("generic.confirm")' @click="confirm"/>
                    <simple-button :text='$t("generic.cancel")' @click="hide"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";

const OrderAdditionErrorType = {
    MISSING_VALUE: {
        id: 1,
        text: "Addition is required.",
    },
    INVALID_VALUE: {
        id: 2,
        text: "Addition must be a number.",
    },
    NOT_POSITIVE: {
        id: 3,
        text: "Addition must be a positive number.",
    },
};

export default {
    name: "OrderAdditionModalBox",
    components: {
        SimpleTextbox,
        SimpleButton,
        ModalBox,
    },
    emits: [ "confirm", ],
    data () {
        return {
            addition: "",
            description: "",
            appliedAdditions: [],
        };
    },
    computed: {
        normalizedAddition () {
            return Number(Number(this.addition).toFixed(2));
        },

        totalAdditionApplied () {
            return this.appliedAdditions.reduce((total, addition) => total + addition.value, 0);
        },
    },
    methods: {
        validateForm () {
            const additionTextbox = this.$refs.additionTextbox;

            additionTextbox.clearErrors();

            if (additionTextbox.validate(OrderAdditionErrorType.MISSING_VALUE, this.addition.length > 0)) {
                if (additionTextbox.validate(OrderAdditionErrorType.INVALID_VALUE, Number.isFinite(this.normalizedAddition))) {
                    additionTextbox.validate(OrderAdditionErrorType.NOT_POSITIVE, this.normalizedAddition > 0);
                }
            }

            return additionTextbox.isValid;
        },

        show ({ appliedAdditions = [], }) {
            this.appliedAdditions = appliedAdditions;

            this.$refs.modalBox.show();
        },

        hide () {
            this.addition = "";
            this.description = "";
            this.appliedAdditions = [];

            this.$refs.modalBox.hide();
        },

        addAddition () {
            if (!this.validateForm()) {
                return;
            }

            this.appliedAdditions.push({
                value: this.normalizedAddition,
                description: this.description,
            });

            this.addition = "";
            this.description = "";
        },

        removeAddition (i) {
            this.appliedAdditions.splice(i, 1);
        },

        confirm () {
            this.$emit("confirm", { appliedAdditions: this.appliedAdditions, });
            this.hide();
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.order-addition-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-button {
    & + & {
        margin-left: 16px;
    }
}

.simple-textbox {
    width: 60%;
    max-width: 400px;
}

.description-textbox {
    margin-top: 14px;
}

.add-button {
    margin-top: 18px;
}

.applied-addition {
    padding: 12px 20px;

    background-color: rgb(246, 246, 246);
    border-radius: 12px;

    &__value {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }

    &__description {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }

    & + & {
        margin-top: 12px;
    }
}

.remove-applied-addition-button {
    cursor: pointer;

    width: 22px;
    height: 22px;

    margin-right: 16px;

    background-color: rgb(33, 33, 33);
    border-radius: 50%;

    &__icon {
        width: 12px;

        fill: rgb(255, 255, 255);

        transform: rotate(45deg);
    }
}

.no-applied-additions-text {
    font-weight: 600;
    font-size: 16px;
    color: rgb(160, 160, 160);
}
</style>
