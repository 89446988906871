<template>
    <div class="flex flex--100 restaurant-media-tab">
        <div class="flex flex--100 box">
            <simple-file-upload/>
        </div>
        <div class="flex flex--100 box">
            <media-selector :medias="images" v-model:selected="selectedImages"/>
        </div>
    </div>
</template>

<script>
import MediaSelector from "@/components/inputs/MediaSelector";
import SimpleFileUpload from "@/components/inputs/SimpleFileUpload";
import { server } from "@/server";

export default {
    name: "RestaurantMediaTab",
    components: {
        MediaSelector,
        SimpleFileUpload,
    },
    data () {
        return {
            images: [],
            selectedImages: [],
        };
    },
    methods: {
        async load () {
            this.images = await server.getImages();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";
</style>
