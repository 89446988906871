export const it = {
  brandName: "giaogiao",
  generic: {
    totalItem: "Risultati",
    totalPage: "Pagine",
    add: "Aggiungi",
    cash: "Contanti",
    cashier: "Cassa",
    order: "Ordine",
    card: "Carta",
    thirdParty: "Terze parti",
    cancel: "Annulla",
    clear: "Cancella",
    continue: "Continua",
    confirm: "Conferma",
    selectPaymentMethod: "Seleziona metodo di pagamento",
    products: "Prodotti",
    rest: "Resto",
    date: "Data",
    notApplicable: "N/A",
    takeAway: "Take away",
    exit: "Esci",
    back: "Indietro",
    close: "Chiudi",
    table: "Tavolo",
    times: "Orario",
    today: "Oggi",
    print: "Stampa",
    paymentThroughCashier: "PREMI QUI PER PAGARE ALLA CASSA",
    paymentThroughWeChatPay: "PREMI QUI PER PAGARE CON WECHAT PAY",
    invalidDate: "Invalid date",
    hasMissingChoices:
      "Scegli ancora un piatto | Scegli altri {choices} piatti",
    press: "Premi",
    toShowAllergenTab: "per visualizzare gli allergeni",
    popular: "Popolare",
    update: "Modifica",
    unavailable: "Non disponibile",
    chooseScene: "Scegli zona",
    allScene: "Tutte le zone",
    save: "Salva",
    cover: "N. Coperto",
    paid: "Pagato",
    openFileBrowser: "Aprire Esplora file",
    showCategories: "Mostra per categorie",
    showThirdParty: "Visualizza terze parti",
    backToTop: "Rimette Top auto",
    all: "Tutto",
    free: "Tocca per ricevere 1 gratis!",
    thirdPartyTotal: "Totale terze parti",
    username: "Nome utente",
    password: "Password",
    access: "Entra",
    gotIt: "Capito",
    default:
      "Predefinito se non esiste una versione in altre lingue, basta inserire solo questa",
    waitOther:
      "Per favore, attendi che le altre pietanze siano pronte altrove.",
    showHideOrders: "Mostra ordini nascosti",
    prebilling: "Preconto",
    delete: "Cancella",
    modifyOrder: "Modifica [lavoro in corso]",
    reprintFiscalReceipt: "Ristampa scontrino fiscale",
    printIntern: "Stampa scontrino non fiscale",
    expirationTime: "Data di scadenza",
    timeRemaining: "Il tempo rimanente: ",
    days: "giorni",
    hours: "ore",
    minutes: "minuti",
    seconds: "secondi",
    newArrival: "Novità",
    selectTag: "Seleziona Etichetta",
  },
  notification: {
    networkError: "Errore di rete, controlla la tua connessione a internet.",
    serverError: "Errore interno al server.",
    productAddedSuccess: "Prodotto aggiunto con successo.",
    productRemoveSuccess: "Prodotto rimosso con successo.",
    productRemoveFail:
      "La riomozione del prodotto è fallita perché è stato ordinato una volta.",
    productArchivingSuccess: "Prodotto archiviato con successo.",
    productUnarchivingSuccess: "Prodotto rimosso dall'archivio con successo.",
    productUpdatedSuccess: "Prodotto aggiornato con successo.",
    productStockChangedSuccess: "Quantità prodotto aggiornata con successo.",
    restaurantOpenSuccess: "Ristorante aperto con successo.",
    restaurantCloseSuccess: "Ristorante chiuso con successo.",
    websiteOrderEnabledSuccess: "Ordinazione da sito web abilitata.",
    websiteOrderDisabledSuccess: "Ordinazione da sito web disabilitata.",
    paymentAfterServiceEnabledSuccess: "Pagamento dopo il servizio abilitato.",
    paymentAfterServiceDisabledSuccess:
      "Pagamento dopo il servizio disabilitato.",
    kioskEnabledSuccess: "Kiosk abilitato.",
    kioskDisabledSuccess: "Kiosk disabilitato.",
    maxDeliveryDistanceChangeSuccess:
      "Distanza di spedizione massima aggiornata con successo.",
    stripeConfigurationChangeSuccess:
      "Configurazione di Stripe aggiornata con successo.",
    coverChangeSuccess: "Coperto aggiornato con successo.",
    skipDeliveryStateEnabledSuccess: "Salta fase di consegna abilitato.",
    skipDeliveryStateDisabledSuccess: "Salta la fase di consegna disabilitato.",
    skipPreparationStateEnabledSuccess: "Salta fase di preparazione abilitato.",
    skipPreparationStateDisabledSuccess:
      "Salta la fase di preparazione disabilitato.",
    customStyleChangeSuccess:
      "Stile personalizzato cambiato con successo, idiota.",
    passwordChangeSuccess: "Password cambiata con successo.",
    passwordChangeFail: "Cambio della passwrod fallito.",
    allergenAddedSuccess: "Allergene aggiunto con successo.",
    allergenUpdatedSuccess: "Allergene aggiornato con successo.",
    allergenDeletedSuccess: "Allergene cancellato con successo.",
    categoryAddedSuccess: "Categoria aggiunta con successo.",
    categoryUpdatedSuccess: "Categoria aggiornata con successo.",
    tableAddedSuccess: "Tavolo aggiunto con successo.",
    tableUpdatedSuccess: "Tavolo modificato con successo.",
    tableRemovedSuccess: "Tavolo cancellato con successo.",
    variationAddedSuccess: "Variazione aggiunta con successo.",
    variationUpdatedSuccess: "Variazione aggiornata con successo.",
    variationArchivedSuccess: "Variazione archiviata con successo.",
    variationUnarchivedSuccess: "Variazione inarchiviata con successo.",
    skipErrorOrders:
      "ordini di terze parti non sono caricati correttamente. Per maggiori informazione degli ordini puoi vedere dal console.",
  },
  error: {
    wrongPassword: "La password è errata.",
    fileSizeLimitExceeded:
      "Limite dimensione file superato, massimo { size } kb.",
  },
  checkout: {
    orderReceiveNowWait:
      "Riceverai presto il tuo ordine, contattaci per ogni dubbio",
    weAreWaitingForYou: "Ti aspettiamo!",
    whereToPay: "Dove vuoi pagare?",
    takeAway: "Asporto",
    preOrder: "Prenota",
    completePaymentToCashier: "Vai alla cassa per completare il pagamento",
    message: "Note per l'ordine",
    checkoutDeliveryErrorType: {
      MISSING_STREET: "È richiesto l'indirizzo.",
      MISSING_BUILDING_CODE: "È richiesto il numero civico.",
      MISSING_POSTAL_CODE: "È richiesto il CAP.",
      INCORRECT_POSTAL_CODE: "Il CAP deve essere un numero.",
      MISSING_FULL_NAME: "Nome e Cognome sono richiesti.",
      MISSING_MOBILE_PHONE: "È richiesto il numero di telefono.",
      MISSING_PERSONS: "È richiesto il numero di persone.",
      UNSUPPORTED_ZONE:
        "La zona inserita non è supportata per la consegna al momento.",
      MESSAGE_LENGTH_OVERFLOW: "Massimo 512 caratteri.",
    },
  },
  customerBasket: {
    subtotal: "Subtotale",
    discount: "Sconto",
    total: "Totale",
    emptyBasket: "Il tuo carrello è vuoto",
  },
  productList: {
    searchBarPlaceholder: "Cerca",
    productHasNoAllergens: "Questo prodotto non ha allergeni.",
  },
  cashierPos: {
    activeOrders: "Ordini attivi",
    tables: "Tavoli",
    orderId: "Numero di ordine",
    bindOrder: "Associa",
    freeTable: "Libera",
    addProduct: "Aggiungi",
    select: "Scegli",
    toPay: "Paga",
    ordersToPay: "Da Pagare",
    ordersInPreparation: "In Preparazione",
    ordersInDelivery: "In Consegna",
    completedOrders: "Completati",
    confirmAfterPayment:
      "Attenzione! Premendo questo pulsante verrà emesso lo scontrino fiscale. Premi solo dopo la conferma del pagamento.",
    maps: "Visualizza mappe ",
    confirmWithoutPayment:
      "Attenzione! Premendo questo pulsante NON emetterà lo scontrino fiscale. Premi solo se il cliente ha chiesto la fattura.",
    memberOnly: "Giaogiao Club",
  },
  kitchen: {
    noOrdersToPrepare: "Nessun ordine da preparare",
    completedOrders: "Ordini Completati",
    stock: "Inventario",
  },
  kiosk: {
    touchToOrder: "Tocca per ordinare",
  },
  order: {
    verifyDeliveryAddress: "Verifica la consegna al tuo indirizzo",
    suggestedSpecialRequests: [
      "Meno piccante",
      "Più piccante",
      "Senza coriandolo",
      "Senza carne",
      "Senza verdura",
      "Meno sale",
      "Più sale",
      "Meno zucchero",
      "Più zucchero",
      "Freddo",
      "Tiepido",
      "Caldo",
      "Con ghiaccio",
      "Senza ghiaccio",
      "Con tapioca",
      "Senza tapioca",
      "Meno aceto",
      "Più aceto",
    ],
    paymentDate: "Data del pagamento",
    deliveryDate: "Data della consegna",
    origin: "Punto di ordinazione",
    originMap: {
      0: "Web",
      1: "Kiosk",
      2: "Cassiere",
      3: "QR Tavolo",
      4: "Terze Parti",
    },
    statusMap: {
      0: "Da pagare",
      1: "In preparazione",
      2: "Consegnato",
      3: "In consegna",
      4: "Chiuso",
    },
    paymentMethod: "Metodo di pagamento",
    paymentMethodMap: {
      0: "Contanti",
      1: "Carta",
      2: "Terze parti",
      3: "Wechat Pay",
      4: "Deliveroo",
      5: "Gua Gua",
      6: "Glovo",
      7: "Uber Eats",
      8: "Chiamata",
      9: "Just Eat",
      10: "OTHER",
      11: "Sconosciuto",
    },
    deliveryLocation: "Punto di consegna",
    deliveryLocationMap: {
      0: "Ristorante",
      1: "Take away",
      2: "Consegna a indirizzo",
    },
    order: "Ordine",
    discounts: "Sconti",
    products: "Prodotti",
    restaurant: "Ristorante",
    total: "Prezzo totale",
    uniqueCode: "Codice Univoco",
    noOrdersToDeliver: "Nessun ordine da consegnare",
    lastOrder: "Il tuo ultimo numero d'ordine è",
    stateAlert: "Riceverai il tuo ordine a breve.",
    orderAgain: "Ordina di nuovo",
    receiver: {
      address: "Indirizzo",
      name: "Nome",
      mobilePhone: "Telefono",
    },
    table: "Tavolo",
    tableAbb: "Tav.",
    additions: "Addizione",
    deductions: "Deduzione",
  },
  delivery: {
    checkAddress: "Controlla se la consegna arriva fino al tuo indirizzo",
    checkLater: "Controlla più tardi",
    check: "Controlla",
    addressTooFar:
      "Ci dispiace ma la consegna non arriva fino all'indirizzo indicato",
    addressReachable: "Consegniamo nella tua zona! Puoi ordinare subito",
    checkDifferentAddress: "Controlla un altro indirizzo",
    deliveryToAddress: "Consegna a indirizzo",
    pickupInRestaurant: "Ritiro in ristorante",
    address: "Indirizzo",
    streetNumber: "N. civico",
    postalCode: "CAP",
    fullName: "Nome e Cognome",
    phoneNumber: "Numero di telefono",
    notes: "Note",
    timePlaceholder: "A che ora arriverai?",
    numberOfPeople: "Numero di persone",
    endOfDay: "Ci dispiace ma ora siamo chiusi, scegli un'altra data",
    pickDate: "Scegli una data",
  },
  payment: {
    payNow: "Paga ora",
    paidConfirmation: "Ho pagato",
    notPaid: "Non hai ancora pagato",
  },
  management: {
    generic: {
      daily: "Giornaliero",
      weekly: "Settimanale",
      monthly: "Mensile",
      restaurant: "Ristorante",
      name: "Nome",
      address: "Indirizzo",
      country: "Paese",
      vat: "IVA",
      actions: "Azioni",
      open: "Apri",
      close: "Chiudi",
      configuration: "Configurazione",
      update: "Aggiorna",
      missingValue: "The value is required.",
      invalidValue: "The value is invalid.",
      origin: "Origine",
      amount: "Quantità",
      total: "Totale",
      unitPrice: "Prezzo Unitario",
      dailyStatistics: "Statistica giornaliera",
      revenue: "Ricavo",
      orderOrigin: ["Sito Web", "Kiosk", "Cassa", "Codice QR", "Third Party"],
      export: "Esporta",
      cancel: "Annulla",
      import: "Importa",
      selectAll: "Seleziona tutti",
      eventManagement: "Gestione eventi",
      date: "Data",
      save: "Salva",
      description: "Descrizione",
      categories: "Categorie",
      price: "Prezzo",
      previewImages: "Foto",
      allergens: "Allergeni",
      variations: "Variazioni",
      selectCategory: "Seleziona categoria | Seleziona categorie",
      selectAllergen: "Seleziona allergene | Seleziona allergeni",
      selectVariation: "Seleziona variazione | Seleziona variazioni",
      unit: "Unit",
      index: "Indice",
      active: "Attiva",
      deactivated: "Disattiva",
      preview: "Anteprima",
      deleteAds: "Cancella Annunci",
      mode: "Modalità",
      productTag: "Etichette",
      discount: "Sconti",
    },
    primaryUserNavigation: {
      user: "Utente",
      management: "Gestione",
      analytics: "Analisi",
      productsAnalytics: "Analisi Prodotti",
      orders: "Ordini",
      stock: "Magazzino",
      content: "Contenuto",
      products: "Prodotti",
      media: "Media",
      poster: "Annuncio di apertura",
      categories: "Categorie",
      allergens: "Allergeni",
      variations: "Variazioni",
      tables: "Tavoli",
      events: "Eventi",
      scenes: "Zone",
      fixedMenus: "Menu fissi",
    },
    userTab: {
      username: "Username",
      password: "Password",
      fullName: "Nome completo",
      role: "Ruolo",
      changePassword: "Cambia password",
      oldPassword: "Vecchio password",
      newPassword: "Nuovo password",
      logout: "Log out",
      secure: "Sicurezza",
    },
    analyticsTab: {
      closedOrders: "ordini conclusi",
      unclosedOrders: "ordini in esecuzione",
      revenue: "Reddito",
      totRevenue: "Entrate totali",
      revenueByDay: "Entrate per giorno",
      averageOrders: "Ordini medi",
      averageDailyOrders: "Ordini medi giornalieri",
      averageOrderTotal: "Ordini medi totali",
      averageDailyProfit: "Guadagno medio giornaliero",
      averageDailyRevenue: "Profitto medio giornaliero",
      ordersByDay: "Ordini per giorno",
      ordersByOrigin: "Ordini per origine",
      ordersByPaymentMethod: "Ordini per metodo di pagamento",
      profitByPaymentMethod: "Profitti per metodo di pagamento",
      originChartOptions: JSON.stringify({
        labels: [
          "Sito Web",
          "Kiosk",
          "Cassa",
          "Deliveroo",
          "Guagua",
          "Glovo",
          "Uber Eats",
          "Chiamata",
          "WeChat",
          "Just Eat",
          "Altro",
        ],
      }),
      paymentMethodChartOptions: JSON.stringify({
        labels: [
          "Contante",
          "Carta di credito",
          "Deliveroo",
          "Guagua",
          "Glovo",
          "Uber Eats",
          "Chiamata",
          "WeChat Pay",
          "Just Eat",
          "Altro",
          "Sconosciuto",
        ],
      }),
    },
    restaurantManagementTab: {
      websiteOrder: "Ordine da sito web",
      kioskEnabled: "Kiosk abilitato",
      paymentAfterService: "Pagamento dopo il servizio",
      skipDeliveryState: "Salta fase di consegna",
      skipPreparationState: "Salta fase di preparazione",
      maxDeliveryDistance: "Distanza di consegna massima",
      pointOfWork: "Punto di lavoro",
      customCSS: "CSS personalizzato",
      saveDistance: "Salva distanza",
      cover: "Coperto",
      saveCover: "Salva coperto",
      createEndpoint: "Crea Endpoint",
    },
    restaurantVariationsTab: {
      newVariation: "Nuova variazione",
      noVariations: "Questo ristorante non ha variazioni.",
      active: "Variazioni attive",
      archived: "Variazioni archiviate",
    },
    restaurantTableManagementTab: {
      newTable: "Nuovo Tavolo",
      noTables: "Questo ristorante non ha Tavoli.",
      update: "Modifica",
      remove: "Cancella",
      seats: "Posti (n. persone)",
      name: "Codice",
    },
    restaurantAllergenManagementTab: {
      newAllergen: "Nuovo allergeno",
      noAllergens: "Questo ristorante non ha allergeni.",
    },
    restaurantCategoryManagementTab: {
      newCategory: "Nuova categoria",
      noCategories: "Questo ristorante non ha categorie.",
      modifyActiveCategory: "Gestione ordine categorie.",
    },
    restaurantProductManagementTab: {
      newProduct: "Nuovo prodotto",
      noProducts: "Questo ristorante non ha prodotti.",
      active: "Prodotti attivi",
      archived: "Prodotti archiviati",
      visible: "Visibile",
      indexRule:
        "Più grande il numero, maggiore la priorità, l'ordine predefinita è in fondo della categoria",
      index: "Indice",
      visibility: "VISIBILITA'",
      excludeFromTopSeller: "Esclude dal top seller",
      modify: "MODIFICA",
      archive: "ARCHIVIA",
      unarchive: "RIPRISTINA",
      discount: "SCONTO",
      layout: "Layout",
      layoutTips:
        "Istruzioni: Seleziona il piatto dalla barra di anteprima, usa i valori in alto a destra per vedere i movimenti degli input; nota: i piatti possono essere in più categorie con valori indipendenti, e l'opzione 'Esclude dal top seller' esclude tutte le categorie e l'etichetta 'Popolare'",
    },
    restaurantFixedMenuManagementTab: {
      newFixedMenu: "Nuovo menù fisso",
      noFixedMenus: "Questo ristorante non ha menù fisso.",
      selectFixedMenu: "Seleziona prodotti",
      createChoice: "Nuova pagina",
      removeChoice: "Rimuove",
      selectFixedMenuProducts: "Prodotti menu fissi",
      note: "Fai clic su Nuova Pagina per aggiungere più opzioni di piatti al menu. Fai clic su Rimuove e poi sul numero di pagina per eliminare la pagina, fai di nuovo clic sul Rimuove per uscire dalla modalità di cancellazione. Fai clic sul nome del piatto per aggiungere il piatto alla pagina del menu. Fai di nuovo clic sul nome del piatto per rimuoverlo dalla pagina.",
    },
    restaurantProductsStockManagementTab: {
      availableVolume: "Quantità",
      unavailable: "Non disponibile",
    },
    restaurantOrderListTab: {
      noOrders: "Questo ristorante non ha ordini.",
    },
    restaurantProductsAnalyticsTab: {
      noOrders: "Questo ristorante non ha ordini.",
    },
    restaurantSceneManagementTab: {
      title: "Controllo delle zone",
      list: "Lista",
      newScene: "Nuova zona",
      name: "Nome",
      configuration: "Configurazione",
      category: "Categoria",
      product: "Prodotto",
      fixedMenu: "Menu fisso",
      customProduct: "Prodotto personalizzato",
      table: "Tavolo",
      tableMap: "Mappa tavoli",
      noMaps: "Questa zona non ha mappa.",
      active: "Attiva",
      disable: "Disattiva",
      noScenes: "Questo ristorante non ha zone.",
      noProducts: "Questo ristorante non ha prodotti.",
      noCustomProducts: "Questo ristorante non ha prodotti personalizzati.",
      noFixedMenus: "Questo ristorante non ha menu fissi.",
      modify: "Modifica",
    },
  },
  homepage: {
    home: "Home",
    ourIdea: "La nostra idea",
    loyalty: "Diventa un Membro Fedeltà",
    orderNow: "Ordina ora",
    logIn: "Log in",
    findOutMore: "Scopri di più",
    slogan: "Our products are tailored for you",
    firstSectionTitle: "Modern look. Traditional taste.",
    firstSectionParagraph_1:
      'Il predecessore del giaogiao in via dei Servi era il più antico ristorante cinese di Firenze e anche dell\'intera Toscana, fondato nel 1961 con il nome di "荷香园" e "Fior di Loto".',
    firstSectionParagraph_2:
      "Oggi, 60 anni dopo, giaogiao, un nuovo tipo di cibo cinese, si attiene alle deliziose ricette del cibo tradizionale cinese, migliora il processo di produzione e consegna e utilizza attrezzature e processi più intelligenti per ringiovanire il cibo cinese.",
    firstSectionParagraph_3:
      "La nostra visione è: Portare un nuovo delizioso cibo cinese (intelligente) in tutto il mondo.",
    firstSectionParagraph_4:
      "La nostra missione è: Ridefinire il cibo cinese d'oltremare, potenziare il cibo cinese con la tecnologia e rendere il cibo cinese un vero cibo moderno e conveniente.",
    firstSectionParagraph_5: "Slogan: Ogni città culturale ha giaogiao!",
    secondSectionTitle: "Why is it called giaogiao",
    secondSectionParagraph_1:
      "Vogliamo essere il leader nel cibo cinese d'oltremare!",
    secondSectionParagraph_2:
      "Il comunicatore più giovane di cultura alimentare cinese.",
    secondSectionParagraph_3:
      "L'abbreviazione di Giao deriva dalle seguenti quattro parole inglesi",
    secondSectionParagraph_4: "Good Buono",
    secondSectionParagraph_5: "Innovative Innovativo",
    secondSectionParagraph_6: "Authentic Autentico",
    secondSectionParagraph_7: "Original Originale",
    serviceTimesTitle: "Orari di servizio",
    serviceTimes: "Firenze 11:00 - 21:30 LUN-DOM",
    homeDeliveryTitle: "Consegna a domicilio",
    homeDelivery: "Fino a 5km, ordina adesso per verificare",
    deliveryChargesTitle: "Costi di consegna",
    deliveryCharges: "1€/km",
    contact: "Contatti",
    addressesTitle: "Vieni a trovarci",
    history: "Storia",
    present: "Presente",
    future: "Futuro",
    vision: "Vision",
    restaurantSectionTitle: "+3 RISTORANTI",
    restaurantSectionParagraph: "e molti in arrivo...",
    orderSectionTitle: "+20k ORDINI",
    orderSectionParagraph: "ricevuti tramite il nostro sistema automatico",
  },
  restaurantList: {
    chooseRestaurant: "Scegli un ristorante",
  },
  loyalty: {
    title:
      "Iscriviti ora al Giaogiao Club e ricevi un omaggio di benvenuto alla tua prossima visita!",
    firstName: "Nome (richiesto)",
    lastName: "Cognome (richiesto)",
    phone: "Numero di cellulare (richiesto)",
    email: "Indirizzo email (richiesto)",
    birthday: "Data di nascita (richiesto)",
    subscribe: "Iscriviti",
    read: "Presa visione dell'",
    privacy: "informativa privacy",
    authorize: ", autorizzo al trattamento dei miei dati personali.",
    street: "Indirizzo",
    city: "Città",
    province: "Provincia",
    postalCode: "CAP",
    optional: "Opzionale",
    newsletter:
      "Acconsento a ricevere newsletter e comunicazioni promozionali tramite email e SMS. (Opzionale)",
    thanks:
      "Grazie per essere diventato nostro membro! Siamo entusiasti di averti con noi e non vediamo l'ora di offrirti le nostre esclusive offerte, aggiornamenti e promozioni. A presto!",
    MISSING_STREET: "È richiesto l'indirizzo.",
    MISSING_CITY: "È richiesto la città",
    MISSING_POSTAL_CODE: "È richiesto il CAP.",
    INCORRECT_POSTAL_CODE: "Il CAP deve essere un numero.",
    MISSING_FIRST_NAME: "È richiesto il nome.",
    MISSING_LAST_NAME: "È richiesto il cognome.",
    MISSING_PHONE: "È richiesto il numero di telefono.",
    MISSING_PROVINCE: "È richiesto la provincia.",
    MISSING_EMAIL: "È richiesto l'email.",
    MISSING_BIRTHDAY: "È richiesto la data di nascita.",
    consentEmail:
      "Desidero ricevere offerte promozionali e aggiornamenti via email.",
    consentSMS:
      "Desidero ricevere offerte promozionali e aggiornamenti via SMS.",
    consentPhoneCall:
      "Desidero essere contattato per offerte promozionali e aggiornamenti tramite chiamate telefoniche.",
  },
  categoryTimes: {
    0: "Immediato",
    10: "10 Minuti",
    20: "20 Minuti",
    30: "30 Minuti",
  },
  discountStrategy: {
    onePlusOne: "Offerta 1 + 1",
    percentage: "Percentuale",
    fixed: "Fisso",
  },
  lang: {
    it: "Italiano",
    zh: "Cinese",
    en: "Inglese",
  },
  endpoint: {
    cashier: "Cassa",
    kiosk: "Kiosk",
    kitchen: "Cucina",
    waiter: "Cameriere",
  },
  host: {
    logout: "Logout",
    access: "Accesso",
    development: "Modalita' Sviluppo",
    fullScreen: "Schermo Intero",
    clearCache: "Cancella Cache",
    exit: "Esce",
    checkUpdate: "Controlla Aggiornamenti",
    start: "Avvia",
    openLogs: "Apre Logs",
    getConfiguration: "Carica configurazione",
    selectDirectory: "Seleziona Axon Printer Directory",
  },
  productTag: {
    new: "Novità(1)",
    discount: "Sconto(2)",
  },
  memberOnly: {
    promoCode: "Codice Promozionale",
    checkInfo: "Controlla Dati Clienti",
    updateInfo: "Aggiorna Dati Clienti",
    check: "Controlla",
    use: "Applica (Non revocabile)",
    invalid: "Il codice promozionale non è più attivo.",
    expired: "Il codice promozionale è scaduto.",
    description: "Descrizione",
    discount: "Sconto",
    notApplicable: "Non applicabile",
    validUntil: "Valido fino al",
  },
  restaurantClosed: {
    title: "Il ristorante è attualmente chiuso",
    description: "Ci scusiamo per il disagio. Torna a trovarci durante gli orari di apertura.",
  }
};
