<template>
    <div class="flex flex--100 restaurant-list-view">
        <language-selector/>
        <home-navigation :selected="2"/>
        <div class="flex flex--100 choose-a-restaraunt">
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex title choose-restaurant-text">{{$t("restaurantList.chooseRestaurant")}}</h2>
            </div>
        </div>
        <div class="flex flex--100 flex--y-align-center flex--x-align-around restaurant-list">
            <div class="restaurant-box" @click="toOrderView(15)">
                <div class="flex flex--100 flex--x-align-center">
                    <primary-logo/>
                </div>
                <h2 class="title">GiaoGiao Novoli &mdash; Firenze<span class="new-restaurant-label">NEW</span></h2>
                <p class="paragraph">Via Nicola Tagliaferri, 16/A, 50127, Firenze (FI)</p>
            </div>
            <div class="restaurant-box" @click="toOrderView(1)">
                <div class="flex flex--100 flex--x-align-center">
                    <primary-logo/>
                </div>
                <h2 class="title">GiaoGiao Duomo &mdash; Firenze</h2>
                <p class="paragraph">Via dei Servi, 35R, 50122, Firenze (FI)</p>
            </div>
            <div class="restaurant-box" @click="toOrderView(5)">
                <div class="flex flex--100 flex--x-align-center">
                    <primary-logo/>
                </div>
                <h2 class="title">GiaoGiao Stazione &mdash; Firenze</h2>
                <p class="paragraph">Via Panzani, 44R, 50123, Firenze (FI)</p>
            </div>
            <div class="restaurant-box" @click="toOrderView(3)">
                <div class="flex flex--100 flex--x-align-center">
                    <primary-logo/>
                </div>
                <h2 class="title">GiaoGiao Bocconi &mdash; Milano</h2>
                <p class="paragraph">Viale Bligny, 45, 20136, Milano (MI)</p>
            </div>
        </div>
        <footer-navigation/>
    </div>
</template>

<script>
import FooterNavigation from "@/components/navigation/FooterNavigation";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import HomeNavigation from "@/components/navigation/HomeNavigation";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { isMobile, } from "@/main";

export default {
    name: "RestaurantListView",
    components: {
        PrimaryLogo,
        HomeNavigation,
        LanguageSelector,
        FooterNavigation
    },
    methods: {
        toOrderView (restaurantId) {
            if (isMobile || window.innerWidth <= 800) {
                this.$router.push({ path: `/restaurant/${restaurantId}/mobile/order`, });
            }
            else {
                this.$router.push({ path: `/restaurant/${restaurantId}/order`, });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.paragraph {
    margin-top: 28px;

    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.07rem;
}

.title {
    margin: 0 auto;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.07rem;
}

.restaurant-list-view {
    :deep(.language-selector) {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 100000;
        background-color: rgba(255, 255, 255, 0.08);

        .language {
            margin: 0;
            background-color: transparent;

            &__name {
                font-size: 14px;
                font-weight: 400;
            }
            &__icon {
                width: 18px;
            }
            &:hover {
                .language__name {

                }
            }
            &--selected {
                .language__name {
                    color: $primary-brand-color;
                    font-weight: 600;
                }
            }
        }
    }
}

.restaurant-list {
    padding: 30px;
    min-height: 80vh;
    background-color: rgb(0, 0, 0);

    background-image: url("https://giaogiao.it:3000/images/internal/TRY.jpg");
    background-size: cover;

    .restaurant-box {
        will-change: transform;
        transition: transform 300ms;

        margin: 30px;

        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
        background-color: $primary-brand-color;
        border-radius: 20px;
        padding: 30px;

        .primary-logo {
            width: 100px;
            margin: 0 auto 20px auto;
        }

        &:hover {
            transform: scale(1.12);
        }
        .paragraph {
            font-size: 20px;
        }
        .title {
            font-size: 30px;
        }
    }
}

.choose-restaurant-text {
    margin: 40px auto;
    color: rgb(0, 0, 0);
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-style: oblique;
}

.new-restaurant-label {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px 3px;
    background-color: red;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 4px;

    @media (max-width: 448px) {
        top: 15px;
        right: 15px;
    }
}
</style>
