<template>
    <div class="flex flex--100 add-table-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-table-form">
                <div class="flex flex--100">
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.restaurantTableManagementTab.seats')" v-model:model-value="seats" ref="enSeatsTextbox"/>
                    </div>
                    <hr class="separator"/>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.restaurantTableManagementTab.name')" v-model:model-value="name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.update')" @click="updateTable" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/

export default {
    name: "UpdateTableModalBox",
    props: {
        table: {
            type: Object,
        },
        closeOnTableUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "table-update" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            seats: "",
            name: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateUpdateTableForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearTableForm () {
            this.seats = "";
            this.name = "";
        },

        load () {
            this.seats = String(this.table.seats);
            this.name = this.table.name;
        },

        async updateTable () {
            if (!this.validateUpdateTableForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                await user.updateTable({
                    id: this.table.id,
                    seats: this.seats,
                    name: this.name,
                });

                this.$emit("table-update", { 
                    id: this.table.id,
                    seats: this.seats,
                    name: this.name,
                    restaurantId: this.restaurantId,
                });

                if (this.closeOnTableUpdate) {
                    this.$refs.window.hide();
                }

                this.clearTableForm();
                notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.tableUpdatedSuccess`), });
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-table-window {}

.add-table-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-table-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-table-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
</style>
