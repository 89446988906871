<template>
    <div class="flex flex--100 user-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.primaryUserNavigation.user`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.userTab.username`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-textbox v-model:model-value="username" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.userTab.fullName`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-textbox v-model:model-value="fullName" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.userTab.role`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-textbox v-model:model-value="roleType" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.actions`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <simple-button :text="$t(`management.userTab.logout`)" @click="logout"/>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.userTab.secure`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.userTab.oldPassword`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-textbox ref="oldPassword" v-model:model-value="oldPassword" :is-password="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.userTab.newPassword`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-textbox ref="newPassword" v-model:model-value="newPassword" :is-password="true" class="item__value"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.actions`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <simple-button
                    :text="$t(`management.userTab.changePassword`).toLocaleUpperCase()"
                    :is-loading="isWaitingServerResponse"
                    :is-disabled="newPassword.length < 3 || oldPassword.length === 0"
                    @click="changePassword"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { user } from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";

export default {
    name: "UserTab",
    components: {
        SimpleButton,
        SimpleTextbox,
    },
    data () {
        return {
            username: user.descriptor.username,
            fullName: user.descriptor.fullName,
            roleType: "Administrator",
            oldPassword: "",
            newPassword: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        logout () {
            user.logout();
            this.$router.push("/login");
        },

        async changePassword () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await user.changePassword(this.username, this.oldPassword, this.newPassword);
                if (response.status === "ok") {
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.passwordChangeSuccess`), });
                }
                else {
                    this.$refs.oldPassword.validate({
                        id: 1,
                        text: this.$t(`error.wrongPassword`),
                    }, false);
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.passwordChangeFail`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
    },
    watch: {
        oldPassword () {
            this.$refs.oldPassword.clearErrors();
        },
    }
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";
</style>
