<template>
    <div class="flex flex--100 order-status-view">
        <language-selector />
        <div class="flex flex--100 flex--y-align-center header" :class="getHeaderModifiers">
            <div class="flex flex--100 flex--x-align-center">
                <primary-logo :class="getPrimaryLogoModifiers"/>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-show="!isUserScrolling">
                <p class="description">
                    {{
                    $t("order.stateAlert")
                    }}
                </p>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center main" :class="getMainBoxModifiers" ref="mainBox">
            <template v-for="order, index in orders" :key="order.id">
                <div class="flex flex--100 order">
                    <restaurant-order :restaurant-presentation="restaurantPresentation" :order="order" :compact-view="false"
                        :show-price="false"/>
                </div>
                <hr v-if="index !== orders.length-1" class="separator">
            </template>
        </div>
        <div class="flex flex--100 btn-order">
            <simple-button @click="placeOrder" :text="$t(`order.orderAgain`)"></simple-button>
        </div>
    </div>
</template>

<script>
import RestaurantOrder from "@/components/RestaurantOrder";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { server } from "@/server";
import SimpleButton from '@/components/inputs/SimpleButton.vue';
import { isMobile } from "@/main";
import { MobileViewportSimulator, getLatestOrders, } from "@/utilities";
import debounce from "lodash/debounce";
import LanguageSelector from "@/components/inputs/LanguageSelector";
const preloadedData = {};

async function getOrders (orderIds) {
    return server.getOrders({ ids: orderIds, });
}

let insideRestaurant = false;
let tableNumber = null;

export default {
    async beforeRouteEnter (to, from, next) {
        const latestOrders = getLatestOrders();
        if (!latestOrders) {
            let redirect = `/restaurant/${to.params.restaurantId}/mobile/order?fromHome=${to.query.fromHome}&insideRestaurant=${to.query.insideRestaurant}`;
            if (to.query.tableNumber) {
                redirect += `&tableNumber=${to.query.tableNumber}`;
            }
            next(redirect);
        }
        preloadedData.orders = await getOrders(latestOrders.map((o) => (o.id)));
        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId || to.query.restaurantId);
        if (to.query.insideRestaurant === "true") {
            insideRestaurant = true;
        }

        if (to.query.tableNumber) {
            tableNumber = to.query.tableNumber;
        }
        next();
    },

    components: {
        RestaurantOrder,
        PrimaryLogo,
        SimpleButton,
        LanguageSelector,
    },
    name: "MobileUserOrdersView",
    data () {
        return {
            isUserScrolling: false,
        };
    },
    methods: {
        async placeOrder() {
            const path = isMobile ? `/restaurant/${this.restaurantPresentation.restaurant.id}/mobile/order` : `/restaurant/${this.restaurantPresentation.restaurant.id}/order`;
            const query = {
                fromHome: "true",
                insideRestaurant: insideRestaurant ? "true" : "false",
            };
            if (tableNumber) {
                query.tableNumber = tableNumber;
            }
            this.$router.push({ path, query: isMobile ? query : undefined });
        },
        handleScroll () {
            this.isUserScrolling = this.$refs.mainBox.scrollTop > 10;
        },
    },
    computed: {
        orders () {
            return preloadedData.orders;
        },

        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        getMainBoxModifiers () {
            return {
                "main-scrolling": this.isUserScrolling,
            };
        },

        getHeaderModifiers () {
            return {
                "header-resize": this.isUserScrolling,
            };
        },

        getPrimaryLogoModifiers () {
            return {
                "primary-logo-resize": this.isUserScrolling,
            };
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
        const debouncedScroll = debounce(this.handleScroll, 10);
        this.$refs.mainBox.addEventListener("scroll", debouncedScroll);
    },
    beforeUnmounted () {
        const debouncedScroll = debounce(this.handleScroll, 10);
        this.$refs.mainBox.removeEventListener("scroll", debouncedScroll);
    },
};
</script>

<style lang="scss" scoped>
.order-status-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(243, 243, 243);
}

.header {
    padding: 60px 40px 40px 40px;
    background-color: rgb(66, 66, 66);
    height: 30vh;
    height: calc(var(--vh, 1vh) * 30);
    transition: height 300ms ease-out;
}

.header-resize {
    padding: 20px 13px 13px 13px;
    height: 10vh!important;
    height: calc(var(--vh, 1vh) * 10)!important;
}

.header--in-preparation {
    background-color: rgb(234, 130, 48);
    background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
}
.header--completed {
    background-color: rgb(35, 187, 49);
    background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
}

.main {
    height: 62vh;
    height: calc(var(--vh, 1vh) * 62);
    padding-bottom: 8vh;
    padding-bottom: calc(var(--vh, 1vh) * 8);
    transition: height 300ms ease-out;
    overflow: auto;
}

.main-scrolling {
    height: 82vh!important;
    height: calc(var(--vh, 1vh) * 82)!important;
}

.primary-logo {
    width: 128px;
    height: 128px;
    margin: 0 20px 20px 20px;

    fill: rgb(255, 255, 255);
    transition: height 300ms width 300ms ease-out;
}

.primary-logo-resize {
    width: 32px;
    height: 32px;
    margin: 0 5px 5px 5px;
}

.title {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.08rem;
    color: rgb(255, 255, 255);
}

.description {
    margin: 4px 20px 0 20px;

    font-size: 18px;
    color: rgb(255, 255, 255);
}

.separator {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    padding: 0;
    background-color: rgb(33, 33, 33);
}

.order {
    margin: 10px 0;

    &__title {
        margin-bottom: 24px;

        font-size: 22px;
        font-weight: 800;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.product {
    & + & {
        margin-top: 10px;
    }

    &__name {
        font-size: 18px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
    &__status {
        padding: 10px 18px;

        border-radius: 1000px;

        font-size: 16px;
        font-weight: 600;
        color: rgb(255, 255, 255);
    }
    &__status--in-preparation {
        background-color: rgb(234, 130, 48);
        background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
    }
    &__status--completed {
        background-color: rgb(35, 187, 49);
        background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
    }
}

:deep(.restaurant-order) {
    background: transparent;
    justify-content: center;
    .restaurant-order__box {
        max-width: 650px;
        background: transparent;
        border: none;
        box-shadow: none;
        .restaurant-name {
            display: none;
        }

        .date {
            .restaurant-order__value {
                text-align: end;
            }
        }

        .restaurant-order__expanded {
            overflow: auto;
            height: auto;

            .unique-id {
                display: none;
            }

            .origin {
                display: none;
            }

            .payment-method {
                display: none;
            }

            .payment-date {
                display: none;
            }

            .delivery-address {
                display: none;
            }

            .delivery-date {
                display: none;
            }

            span.product-name {
                padding-right: 25px;
            }

            span.fixed-menu-product {
                padding-right: 25px;
            }
        }
    }
}

.btn-order {
    height: 8vh;
    height: calc(var(--vh, 1vh) * 8);
    position: fixed;
    bottom: 0;
    justify-content: center;
    .simple-button {
        border-radius: 0;
        width: 100vw;
        height: 100%;
    }
}

.language-selector {
    position: absolute;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: 12px;

    z-index: 10;

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
        padding: 6px 10px;
    }

    :deep(.language.language--selected) {
        background-color: transparent;
    }

    :deep(.language .language__name) {
        display: none;
    }

    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }

    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }

    :deep(.language+.language) {
        margin: 0;
    }
}
</style>
