<template>
    <div class="flex flex--100 restaurant-table-management">
        <modal-box ref="modalBox" :show-close-button="false" :close-on-background-click="true" :show-back-button="true">
            <vue-easy-lightbox
                class="lightbox"
                :visible="lightboxVisible"
                :imgs="sceneMapImages"
                :index="selectedIndex"
                :move-disabled="true"
                @hide="() => lightboxVisible = false"
            ></vue-easy-lightbox>
            <div class="flex flex--100 restaurant-table-management__box2">
                <div class="flex flex--100 slider">
                    <splide :options="splideOptions" :extensions="splideExtensions">
                        <splide-slide v-for="table in restaurantTables" :key="table.id">
                            <restaurant-table
                                :table="table"
                                :is-mobile="isMobile"
                                :show-options="true"
                                :show-orders="true"
                                :show-price="true"
                                :payment-after-service="paymentAfterService"
                                :restaurant-presentation="restaurantPresentation"
                                @to-pay="toPay"
                                @bind-order="showOrderInputModalBox"
                                @add-product="addProduct"
                                @free="freeTable"
                                @select="select"
                                @table-on-click="tableOnClick"
                            />
                        </splide-slide>
                    </splide>
                </div>
            </div>
            <div class="flex flex--100 restaurant-table-management__box1">
                <div class="flex flex--100 flex--x-align-center">
                    <simple-button :text="$t('cashierPos.maps')"
                        @click="showLightbox(0)" ></simple-button>
                </div>
            </div>
            <modal-box
                class="order-input"
                ref="orderInputModalBox"
                :show-close-button="false"
                :close-on-background-click="true"
                :show-back-button="true"
                :append-to-body="true"
                @before-hide="clear"
            >
                <div class="flex flex--100 flex--y-align-center order-input">
                    <div class="flex flex--70">
                        <simple-textbox class="flex flex--100" :placeholder="$t('cashierPos.orderId')" v-model:model-value="orderId"></simple-textbox>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button :text="$t('cashierPos.bindOrder')" @click="bindOrder"></simple-button>
                    </div>
                </div>
            </modal-box>
            <modal-box class="order-list-modal-box" ref="activeOrderListModalBox">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                    <h2 class="order-list-title__text">{{ $t("cashierPos.activeOrders") }}</h2>
                </div>
                <div v-if="selectedTable" class="flex flex--100 flex--x-align-center order-list">
                    <template  v-for="order in pasTables" :key="order">
                        <simple-button
                        :text="order.id.toString()"
                        @click="selectOrder(selectedTable.id, order.id)"
                        />
                        <span>{{ order }}</span>
                    </template>
                </div>
            </modal-box>
        </modal-box>
    </div>
</template>

<script>
import {RestaurantProcessor} from "@/utilities";
import ModalBox from "@/components/containers/ModalBox";
import { Grid } from "@splidejs/splide-extension-grid";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import RestaurantTable from "@/components/RestaurantTable";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { server } from "@/server";
import VueEasyLightbox from 'vue-easy-lightbox'
import { isMobile, } from '@/main';

export default {
    name: "SceneMapTableManagement",
    components: {
        ModalBox,
        Splide,
        SplideSlide,
        RestaurantTable,
        SimpleTextbox,
        SimpleButton,
        VueEasyLightbox,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        scenes: {
            type: Array,
        },
        scene: {
            type: Object,
        },
        tables: {
            type: Array,
        }
    },
    emits: [
        "table-update",
        "to-pay",
        "table-add-product",
        "table-update",
        "table-select",
        "table-on-click",
    ],
    data () {
        return {
            isMobile,
            showSceneMap: false,
            orderId: "",
            selectedSceneValue: null,
            selectedTable: null,
            selectedIndex: 0,
            lightboxVisible: false,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        // </restaurant>
        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: false,
                pagination: false,
                drag: true,
                trimSpace: "move",
                padding: "28px",
                grid: {
                    dimensions: this.splideDimensions,
                },
            };
        },

        splideExtensions () {
            return {
                Grid,
            };
        },

        splideDimensions () {
            if (this.isMobile) {
                return [ [ 6, 1, ], [ 6, 1, ], ];
            }
            else {
                return [ [ 2, 4, ], [ 2, 4, ], ];
            }
        },

        restaurantTables () {
            const selectedScenes = this.selected ? [ this.selected, ] : this.scenes;
            const activeTableIds = selectedScenes.flatMap((ss) => ss.maps).flatMap((m) => m.tables);
            return this.tables.filter((table) => activeTableIds.includes(table.id));
        },

        pasTables () {
            return this.selectedTable.orders.filter((o) => o.paymentMethod === null);
        },

        paymentAfterService () {
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        },

        sceneMaps () {
            const selectedScenes = this.selected ? [this.selected,] : this.scenes;
            return selectedScenes.flatMap((ss) => ss.maps);
        },

        sceneMapImages () {
            return this.sceneMaps.map((map) => map.map);
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedSceneValue));
        }
    },
    methods: {
        async load () {
            this.selectedSceneValue = String(this.scene ? this.scene.id : "all");
        },

        async bindOrder () {
            try {
                const order = await server.updateOrder({
                    id: this.orderId,
                    tables: [this.selectedTable.id],
                });
                if (order) {
                    this.$refs.orderInputModalBox.hide();
                    this.clear();
                    this.$emit("table-update");
                }
            }
            catch (error) {
                console.log(error);
            }
        },

        toPay (orderId) {
            this.$emit("to-pay", orderId);
        },

        addProduct (table) {
            this.selectedTable = table;
            this.$refs.activeOrderListModalBox.show();
        },

        showOrderInputModalBox (table) {
            this.selectedTable = table;
            this.$refs.orderInputModalBox.show();
        },

        selectOrder (tableId, orderId) {
            this.$emit("table-add-product", tableId, orderId);
            this.$refs.activeOrderListModalBox.hide();
        },

        async freeTable (table) {
            try {
                await server.freeTable({
                    id: table.id,
                });
                this.$refs.orderInputModalBox.hide();
                this.$emit("table-update");
            }
            catch (error) {
                console.log(error);
            }
        },

        select (table) {
            this.$emit("table-select", table);
        },

        clear () {
            this.selectedTable = null;
            this.orderId = "";
        },

        show () {
            this.$refs.modalBox.show();
        },

        hide () {
            this.$refs.modalBox.hide();
        },

        tableOnClick (table) {
            this.$emit("table-on-click", table);
        },

        showLightbox (index) {
            this.selectedIndex = index;
            this.lightboxVisible = true;
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedSceneValue || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
}
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-table-management {
    &__title {
        overflow: hidden;

        height: 15%;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &__text {
            margin: 50px 0;

            font-size: 32px;
            font-weight: 600;
        }
    }

    &__box1 {
        height: 10%;
        background-color: rgb(240, 240, 240);
    }

    &__box2 {
        height: 90%;
        background-color: rgb(240, 240, 240);
    }
}

.modal-box {
    :deep(&__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.slider {
    margin: 20px 0;
    padding: 0;

    z-index: 2;
}

.splide {
    margin: 0;
    padding: 0;

    width: 100%;
}

.splide-list {
    width: 500px!important;
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);

    &--to-pay {
        :deep(.restaurant-order .name) {
            display: none;
        }
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}

.scene-map-container {
    margin-top: 10px;
    overflow: auto;
    justify-content: center;
    align-items:center;

    &_img {
        @extend .--unselectable;

        width: 100px;
        height: 100px;

        object-fit: contain;
        border-radius: 6px;
    }
}

.lightbox {
    z-index: 99999;
}

.scene-select-box {
    min-width: 174px;
    margin-right: 20px;
}
</style>
