<template>
    <ul class="flex flex--100 error-list" v-show="!isValid">
        <li class="flex flex--100 error-list__item" v-for="error in errors" :key="error.id">{{ error.text }}</li>
    </ul>
</template>

<script>
export default {
    name: "ErrorList",
    data () {
        return {
            errors: [],
        };
    },
    methods: {
        addError (error) {
            if (!this.hasError(error)) {
                this.errors.push(error);
            }
        },

        getErrorIndexById (id) {
            for (let i = 0; i < this.errors.length; ++i) {
                const error = this.errors[i];

                if (error.id === id) {
                    return i;
                }
            }

            return -1;
        },

        hasError (error) {
            return this.getErrorIndexById(error.id) !== -1;
        },

        removeError (error) {
            const i = this.getErrorIndexById(error.id);

            if (i !== -1) {
                this.errors.splice(i, 1);
            }
        },

        clear () {
            this.errors = [];
        },

        validate (error, condition) {
            if (!condition) {
                this.addError(error);
            }
            else {
                this.removeError(error);
            }

            return condition;
        },
    },
    computed: {
        isValid () {
            return this.errors.length === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.error-list {
    margin: 0;
    padding: 20px;
    background-color: rgb(255, 242, 244);
    border-radius: 12px;
    list-style-type: none;

    &__item {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        color: rgb(227, 50, 50);
    }
}
.error-list__item + .error-list__item {
    margin-top: 5px;
}
</style>
