<template>
    <div class="flex restaurant-table" :class="getModifiers()" @click="onClick">
        <div v-if="showManagementOptions" class="flex flex--100 flex--x-align-end cashier-control-box">
            <simple-button :text="$t('management.restaurantTableManagementTab.update').toUpperCase()" @click="update"></simple-button>
            <simple-button :text="$t('management.restaurantTableManagementTab.remove').toUpperCase()" @click="remove"></simple-button>
            <update-table-modal-box ref="updateTableModalBox" :table="table" @table-update="onTableUpdate"></update-table-modal-box>
        </div>
        <div v-if="showOptions" class="flex flex--100 flex--x-align-end cashier-control-box">
            <simple-button :text="$t('cashierPos.bindOrder').toUpperCase()" @click="bindOrder"></simple-button>
            <simple-button v-if="paymentAfterService && busy" :text="$t('cashierPos.addProduct').toUpperCase()" @click="addProduct"></simple-button>
            <simple-button v-if="paymentAfterService && busy" :text="$t('cashierPos.toPay').toUpperCase()" @click="toPay"></simple-button>
            <simple-button v-if="!busy" :text="$t('cashierPos.select')" @click="select"/>
            <simple-button :text="$t('cashierPos.freeTable').toUpperCase()" @click="freeTable"></simple-button>
        </div>
        <div class="flex flex--100 restaurant-table__box" >
            <div class="flex flex--50">
                <span class="flex restaurant-table__id" :class="getTextModifiers()">#{{table.name}}</span>
            </div>
            <div class="flex flex--50 flex--x-align-end">
                <span class="restaurant-table__seats" :class="getTextModifiers()">{{table.seats}}</span>
                <svg class="restaurant-table__icon" :class="getIconModifiers()" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                    </path>
                </svg>
            </div>
            <template v-if="showOrders">
                <div class="flex flex--100">
                    <div class="flex flex--100" v-show="orderProducts.length > 0">
                        <span class="restaurant-order__key">{{ $t("order.products") }}</span>
                    </div>
                    <template v-if="orderProductsInPreparation.length > 0">
                        <div class="flex flex--100">
                            <ul class="flex flex--100 item-list">
                                <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in orderProductsInPreparation"
                                    :key="orderProduct.id">
                                    <!-- <fixed-menu-list> -->
                                    <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                            :key="menu.id">
                                            <div class="flex flex--100 flex--y-align-center">
                                                <div class="flex flex--70">
                                                    <span class="product-name">
                                                        x1 {{
                                                        restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                        }}

                                                        {{ showPrice ? `(€
                                                        ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                        + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                        i))})` : '' }}
                                                    </span>
                                                </div>
                                                <div class="flex flex--30 flex--x-align-end">
                                                    <span class="product-status product-status--in-preparation">
                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products" :key="product.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="fixed-menu-product">
                                                                x{{ product.requestedVolume }} {{
                                                                restaurantProcessor.getProductLocalizedName(product.id)
                                                                }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status"
                                                                :class="getOrderProductStatusModifiers(product)">
                                                                <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                    {{ $t("order.statusMap.0").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.completedVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <fixed-menu-list> -->
                                    <!-- <custom-product-list> -->
                                    <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                            <div class="flex flex--70">
                                                <span class="product-name">
                                                    x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                </span>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--in-preparation">
                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                </span>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div
                                                    class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products"
                                                    :key="product.id"
                                                >
                                                    <div class="flex flex--70">
                                                        <span class="fixed-menu-product">
                                                            x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                            (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                            <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                {{ $t("order.statusMap.0").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.2").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.3").toUpperCase() }}
                                                            </template>
                                                            <template v-else>
                                                                {{ $t("order.statusMap.1").toUpperCase() }}
                                                            </template>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <custom-product-list> -->
                                    <!-- <base-product-list> -->
                                    <template v-else-if="orderProduct.displayVolume > 0">
                                        <div class="flex flex--100 flex--y-align-center">
                                            <div class="flex flex--70">
                                                <span class="product-name"
                                                    v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                    x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    {{ showPrice ? `(€
                                                    ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                    restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                    }}
                                                </span>
                                                <template v-for="(item, i) in orderProduct.requestedVolume">
                                                    <div class="product-name" :key="i"
                                                        v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                        <span class="product-name">
                                                            x1 {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                            }}
                                                            {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                            i)
                                                            }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                            + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                            i))})` : '' }}
                                                        </span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--in-preparation">
                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- </base-product-list> -->
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-if="orderProductsInDelivery.length > 0">
                        <div class="flex flex--100">
                            <ul class="flex flex--100 item-list">
                                <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in orderProductsInDelivery"
                                    :key="orderProduct.id">
                                    <!-- <fixed-menu-list> -->
                                    <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                            :key="menu.id">
                                            <div class="flex flex--100 flex--y-align-center">
                                                <div class="flex flex--70">
                                                    <span class="product-name">
                                                        x1 {{
                                                        restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                        }}

                                                        {{ showPrice ? `(€
                                                        ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                        + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                        i))})` : '' }}
                                                    </span>
                                                </div>
                                                <div class="flex flex--30 flex--x-align-end">
                                                    <span class="product-status product-status--in-delivery">
                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                </span>
                                                </div>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products" :key="product.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="fixed-menu-product">
                                                                x{{ product.requestedVolume }} {{
                                                                restaurantProcessor.getProductLocalizedName(product.id)
                                                                }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status"
                                                                :class="getOrderProductStatusModifiers(product)">
                                                                <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                    {{ $t("order.statusMap.0").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.completedVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <fixed-menu-list> -->
                                    <!-- <custom-product-list> -->
                                    <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                            <div class="flex flex--70">
                                                <span class="product-name">
                                                    x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                </span>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--in-delivery">
                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                </span>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div
                                                    class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products"
                                                    :key="product.id"
                                                >
                                                    <div class="flex flex--70">
                                                        <span class="fixed-menu-product">
                                                            x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                            (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                            <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                {{ $t("order.statusMap.0").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.2").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.3").toUpperCase() }}
                                                            </template>
                                                            <template v-else>
                                                                {{ $t("order.statusMap.1").toUpperCase() }}
                                                            </template>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <custom-product-list> -->
                                    <!-- <base-product-list> -->
                                    <template v-else-if="orderProduct.displayVolume > 0">
                                        <div class="flex flex--100 flex--y-align-center">
                                            <div class="flex flex--70">
                                                <span class="product-name"
                                                    v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                    x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    {{ showPrice ? `(€
                                                    ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                    restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                    }}
                                                </span>
                                                <template v-for="(item, i) in orderProduct.requestedVolume">
                                                    <div class="product-name" :key="i"
                                                        v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                        <span class="product-name">
                                                            x1 {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                            }}
                                                            {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                            i)
                                                            }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                            + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                            i))})` : '' }}
                                                        </span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--in-delivery">
                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- </base-product-list> -->
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-if="orderProductsDelivered.length > 0">
                        <div class="flex flex--100">
                            <ul class="flex flex--100 item-list">
                                <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in orderProductsDelivered"
                                    :key="orderProduct.id">
                                    <!-- <fixed-menu-list> -->
                                    <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                            :key="menu.id">
                                            <div class="flex flex--100 flex--y-align-center">
                                                <div class="flex flex--70">
                                                    <span class="product-name">
                                                        x1 {{
                                                        restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                        }}

                                                        {{ showPrice ? `(€
                                                        ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                        + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                        i))})` : '' }}
                                                    </span>
                                                </div>
                                                <div class="flex flex--30 flex--x-align-end">
                                                    <span class="product-status product-status--delivered">
                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products" :key="product.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="fixed-menu-product">
                                                                x{{ product.requestedVolume }} {{
                                                                restaurantProcessor.getProductLocalizedName(product.id)
                                                                }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status"
                                                                :class="getOrderProductStatusModifiers(product)">
                                                                <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                    {{ $t("order.statusMap.0").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                                </template>
                                                                <template
                                                                    v-else-if="product.completedVolume === product.requestedVolume">
                                                                    {{ $t("order.statusMap.3").toUpperCase() }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ $t("order.statusMap.1").toUpperCase() }}
                                                                </template>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <fixed-menu-list> -->
                                    <!-- <custom-product-list> -->
                                    <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                        <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                            <div class="flex flex--70">
                                                <span class="product-name">
                                                    x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                </span>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--delivered">
                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                </span>
                                            </div>
                                            <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                <div
                                                    class="flex flex--100 fixed-menu-product"
                                                    v-for="product in menu.products"
                                                    :key="product.id"
                                                >
                                                    <div class="flex flex--70">
                                                        <span class="fixed-menu-product">
                                                            x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                            (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                            <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                {{ $t("order.statusMap.0").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.2").toUpperCase() }}
                                                            </template>
                                                            <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                {{ $t("order.statusMap.3").toUpperCase() }}
                                                            </template>
                                                            <template v-else>
                                                                {{ $t("order.statusMap.1").toUpperCase() }}
                                                            </template>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <custom-product-list> -->
                                    <!-- <base-product-list> -->
                                    <template v-else-if="orderProduct.displayVolume > 0">
                                        <div class="flex flex--100 flex--y-align-center">
                                            <div class="flex flex--70">
                                                <span class="product-name"
                                                    v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                    x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                    {{ showPrice ? `(€
                                                    ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                    restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                    }}
                                                </span>
                                                <template v-for="(item, i) in orderProduct.requestedVolume">
                                                    <div class="product-name" :key="i"
                                                        v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                        <span class="product-name">
                                                            x1 {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                            }}
                                                            {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                            i)
                                                            }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                            + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                            i))})` : '' }}
                                                        </span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="flex flex--30 flex--x-align-end">
                                                <span class="product-status product-status--delivered">
                                                    {{ $t("order.statusMap.2").toUpperCase() }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- </base-product-list> -->
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import UpdateTableModalBox from "@/components/windows/UpdateTableModalBox";
import { OrderStatus, } from "@/orders/RestaurantOrder";
import { RestaurantProcessor, } from "@/utilities";

export default {
    name: "RestaurantTable",
    components: {
        SimpleButton,
        UpdateTableModalBox,
    },
    props: {
        restaurantPresentation: {
            type: Object,
            required: true,
        },
        table: {
            type: Object,
        },
        showOptions: {
            type: Boolean,
        },
        showManagementOptions: {
            type: Boolean,
        },
        showOrders: {
            type: Boolean,
        },
        isMobile: {
            type: Boolean,
        },
        paymentAfterService: {
            type: Number,
        },
        showPrice: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        "bind-order",
        "to-pay",
        "select",
        "add-product",
        "free",
        "table-on-click",
        "table-update",
        "table-delete",
    ],
    data () {
        return {
            OrderStatus,
        };
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurant () {
            return this.restaurantProcessor.restaurant;
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },
        // </restaurant>

        busy () {
            return this.table.orders.filter((o) => o.paymentMethod === null).length > 0;
        },

        orderProducts () {
            const orderProducts = this.table.orders.reduce((arr, o) => {
                for (const pp of o.products) {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                    }
                    else {
                        pp.status = o.status;
                        arr.push({ ...pp, });
                        console.log(arr);
                    }
                }
                return arr;
            }, []);
            return orderProducts;
        },

        orderProductsInPreparation () {
            const orderProducts = this.table.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.requestedVolume - pp.completedVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                    }
                    else {
                        if (pp.completedVolume < pp.requestedVolume) {
                            arr.push({ ...pp, displayVolume: pp.requestedVolume  - pp.completedVolume, });
                        }
                    }
                });
                return arr;
            }, []);

            return orderProducts;
        },

        orderProductsInDelivery () {
            const orderProducts = this.table.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.completedVolume - pp.deliveredVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                        arr.push({ ...pp, });
                    }
                    else {
                        if (pp.completedVolume != pp.deliveredVolume) {
                            arr.push({ ...pp, displayVolume: pp.completedVolume - pp.deliveredVolume,});
                        }
                    }
                });
                return arr;
            }, []);
            return orderProducts;
        },

        orderProductsDelivered () {
            const orderProducts = this.table.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.deliveredVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                        arr.push({ ...pp });
                    }
                    else {
                        if (pp.deliveredVolume > 0) {
                            arr.push({ ...pp, displayVolume: pp.deliveredVolume, });
                        }
                    }
                });
                return arr;
            }, []);
            return orderProducts;
        },
    },
    methods: {
        getModifiers () {
            return {
                "restaurant-table--mobile": this.isMobile,
                "restaurant-table--cashier": !this.isMobile,
                "restaurant-table__busy": this.busy,
            };
        },
        getTextModifiers () {
            return {
                "restaurant-table__busy__text": this.busy,
            };
        },
        getIconModifiers () {
            return {
                "restaurant-table__busy__icon": this.busy,
            };
        },
        bindOrder () {
            this.$emit("bind-order", this.table);
        },

        toPay () {
            this.$emit("to-pay", this.table.orders[0].id);
        },

        select () {
            this.$emit("select", this.table);
        },

        addProduct () {
            this.$emit("add-product", this.table);
        },

        freeTable () {
            this.$emit("free", this.table);
        },

        onClick () {
            this.$emit("table-on-click", this.table);
        },

        update () {
            this.$refs.updateTableModalBox.load();
            this.$refs.updateTableModalBox.window.show();
        },

        onTableUpdate (table) {
            this.$emit("table-update", table);
        },

        remove () {
            this.$emit("table-delete", this.table.id);
        },

        getOrderProductStatusModifiers (orderProduct) {
            if (orderProduct.status === OrderStatus.TO_PAY) {
                return {};
            }

            if (orderProduct.requestedVolume === orderProduct.deliveredVolume) {
                return {
                    "product-status--delivered": true,
                };
            }
            else if (orderProduct.requestedVolume === orderProduct.completedVolume) {
                return {
                    "product-status--in-delivery": true,
                };
            }
            else {
                return {
                    "product-status--in-preparation": true,
                };
            }
        },

        getOrderProductVariationsPriceByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (total, variation) => total += this.restaurantProcessor.getVariationById(variation.id).price, 0
            );
        },

        getCustomProductPrice (product) {
            let total = 0;
            product.requestedMenus.forEach((menu) => {
                total += this.getCustomProductMenuPrice(menu);
            });

            return total;
        },

        getCustomProductMenuPrice (menu) {
            let total = 0;

            menu.products.forEach((customProductProduct) => {
                total += this.restaurantProcessor.getProductById(customProductProduct.id).price;
            });

            return total;
        },

        getProductsNoVariations (orderProduct) {
            let total = 0;
            for (let i = 0; i < orderProduct.requestedVolume; i++) {
                if (this.orderProductVolumeIndexHasVariations(orderProduct, i)) {
                    total++;
                }
            }
            return total;
        },

        orderProductVolumeIndexHasVariations (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).length > 0;
        },

        getOrderProductVariationsByVolumeIndex (orderProduct, volumeIndex) {
            return orderProduct.variations.filter(
                (variation) => variation.volumeIndex === volumeIndex
            ).map(
                (variation) => this.restaurantProcessor.getVariationById(variation.id)
            );
        },

        getOrderProductVariationsTextByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (text, variation) => text += ", " + this.restaurantProcessor.getVariationLocalizedName(variation.id).toLocaleLowerCase(), ""
            );
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.restaurant-table {
    min-width: 200px;
    &__box {
        position: relative;
        padding: 25px;
        background-color: rgb(254, 254, 254);
        border-radius: 6px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
        height: 300px;
        overflow: auto;
    }

    &__id {
        padding-right: 10px;
        margin: 0;
        font-size: 18px;
        font-weight: 800;
        color: rgb(20, 20, 20);
    }
    &__seats {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 500;
    }
    &__icon {
        width: 18px;
        height: auto;
        margin: 0;
        fill: rgb(255, 255, 255);
    }
    &__simple-button {
        min-width: initial;
        padding: 5px 12px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    &__busy {
        &__text {
            color: #F1BF40;
        }
        &__icon {
            fill: #F1BF40;
        }
    }
}
.cashier-control-box {
    transition: 256ms opacity;

    margin: 0 0 10px 0;

    opacity: 0;

    :deep(.simple-button) {
        min-width: initial;
        padding: 5px 12px;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    :deep(.simple-button .simple-button__text) {
        padding: 0;
        font-size: 11px;
    }
    :deep(.simple-button .simple-button__loading-spinner) {
        transform: translate(-50%, -50%) scale(0.14);
    }
    :deep(.simple-button + .simple-button) {
        margin-left: 6px;
    }
}

.restaurant-table:hover > .cashier-control-box {
    opacity: 1;
}

.restaurant-table--mobile {
    margin: 5px 8px;
}

.restaurant-table--cashier {
    margin: 0px 8px;
}

.product-status {
    padding: 4px 6px;
    background-color: rgb(70, 70, 70);
    border-radius: 4px;

    font-size: 12px;
    font-weight: 600;
    color: rgb(254, 254, 254);

    &--in-preparation {
        background-color: rgb(242, 152, 7);
        background-image: linear-gradient(45deg, rgb(242, 152, 7), rgb(250, 166, 30));
    }

    &--in-delivery {
        background-color: rgb(245, 191, 66);
        background-image: linear-gradient(45deg, rgb(245, 191, 66), rgb(230, 210, 76));
    }

    &--delivered {
        background-color: rgb(14, 161, 32);
        background-image: linear-gradient(45deg, rgb(14, 161, 32), rgb(21, 153, 38));
    }
}

.product-name {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);
}

.fixed-menu-product-list {
    margin: 0;
    padding: 10px 20px;
}

.fixed-menu-product {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);

    & + & {
        margin-top: 4px;
    }
}

.item-list {
    margin: 8px 0 0 18px;
    padding: 0;
    list-style-type: none;

    &__item {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 500;
    }
}

.item-list__item + .item-list__item {
    margin-top: 5px;
}
</style>
