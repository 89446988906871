<template>
    <div class="flex flex--100 order-status-view">
        <language-selector />
        <div class="flex flex--100 flex--y-align-center header">
            <div class="flex flex--100 flex--x-align-center">
                <primary-logo />
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <p class="description">
                    {{
                    $t("order.stateAlert")
                    }}
                </p>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center main">
            <div class="flex flex--100 order">
                <restaurant-order :restaurant-presentation="restaurantPresentation" :order="order" :compact-view="false"
                    :show-price="false" />
                <div class="flex flex--100 btn-order">
                    <simple-button @click="placeOrder" :text="$t(`order.orderAgain`)"></simple-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RestaurantOrder from "@/components/RestaurantOrder";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import { server } from "@/server";
import SimpleButton from '../inputs/SimpleButton.vue';
import { isMobile } from "../../main";

const preloadedData = {};

// function decodeId (plainId) {
//     return plainId;
//     let decodedId;
//
//     try {
//         decodedId = window.atob(plainId);
//     }
//     catch {
//         return undefined;
//     }
//
//     const parts = decodedId.split("/");
//     const length = Number.parseInt(parts[1]);
//
//     if (Number.isNaN(length)) {
//         return undefined;
//     }
//
//     const normalizedId = Number.parseInt(parts[0].substr(length));
//
//     if (Number.isNaN(normalizedId)) {
//         return undefined;
//     }
//
//     return normalizedId;
// }

async function preloadData (orderId) {
    preloadedData.order = await server.getOrder({ id: orderId, });
}

let insideRestaurant = false;
let tableNumber = null;

export default {
    async beforeRouteEnter (to, from, next) {
        await preloadData(to.params.id);
        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId || to.query.restaurantId);
        if (to.query.insideRestaurant === "true") {
            insideRestaurant = true;
        }

        if (to.query.tableNumber) {
            tableNumber = to.query.tableNumber;
        }
        next();
    },

    components: {
        RestaurantOrder,
        PrimaryLogo,
        LanguageSelector,
        SimpleButton,
    },
    name: "OrderStatusView",
    methods: {
        async placeOrder() {
            const path = isMobile ? `/restaurant/${this.restaurantPresentation.restaurant.id}/mobile/order` : `/restaurant/${this.restaurantPresentation.restaurant.id}/order`;
            const query = {
                fromHome: "true",
                insideRestaurant: insideRestaurant ? "true" : "false",
            };
            if (tableNumber) {
                query.tableNumber = tableNumber;
            }
            this.$router.push({ path, query: isMobile ? query : undefined });
        }
    },
    computed: {
        order () {
            return preloadedData.order;
        },

        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },
    },
};
</script>

<style lang="scss" scoped>
.order-status-view {
    background-color: rgb(254, 254, 254);
    min-height: 100vh;
}

.header {
    padding: 60px 40px 40px 40px;
    background-color: rgb(66, 66, 66);
    height: auto;
}
.header--in-preparation {
    background-color: rgb(234, 130, 48);
    background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
}
.header--completed {
    background-color: rgb(35, 187, 49);
    background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
}

.main {
    height: 60%;
}

.primary-logo {
    width: 128px;
    height: 128px;
    margin: 0 20px 20px 20px;

    fill: rgb(255, 255, 255);
}

.title {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.08rem;
    color: rgb(255, 255, 255);
}

.description {
    margin: 4px 20px 0 20px;

    font-size: 18px;
    color: rgb(255, 255, 255);
}

.order {
    margin: 10px 0;

    &__title {
        margin-bottom: 24px;

        font-size: 22px;
        font-weight: 800;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.product {
    & + & {
        margin-top: 10px;
    }

    &__name {
        font-size: 18px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
    &__status {
        padding: 10px 18px;

        border-radius: 1000px;

        font-size: 16px;
        font-weight: 600;
        color: rgb(255, 255, 255);
    }
    &__status--in-preparation {
        background-color: rgb(234, 130, 48);
        background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
    }
    &__status--completed {
        background-color: rgb(35, 187, 49);
        background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
    }
}

:deep(.restaurant-order) {
    background: transparent;
    justify-content: center;
    .restaurant-order__box {
        max-width: 650px;
        background: transparent;
        border: none;
        box-shadow: none;
        .restaurant-name {
            display: none;
        }

        .date {
            .restaurant-order__value {
                text-align: end;
            }
        }

        .restaurant-order__expanded {
            overflow: auto;
            height: auto;

            .unique-id {
                display: none;
            }

            .origin {
                display: none;
            }

            .payment-method {
                display: none;
            }

            .payment-date {
                display: none;
            }

            .delivery-address {
                display: none;
            }

            .delivery-date {
                display: none;
            }

            span.product-name {
                padding-right: 25px;
            }

            span.fixed-menu-product {
                padding-right: 25px;
            }
        }
    }
}

.language-selector {
    position: absolute;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: 12px;

    z-index: 10;

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
    }

    :deep(.language.language--selected) {
        background-color: transparent;
    }

    :deep(.language .language__name) {
        display: none;
    }

    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }

    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }

    :deep(.language+.language) {
        margin: 0;
    }
}

.btn-order {
    justify-content: center;
    .simple-button {
        border-radius: 0;
        width: 650px;
        height: 100%;
    }
}


</style>
