<template>
    <div class="flex flex--100 add-product-special-request-modal-box">
        <modal-box ref="modalBox">
            <form class="flex flex--100 add-special-request-form" v-if="product">
                <h2 class="title">{{ getProductLocalizedName(product) }}</h2>
                <hr class="separator"/>
                <div class="flex flex--100 suggested-special-request-list">
                    <simple-button
                        class="suggested-special-request-button"
                        v-for="suggestedSpecialRequest in suggestedSpecialRequests"
                        :text="suggestedSpecialRequest"
                        :key="suggestedSpecialRequest"
                        @click="addSuggestedSpecialRequest(suggestedSpecialRequest)"
                    />
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-textbox placeholder="..." :is-multiline="true" v-model:model-value="specialRequest" ref="specialRequestTextbox"/>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button :text='$t("generic.confirm")' @click="confirm"/>
                    <simple-button :text='$t("generic.cancel")' @click="hide"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";

export default {
    name: "AddProductSpecialRequestModalBox",
    components: {
        SimpleTextbox,
        SimpleButton,
        ModalBox,
    },
    data () {
        return {
            specialRequest: "",
            product: undefined,
            volume: 0,
        };
    },
    computed: {
        suggestedSpecialRequests () {
            return this.$i18n.tm("order.suggestedSpecialRequests");
        },
    },
    methods: {
        show ({ product, specialRequest, }) {
            this.product = product;
            this.specialRequest = specialRequest;

            this.$refs.modalBox.show();
        },

        hide () {
            this.specialRequest = "";
            this.product = undefined;
            this.volume = 0;

            this.$refs.modalBox.hide();
        },

        confirm () {
            this.$emit("confirm", {
                product: this.product,
                specialRequest: this.specialRequest,
            });

            this.hide();
        },

        getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getProductLocalizedName (product) {
            return this.getProductLocalization(product, this.$i18n.locale)?.name ?? "";
        },

        addSuggestedSpecialRequest (text) {
            let normalizedText = "";

            if (this.specialRequest.length > 0) {
                normalizedText += ", ";
            }

            normalizedText += text;
            this.specialRequest += normalizedText;
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-product-special-request-modal-box {}

.add-special-request-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-textbox {
    width: 60%;

    :deep(.simple-textbox__text-input.simple-textbox__text-input--multiline) {
        min-height: 120px;
    }
}

.simple-button {
    & + & {
        margin-left: 16px;
    }
}

.suggested-special-request-list {
    padding: 24px;

    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .simple-button {
        margin: 12px 20px;
    }
}

.title {
    font-weight: 600;
    font-size: 24px;

    color: rgb(11, 11, 11);
}


</style>
