<template>
    <div class="flex flex--100 allergen-selector">
        <div class="flex flex--100">
            <simple-button :text="selectButtonText" @click="showAllergenModalBox" class="select-button"/>
        </div>
        <modal-box ref="modalBox" :append-to-body="true">
            <div class="flex flex--100 allergen-list">
                <restaurant-allergen
                    v-for="allergen in allergens"
                    :key="allergen.id"
                    :allergen="allergen"
                    :selectable="true"
                    v-model:selected="selectedAllergens[allergen.id]"
                    @click="toggleSelection(allergen)"
                    :class="getAllergenModifiers(allergen)"
                />
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import RestaurantAllergen from "@/components/RestaurantAllergen";

export default {
    name: "AllergenSelector",
    components: {
        ModalBox,
        SimpleButton,
        RestaurantAllergen,
    },
    props: {
        allergens: {
            type: Array,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    emits: ["update:selected"],
    data() {
        return {
            selectedAllergens: this.selected.reduce((selected, id) => {
                selected[id] = true;
                return selected;
            }, {}),
        };
    },
    methods: {
        getAllergenModifiers(allergen) {
            return {
                "allergen--selected": this.selected.includes(allergen.id),
            };
        },
        showAllergenModalBox() {
            this.$refs.modalBox.show();
        },
        toggleSelection(allergen) {
            const selectedCopy = JSON.parse(JSON.stringify(this.selected));
            const selectedIndex = this.selected.indexOf(allergen.id);
            if (selectedIndex !== -1) {
                selectedCopy.splice(selectedIndex, 1);
            } else if (this.max === -1 || this.selected.length < this.max) {
                selectedCopy.push(allergen.id);
            }
            this.$emit("update:selected", selectedCopy);
        },
    },
    computed: {
        normalizedSelectedCategories() {
            return Object.keys(this.selectedAllergens).filter((id) => this.selectedAllergens[id] === true);
        },
        selectButtonText() {
            let text = this.$t("management.generic.selectAllergen");
            if (this.selected.length > 0) {
                text = `${this.$t("management.generic.selectAllergen")} (${this.normalizedSelectedCategories.length})`;
            }
            return text;
        },
    },
    watch: {
        normalizedSelectedCategories: {
            deep: true,
            handler(value) {
                this.$emit("update:selected", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.allergen-selector {
    .select-button {
        width: 100%;
        padding: 10px;
        background-color: rgb(77, 77, 77);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .select-button:hover {
        background-color: rgb(50, 50, 50);
    }

    .allergen-list {
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-height: 70vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 16px; /* Added gap between items for better spacing */
    }
}

.restaurant-allergen {
    padding: 12px 16px;
    margin: 12px 10px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */

    &:hover {
        background-color: #5a5a5a;
        transform: scale(1.05); /* Slightly increase size on hover */
    }

    &--selectable {
        cursor: pointer;
    }

    &--selected {
        outline-color: $primary-brand-color;
    }
}

.modal-box {
    :deep(.modal-box__slot) {
        width: auto;
        min-width: 300px;
        max-width: 80vw;
        min-height: 50vh;
        padding: 30px;
        margin: 30px auto;
        background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }
}
</style>