<template>
    <div class="flex flex--100 flex--x-align-center playground-view">
        <restaurant-product-analytics
            :restaurant-presentation="restaurantPresentation"
            :product="product"
            :daily-revenue="1"
            :daily-completed-volume="1"
        />
    </div>
</template>

<script>

import RestaurantProductAnalytics from "@/components/RestaurantProductAnalytics";
import {server} from "@/server";
import {RestaurantProcessor} from "@/utilities";

export default {
    name: "PlaygroundView",
    components: {
        RestaurantProductAnalytics,
    },
    data () {
        return {
            restaurantPresentation: {},
            product: {},
        };
    },

    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
    },

    async mounted() {
        this.restaurantPresentation = await server.getRestaurantPresentation(1);
        this.product = this.restaurantProcessor.getProductById(97);
        console.log(this.product);
        this.$i18n.locale = "it";
    }
};
</script>

<style lang="scss" scoped>
.playground-view {
    background-color: rgb(240, 240, 240);
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

</style>
