<template>
    <div class="flex flex--100 cashier-payment-calculator">
        <div class="flex flex flex--100 cashier-payment-calculator__box">
            <div class="flex flex--100 flex--x-align-center">
                <span class="cashier-payment-calculator__value">{{ stringValue }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="€ 5" class="calculator-button-add" @click="add(5)"/>
                <simple-button text="€ 10" class="calculator-button-add" @click="add(10)"/>
                <simple-button text="€ 20" class="calculator-button-add" @click="add(20)"/>
                <simple-button text="€ 50" class="calculator-button-add" @click="add(50)"/>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="1" class="calculator-button" @click="append(1)"/>
                <simple-button text="2" class="calculator-button" @click="append(2)"/>
                <simple-button text="3" class="calculator-button" @click="append(3)"/>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="4" class="calculator-button" @click="append(4)"/>
                <simple-button text="5" class="calculator-button" @click="append(5)"/>
                <simple-button text="6" class="calculator-button" @click="append(6)"/>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="7" class="calculator-button" @click="append(7)"/>
                <simple-button text="8" class="calculator-button" @click="append(8)"/>
                <simple-button text="9" class="calculator-button" @click="append(9)"/>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="0" class="calculator-button" @click="append(0)"/>
                <simple-button text="." :is-disabled="isDecimal" class="calculator-button" @click="append('.')"/>
                <simple-button text="C" class="calculator-button" @click="clear"/>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";

export default {
    name: "CashierPaymentCalculator",
    components: {
        SimpleButton,
    },
    props: {
        modelValue: {
            type: Number,
        },
    },
    emits: [ "update:modelValue" ],
    data () {
        return {
            stringValue: "0",
        };
    },
    methods: {
        append (value) {
            if (this.stringValue[0] === "0") {
                this.stringValue = value.toString();
            }
            else {
                this.stringValue += value.toString();
            }
        },

        add (value) {
            this.stringValue = (Number.parseFloat(this.stringValue) + value).toString();
        },

        clear () {
            this.stringValue = "0";
        },
    },
    computed: {
        value () {
            return Number.parseFloat(this.stringValue);
        },

        isDecimal () {
            return this.stringValue.includes(".");
        },
    },
    watch: {
        value (value) {
            this.$emit("update:modelValue", value);
        },
    },
};
</script>

<style lang="scss" scoped>
.cashier-payment-calculator {
    &__box {
        // Silence is golden.
    }

    &__value {
        margin: 0 0 25px 0;
        padding: 0;

        font-size: 24px;
        font-weight: 600;
    }
}

.simple-button {
    will-change: transform;
    transition: transform 300ms;

    background-color: rgb(80, 80, 80);
}
.simple-button:not(.simple-button--disabled):active {
    transform: scale(0.93);
}

.calculator-button {
    width: 84px;
    min-width: initial;

    margin: 24px 20px 0 20px;

    border-radius: 12px;

    :deep(.simple-button__text) {
        margin: 0;

        font-size: 24px;
    }
}

.calculator-button-add {
    width: 64px;
    min-width: initial;

    margin: 12px 12px 0 12px;
    padding: 0;

    border-radius: 10px;

    :deep(.simple-button__text) {
        margin: 12px 0;

        font-size: 18px;
    }
}
</style>
