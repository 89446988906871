<template>
    <div class="flex flex--100 add-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-category-form">
                <div class="flex flex--100 box">
                    <simple-textbox :placeholder="$t('management.generic.name')" v-model:model-value="name" ref="enNameTextbox"/>
                </div>
                <div class="flex flex--100 box">
                    <simple-selectbox
                        class="mode-select-box"
                        :select-placeholder="$t('management.generic.mode')"
                        :options="modeOptions"
                        :can-search-options="false"
                        :can-select-empty-value="false"
                        v-model:model-value="selectedMode"
                    />
                </div>
                <div class="flex flex--100 box">
                    <prism-editor class="configuration-textbox " v-model:model-value="configuration" :highlight="highlightJSON"></prism-editor>
                </div>
                 <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.add')" @click="addEndpoint" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/

export default {
    name: "AddEndpointModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        closeOnEndpointAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "category-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        SimpleSelectbox,
        PrismEditor,
    },
    data () {
        return {
            mode: null,
            modeOptions: [{
                text: this.$t("endpoint.cashier"),
                value: "cashier",
            }, {
                text: this.$t("endpoint.kiosk"),
                value: "kiosk",
            }, {
                text: this.$t("endpoint.kitchen"),
                value: "kitchen",
            }, {
                text: this.$t("endpoint.waiter"),
                value: "waiter",
            }],
            selectedMode: "",
            name: "",
            configuration: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAddEndpointForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.name = "";
            this.configuration = "";
            this.selectedMode = "";
        },

        async addEndpoint () {
            if (!this.validateAddEndpointForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const endpoint = await user.addEndpoint({
                    restaurantId: this.restaurantId,
                    name: this.name,
                    mode: this.selectedMode,
                    configuration: this.configuration,
                });

                if (endpoint) {
                    this.$emit("endpoint-add", endpoint);

                    if (this.closeOnEndpointAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.endpointAddedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        highlightJSON (code) {
            return highlight(code, languages.json);
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.add-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px 15px 0px;
}

.configuration-textbox {
    width: 100%;

    padding: 20px;

    background-color: rgb(240, 240, 240);
    border-radius: 6px;

    :deep(*) {
        font-family: "Fira code", "Fira Mono", "Consolas", "Menlo", "Courier", "monospace";
        font-size: 14px;
        line-height: 1.5;
    }
}

.mode-select-box {
    width: 300px;
}

.box  {
    margin-bottom: 20px;
}
</style>
