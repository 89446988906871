<template>
    <div class="flex flex--100 fixed-menu-selector-modal-box" :class="getModifiers()">
        <modal-box ref="modalBox" :show-close-button="false" :close-on-background-click="true" :show-back-button="true">
            <custom-product-selector
                :custom-product="customProduct"
                v-model:model-value="customProductValue"
                :restaurant-presentation="restaurantPresentation"
                @choice-change="onChoiceChange"
                v-if="customProduct"
            />
            <div class="flex flex--100 flex--x-align-center flex--y-align-center total-price">
                <span class="total-price text">{{ $t("order.total") }}</span>
                <span class="separator-price"></span>
                <span class="total-price text">€ {{ normalizePriceToDisplay(totalPrice) }}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center action-box">
                <simple-button class="cancel-button" :text="$t('generic.cancel')" @click="cancelChoices"/>
                <simple-button class="confirm-button" :text="confirmButtonText" :is-disabled="hasMissingChoices" @click="confirmChoices"/>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CustomProductSelector from "@/components/inputs/CustomProductSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import { cloneDeep, } from "lodash";
import { isMobile, } from "@/main";

const CHOICE_INDEX_INCREMENT_DELAY = 120;

export default {
    name: "CustomProductSelectorModalBox",
    components: {
        SimpleButton,
        CustomProductSelector,
        ModalBox
    },
    props: {
        restaurantPresentation: {
            type: Object,
        }
    },
    emits: [ "cancel", "confirm" ],
    data () {
        return {
            customProduct: undefined,
            customProductValue: undefined,
            incrementChoiceIndexTimeoutId: undefined,
        };
    },
    methods: {
        getModifiers () {
            return {
                "fixed-menu-selector-modal-box--mobile": isMobile,
            };
        },

        incrementChoiceIndex () {
            this.incrementChoiceIndexTimeoutId = setTimeout(() => {
                ++this.customProductValue.choiceIndex;
                this.incrementChoiceIndexTimeoutId = undefined;
            }, CHOICE_INDEX_INCREMENT_DELAY);
        },

        onChoiceChange () {
            if (this.incrementChoiceIndexTimeoutId) {
                clearTimeout(this.incrementChoiceIndexTimeoutId);

                this.incrementChoiceIndex();
            }
        },

        cancelChoices () {
            this.$emit("cancel");
            this.$refs.modalBox.hide();
        },

        confirmChoices () {
            // VUE X UPDATE HERE
            this.customProduct.price = this.totalPrice;
            this.$emit("confirm", cloneDeep(this.customProductValue.selectedProducts));
            this.$refs.modalBox.hide();
        },

        show ({ customProduct, value, }) {
            this.customProduct = customProduct;
            this.customProductValue = value ?? {
                choiceIndex: 0,
                selectedProducts: customProduct.choices.map(() => ({})),
            };

            this.$refs.modalBox.show();
        },
    },
    computed: {
        missingChoices () {
            const missingChoices = [];

            if (!this.customProduct) {
                return [];
            }

            this.customProduct.choices.forEach((choice, i) => {
                if (!this.customProductValue.selectedProducts[i] || Object.keys(this.customProductValue.selectedProducts[i]).length === 0) {
                    missingChoices.push(i);
                }
            });

            return missingChoices;
        },

        hasMissingChoices () {
            return this.missingChoices.length > 0;
        },

        confirmButtonText () {
            if (this.hasMissingChoices) {
                return this.$tc('generic.hasMissingChoices', this.missingChoices.length, { choices: this.missingChoices.length });
            }
            return this.$t('generic.confirm');
        },

        totalPrice () {
            let totalPrice = 0;

            this.customProductValue?.selectedProducts.forEach((choiceIndex) => {
                for (const key in choiceIndex) {
                    totalPrice += choiceIndex[key].price;
                }
            });

            return totalPrice;
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.modal-box {
    :deep(.modal-box__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        padding: 0;

        border-radius: 12px 12px 0 0;
    }
}

.action-box {
    height: 20%;
}
.fixed-menu-selector {
    height: 80%;

    :deep(.header) {
        @extend .flex--y-align-center;

        height: 20%;
    }
    :deep(.main) {
        height: 80%;

        margin: 0;
        padding: 30px 0 0 0;

        background-color: rgb(245, 245, 245);
        box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
    }
    :deep(.choice) {
        transition: opacity 1.2s;

        opacity: 0;
    }
    :deep(.choice.choice--active) {
        opacity: 1;
    }
}

.cancel-button {
    min-width: 120px;
    margin-right: 50px;

    background-color: transparent;
    border: 2px solid rgb(160, 160, 160);
    border-radius: 1000px;

    :deep(.simple-button__text) {
        color: rgb(140, 140, 140);
    }
}

.confirm-button {
    margin-left: 50px;

    background-color: $primary-brand-color;
    border-radius: 1000px;
    border: 2px solid $primary-brand-color;
}

.fixed-menu-selector-modal-box.fixed-menu-selector-modal-box--mobile {
    .cancel-button {
        margin-right: 10px;
    }
    .confirm-button {
        margin-left: 10px;
    }

    :deep(.modal-box__slot) {
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        margin: 10vh 0 0 0;
        margin: calc(var(--vh, 1vh) * 10) 0 0 0;
    }
}

.total-price {
    height: 5%;
    .text {
        font-size: 16px;
        font-weight: bolder;
    }
}

.separator-price {
    padding: 10px;
}
</style>
