const onePlusOne = Object.freeze({
    id: 1,
    params: 2,
    name: "discountStrategy.onePlusOne",
    applyDiscount: (quantity, unitPrice) => {
        // Calcola il numero di articoli da pagare
        let freeItems = Math.floor(quantity / 2);
        // Calcola il subtotale
        let discount = freeItems * unitPrice;
        return discount;
    }
});

const percentage = Object.freeze({
  id: 2,
  params: 3,
  name: "discountStrategy.percentage",
  applyDiscount: (quantity, unitPrice, percentage) => {
    if (!Number.isFinite(Number.parseFloat(percentage))) {
      return quantity * unitPrice;
    }
    let discount = Number.parseFloat(percentage) / 100 * unitPrice;
    return discount * quantity;
  },
});

const fixed = Object.freeze({
  id: 3,
  params: 3,
  name: "discountStrategy.fixed",
  applyDiscount: (quantity, unitPrice, fixed) => {
    if (!Number.isFinite(Number.parseFloat(fixed))) {
      return quantity * unitPrice;
    }
    let discount = Number.parseFloat(fixed);

    return discount * quantity;
  },
});


export const DiscountStrategies = {
  onePlusOne,
  percentage,
  fixed,
};