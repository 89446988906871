<template>
    <div class="loading-spinner">
        <div class="loading-spinner__box">
            <div class="spinner">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
                <hr class="spinner__bar">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingSpinner",
};
</script>

<style lang="scss" scoped>
.loading-spinner {
    &__box {
        position: relative;

        width: 100px;
        height: 100px;
    }
}

.spinner {
    cursor: default;
    pointer-events: none;

    animation: spin 1.1s steps(12, end) infinite;

    position: absolute;
    left: 50%;
    top: 50%;

    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;

    transform: translate(-50%, -50%);

    &__bar {
        $width: 8px;

        position: absolute;
        left: 50%;

        display: block;
        height: 24px;
        width: $width;
        margin: 0 0 0 $width / 2 * -1;
        padding: 0;

        background-color: rgb(251, 251, 251);
        border: none;
        border-radius: 99px;

        transform-origin: center 50px;
    }
    &__bar:nth-child(1) {
        opacity: 0.08;
    }
    &__bar:nth-child(2) {
        opacity: 0.167;
        transform: rotate(30deg);
    }
    &__bar:nth-child(3) {
        opacity: 0.25;
        transform: rotate(60deg);
    }
    &__bar:nth-child(4) {
        opacity: 0.33;
        transform: rotate(90deg);
    }
    &__bar:nth-child(5) {
        opacity: 0.4167;
        transform: rotate(120deg);
    }
    &__bar:nth-child(6) {
        opacity: 0.5;
        transform: rotate(150deg);
    }
    &__bar:nth-child(7) {
        opacity: 0.583;
        transform: rotate(180deg);
    }
    &__bar:nth-child(8) {
        opacity: 0.67;
        transform: rotate(210deg);
    }
    &__bar:nth-child(9) {
        opacity: 0.75;
        transform: rotate(240deg);
    }
    &__bar:nth-child(10) {
        opacity: 0.833;
        transform: rotate(270deg);
    }
    &__bar:nth-child(11) {
        opacity: 0.9167;
        transform: rotate(300deg);
    }
    &__bar:nth-child(12) {
        opacity: 1;
        transform: rotate(330deg);
    }

    @keyframes spin {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}
</style>
