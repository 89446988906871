<template>
    <div class="flex flex--100 user-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.eventManagement`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <span class="item__key">{{$t(`management.generic.date`).toLocaleUpperCase()}}</span>
                <div class="flex flex--100 flex--x-align-center date-picker-box">
                    <v3-date-picker
                        class="flex flex--x-align-center"
                        input-format="dd/MM/yyyy"
                        :locale="calendarLanguage"
                        v-model:model-value="event.date"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {enUS, zhCN, it} from "date-fns/locale";
import V3DatePicker from "@/components/utilities/V3DatePicker";

function getMidnightDate () {
    const date = new Date();

    date.setUTCHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "EventsManagementTab",
    components: {
        V3DatePicker,
    },
    data () {
        return {
            event: {
                date: getMidnightDate(),
            },
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
        };
    },
    computed: {
        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.simple-textbox {
    width: 90%;
}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
}
</style>
