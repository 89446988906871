<template>
    <div class="flex flex--100 login-view">
        <div class="flex flex--100 flex--x-align-center">
            <div class="flex flex--100 login-view__box">
                <form class="flex flex--100 form">
                    <h1 class="flex flex--100 flex--x-align-center brand-name">FortuneRMS</h1>
                    <error-list ref="errorList"/>
                    <div class="flex flex--100 flex--direction-column flex--y-align-center" v-if="isLogged">
                        <span class="restaurant-name">{{ configuration.restaurant.name }}</span>
                        <span class="restaurant-address">{{ configuration.restaurant.address }}</span>
                        <span class="mode">{{ $t(`endpoint.${configuration.mode}`) }}</span>
                    </div>
                    <div class="flex flex--100" v-if="isLogged">
                        <simple-button :text="$t('host.start')" class="login-button" :is-loading="isWaitingServerResponse" @click="start"/>
                    </div>
                    <div class="flex flex--100 flex--direction-column" v-else>
                        <simple-textbox placeholder="Token" v-model:model-value="token" class="username" ref="tokenTextbox"/>
                    </div>
                    <div class="flex flex--100" v-if="isLogged">
                        <simple-button :text="$t('host.logout')" class="login-button" :is-loading="isWaitingServerResponse" @click="logout"/>
                    </div>
                    <div class="flex flex--100" v-else>
                        <simple-button :text="$t('host.access')" class="login-button" :is-loading="isWaitingServerResponse" @click="login"/>
                    </div>
                    <div class="flex flex--100" v-if="isLogged">
                        <simple-button :text="$t('host.getConfiguration')" class="login-button" :is-loading="isWaitingServerResponse" @click="getConfiguration"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.development')" class="login-button" :is-loading="isWaitingServerResponse" @click="development"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.fullScreen')" class="login-button" :is-loading="isWaitingServerResponse" @click="fullScreen"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.clearCache')" class="login-button" :is-loading="isWaitingServerResponse" @click="clearCache"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.selectDirectory')" class="login-button" :is-loading="isWaitingServerResponse" @click="selectDirectory"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.checkUpdate')" class="login-button" :is-loading="isWaitingServerResponse" @click="checkUpdate"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.openLogs')" class="login-button" :is-loading="isWaitingServerResponse" @click="openLogs"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-button :text="$t('host.exit')" class="login-button" :is-loading="isWaitingServerResponse" @click="exit"/>
                    </div>
                </form>
            </div>
        </div>
        <PrimaryFooter/>
    </div>
</template>

<script>
import PrimaryFooter from "@/components/PrimaryFooter";
import ErrorList from "@/components/utilities/ErrorList";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { user } from "@/user";
import { server } from "@/server";
import { isDesktop, } from "@/main";

const UserLoginErrorType = {
    MISSING_USERNAME: {
        id: 1,
        text: "Username is required.",
    },
    MISSING_PASSWORD: {
        id: 2,
        text: "Password is required.",
    },
    INEXISTENT_USER: {
        id: 3,
        text: "This username doesn't exists.",
    },
    INCORRECT_PASSWORD: {
        id: 4,
        text: "This password is incorrect.",
    },
    BLOCKED_USER: {
        id: 5,
        text: "Your account has been blocked.",
    },
    NETWORK_ERROR: {
        id: 6,
        text: "A Internet error occurred, check your connection.",
    },
};

export default {
    beforeRouteLeave (to, from, next) {
        next();
    },
    
    name: "LoginHostView",
    components: {
        PrimaryFooter,
        SimpleButton,
        SimpleTextbox,
        ErrorList,
    },
    data () {
        return {
            token: "",
            isWaitingServerResponse: false,
            isDesktop,
            configuration: null,
        };
    },
    methods: {
        validateLoginForm () {
            const tokenTextbox = this.$refs.tokenTextbox;
            tokenTextbox.validate(UserLoginErrorType.MISSING_USERNAME, this.token.length > 0);
            return tokenTextbox.isValid
        },

        async login () {
            this.$refs.errorList.clear();
            this.$refs.tokenTextbox.clearErrors();

            if (!this.validateLoginForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const endpoint = await user.loginHost({
                    token: this.token,
                });

                this.isWaitingServerResponse = false;

                if (endpoint) {
                    try {
                        const config = {
                            ...endpoint,
                            token: this.token,
                        };

                        if (!this.configuration) {
                            this.configuration = config;
                            this.configuration.restaurant = await server.getRestaurant(endpoint.restaurantId);
                        }
                        const { ipcRenderer, } = window.require("electron");
                        ipcRenderer.send("host:login", JSON.stringify(config));
                    }
                    catch (e) {
                        console.log(e);
                        // Silence is golden.
                    }
                }
            }
            catch (error) {
                this.isWaitingServerResponse = false;

                this.$refs.errorList.addError(UserLoginErrorType.NETWORK_ERROR);
            }
        },

        async getConfiguration () {
            if (this.configuration && this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            try {
                const endpoint = await user.loginHost({
                    token: this.configuration.token,
                });

                this.isWaitingServerResponse = false;

                if (endpoint) {
                    try {
                        const config = {
                            ...endpoint,
                            token: this.configuration.token,
                        };

                        if (!this.configuration) {
                            this.configuration = config;
                            this.configuration.restaurant = await server.getRestaurant(endpoint.restaurantId);
                        }
                        const { ipcRenderer, } = window.require("electron");
                        ipcRenderer.send("host:login", JSON.stringify(config));
                    }
                    catch (e) {
                        console.log(e);
                        // Silence is golden.
                    }
                }
            }
            catch (error) {
                this.isWaitingServerResponse = false;
            }
        },

        development () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:development");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        fullScreen () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:fullScreen");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        clearCache () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:clear");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        exit () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:exit");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        logout () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:logout");
                this.configuration = null;
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        start () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:start");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },

        async checkUpdate () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            try {
                const { ipcRenderer, } = window.require("electron");
                const result = await ipcRenderer.invoke("host:checkUpdate");
                console.log(result);
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
            this.isWaitingServerResponse = false;
        },

        async selectDirectory () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            try {
                const { ipcRenderer, } = window.require("electron");
                const result = await ipcRenderer.invoke("host:selectDirectory");
                console.log(result);
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
            this.isWaitingServerResponse = false;
        },

        openLogs () {
            try {
                const { ipcRenderer, } = window.require("electron");
                ipcRenderer.send("host:openLogs");
            }
            catch (e) {
                console.log(e);
                // Silence is golden.
            }
        },
    },
    computed: {
        isLogged () {
            return this.configuration && this.configuration.restaurant;
        },
    },
    async created () {
        try {
            const { ipcRenderer, } = window.require("electron");
            this.configuration = JSON.parse(await ipcRenderer.invoke("host:getUserData"));
            if (this.configuration) {
                this.configuration.restaurant = await server.getRestaurant(this.configuration.restaurantId);
            }
        }
        catch (e) {
            console.log(e);
            // Silence is golden.
        }
    }
};
</script>

<style lang="scss" scoped>
.login-view {
    overflow: auto;

    min-height: 100vh;
    height: 100vh;

    &__box {
        max-width: 560px;
    }
}

.form {
    margin: 100px 0 0 0;
    padding: 50px;
    background-color: rgb(254, 254, 254);

    .simple-textbox.username,
    .simple-textbox.password {
        width: 100%;
    }
    .simple-textbox.password {
        margin-top: 20px;
    }

    .simple-button.login-button {
        width: 100%;
        margin-top: 30px;
    }
}

.restaurant-name {
    font-size: 24px;
    font-weight: 600;
}

.restaurant-address {
    font-size: 20px;
    font-weight: 600;
}

.mode {
    font-size: 24px;
    font-weight: 600;
}

.brand-name {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
}
</style>
