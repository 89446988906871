<template>
    <div class="flex restaurant-variation" :class="getModifiers()" @click="onClick">
        <span class="flex restaurant-variation__name">{{ variationText }}</span>
        <update-variation-modal-box
            :variation="variation"
            :restaurant-id="restaurantId"
            ref="updateVariationModalBox"
            @variation-update="onVariationUpdate"
        />
    </div>
</template>

<script>
import UpdateVariationModalBox from "@/components/windows/UpdateVariationModalBox";

export default {
    name: "RestaurantVariation",
    components: {
        UpdateVariationModalBox,
    },
    props: {
        variation: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        showEditor: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:selected",
        "variation-update",
    ],
    methods: {
        getModifiers () {
            return {
                "restaurant-variation--selectable": this.selectable,
                "restaurant-variation--selected": this.selected,
            };
        },

        onClick () {
            if (this.selectable) {
                if (this.showEditor) {
                    this.showUpdateVariationModalBox();
                }
                else {
                    this.$emit("update:selected", !this.selected);
                }
            }
        },

        getLocalization (languageIso) {
            return this.variation.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getVariationText () {
            const price = this.variation.price;
            let text = this.getLocalization(this.$i18n.locale)?.name ?? "";

            if (price !== 0) {
                if (price > 0) {
                    text += ` +${this.normalizePriceToDisplay(price)} €`;
                }
                else {
                    text += ` ${this.normalizePriceToDisplay(price)} €`;
                }
            }

            return text;
        },

        onVariationUpdate (variation) {
            this.$emit("variation-update", variation);
        },

        showUpdateVariationModalBox () {
            this.$refs.updateVariationModalBox.load();
            this.$refs.updateVariationModalBox.window.show();
        },
    },
    computed: {
        variationText ()  {
            return this.getVariationText();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-variation {
    @extend .--unselectable;

    padding: 12px 16px;

    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    &__name {
        font-size: 16px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &--selectable {
        cursor: pointer;
    }
    &--selected {
        outline-color: $primary-brand-color;
    }
}
</style>
