<template>
    <div class="flex flex--100 product-tag-selector">
        <div class="flex flex--100">
            <simple-button :text="$t(`generic.selectTag`)" @click="showProductTagModalBox" class="select-button"/>
        </div>
        <modal-box ref="modalBox" :append-to-body="true">
            <div class="flex flex--100 product-tag-list">
                <restaurant-product-tag
                    v-for="productTag in productTags"
                    :key="productTag.id"
                    :product-tag="productTag"
                    :selectable="true"
                    :selected="selectedProductTags[productTag.id]"
                    :is-active="activatedProductTags[productTag.id]"
                    :class="getProductTagModifiers(productTag)"
                    @update:selected="onSelect(productTag.id)"
                />
            </div>
            <hr class="separator"/>
            <div class="flex flex--100 flex--y-align-center">
                <span class="input-descriptor-text">{{ $t("generic.expirationTime") }}</span>
                <div class="flex flex--direction-column input-descriptor-text">
                    <v3-date-picker
                        ref="DatePicker"
                        class="date-picker-input"
                        input-format="dd/MM/yyyy"
                        :locale="calendarLanguage"
                        v-model:model-value="expirationTimestamp"
                    />
                    <vue-countdown v-if="hasExpiration" :time="expirationTimestamp.getTime() - Date.now()" v-slot="{ days, hours, minutes, seconds }">
                        {{ $t("generic.timeRemaining") }} {{ days }} {{ $t("generic.days") }} , {{ hours }} {{ $t("generic.hours") }} , {{ minutes }} {{ $t("generic.minutes") }} , {{ seconds }} {{ $t("generic.seconds") }} .
                    </vue-countdown>
                </div>
                <simple-checkbox ref="hasExpiration" appearance="slider" v-model:model-value="hasExpiration" />
            </div>
            <hr class="separator"/>
            <div class="flex flex--100">
                <div class="flex input-descriptor-text" v-show="active">
                    <simple-button :text="$t('management.generic.active')" @click="onActivate"/>
                </div>
                <div class="flex" v-show="deactivate">
                    <simple-button :text="$t('management.generic.deactivated')" @click="onDeactivate"/>
                </div>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import RestaurantProductTag from "@/components/RestaurantProductTag";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import { enUS, zhCN, it } from "date-fns/locale";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";

export default {
    name: "ProductTagSelector",
    components: {
        ModalBox,
        SimpleButton,
        RestaurantProductTag,
        V3DatePicker,
        SimpleCheckbox,
    },
    props: {
        productTags: {
            type: Array,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    emits: ["update:selected"],
    data() {
        return {
            selectedProductTags: {},
            activeProductTags: [],
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            expirationTimestamp: new Date(),
            hasExpiration: false,
            active: false,
            deactivate: false,
        };
    },
    methods: {
        getProductTagModifiers(productTag) {
            return {
                "product-tag--selected": this.selected.includes(productTag.id),
            };
        },
        showProductTagModalBox () {
            this.activeProductTags = JSON.parse(JSON.stringify(this.selected));
            this.$refs.modalBox.show();
        },
        // toggleSelection(productTag) {
        //     const selectedCopy = JSON.parse(JSON.stringify(this.selected));
        //     const selectedIndex = this.selected.indexOf(productTag.id);
        //     if (selectedIndex !== -1) {
        //         selectedCopy.splice(selectedIndex, 1);
        //     } else if (this.max === -1 || this.selected.length < this.max) {
        //         selectedCopy.push(productTag.id);
        //     }
        //     this.$emit("update:selected", selectedCopy);
        // },
        onSelect (productTagId) {
            this.selectedProductTags = {};
            this.selectedProductTags[productTagId] = true;

            const selected = this.activeProductTags.find((productTag) => productTag.id === productTagId);

            if (selected === undefined) {
                this.deactivate = false;
                this.active = true;
                this.expirationTimestamp = new Date();
                this.$refs.DatePicker.set(this.expirationTimestamp);
                this.hasExpiration = false;
            }
            else {
                this.active = false;
                this.deactivate = true;
                this.expirationTimestamp = selected.expirationTimestamp ? new Date(selected.expirationTimestamp) : new Date();
                this.$refs.DatePicker.set(this.expirationTimestamp);
                this.hasExpiration = Boolean(selected.expirationTimestamp);
            }
        },
        onDeactivate () {
            this.activeProductTags = this.activeProductTags.filter((tag) => tag.id === this.selectedProductTag);
            this.$emit("update:selected", this.activeProductTags);
            
            this.deactivate = false;
            this.active = true;
        },

        onActivate () {
            this.activeProductTags.push({
                id: this.selectedProductTag,
                expirationTimestamp: this.hasExpiration ? this.expirationTimestamp.getTime() : null,
            });
            this.$emit("update:selected", this.activeProductTags);
            
            this.deactivate = true;
            this.active = false;
        },
    },
    computed: {
        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
        activatedProductTags () {
            const activated = [];

            for (const activeProductTag of this.activeProductTags) {
                activated[activeProductTag.id] = true;
            }

            return activated;
        },
        selectedProductTag () {
            return Object.keys(this.selectedProductTags)[0];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
.product-tag-selector {
    .select-button {
        width: 100%;
        padding: 10px;
        background-color: rgb(77, 77, 77);
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .select-button:hover {
        background-color: rgb(50, 50, 50);
    }

    .product-tag-list {
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-height: 70vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 16px; /* Added gap between items for better spacing */
    }
}

.restaurant-product-tag {
    padding: 12px 16px;
    margin: 12px 10px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */

    &:hover {
        background-color: #5a5a5a;
        transform: scale(1.05); /* Slightly increase size on hover */
    }

    &--selectable {
        cursor: pointer;
    }

    &--selected {
        outline-color: $primary-brand-color;
    }
}

.modal-box {
    :deep(.modal-box__slot) {
        width: auto;
        min-width: 300px;
        max-width: 80vw;
        min-height: 50vh;
        padding: 30px;
        margin: 30px auto;
        background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
    }
}

.input-descriptor-text {
    margin: 10px 20px;

    font-weight: 400;
    font-size: 14px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.date-picker-input {
    margin: 10px 0;
    width: 300px;
}
</style>